import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Santiago Hernández N.
 *
 * @version jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/

class CustomProductosClientesProveedores extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                  = this.initForm.bind(this);
        // Variables de tablas:
        this.gridOptionsTbPrincipal                    = Object.assign({},this.gridOptions);
        this.gridOptionsTbModPrecios                   = Object.assign({},this.gridOptions);
        
        // Funciones:
        this.abrirTabla                                = this.abrirTabla.bind(this);
        this.successAbrirTabla                         = this.successAbrirTabla.bind(this);
        this.setCrearButtonRejilla                     = this.setCrearButtonRejilla.bind(this);
        this.DatosEliminar                             = this.DatosEliminar.bind(this);
        this.successEliminar                           = this.successEliminar.bind(this);
        this.agregar                                   = this.agregar.bind(this);
        this.cancelar                                  = this.cancelar.bind(this);
        this.aceptar                                   = this.aceptar.bind(this);
        this.crearAticulosTerceros                     = this.crearAticulosTerceros.bind(this);
        this.successCrearAticulosTerceros              = this.successCrearAticulosTerceros.bind(this);
        this.validarBotones                            = this.validarBotones.bind(this);
        this.DatosPrecios                              = this.DatosPrecios.bind(this);
        this.cerrarModalPCP                            = this.cerrarModalPCP.bind(this);
        this.successDatosPrecios                       = this.successDatosPrecios.bind(this);
        this.dibujarSelectTipoDescuento                = this.dibujarSelectTipoDescuento.bind(this);
        this.setCrearButtonTbModPrecios                = this.setCrearButtonTbModPrecios.bind(this);
        this.DatosEliminarArtTerPre                    = this.DatosEliminarArtTerPre.bind(this);
        this.successEliminarArtTerPre                  = this.successEliminarArtTerPre.bind(this);
        this.limpiarModalPCP                           = this.limpiarModalPCP.bind(this);
        this.cancelarModPrecios                        = this.cancelarModPrecios.bind(this);
        this.validarFechas                             = this.validarFechas.bind(this);
        this.aceptarModPrecios                         = this.aceptarModPrecios.bind(this);
        this.successAceptarModPrecios                  = this.successAceptarModPrecios.bind(this);
        this.crearArticulosTercerosPrecios             = this.crearArticulosTercerosPrecios.bind(this);
        this.successCrearArticulosTercerosPrecios      = this.successCrearArticulosTercerosPrecios.bind(this);
        this.successAceptarPrincipal                   = this.successAceptarPrincipal.bind(this);
        this.dosCaracteres                             = this.dosCaracteres.bind(this);
    }

    initForm(){
     
        console.log('Formulario CustomProductosClientesProveedores,  @version: jdesk_1.01.0001, @author:Santiago Hernández N.')
        this.getField('nombre').setOnChange(this.abrirTabla);
        this.getField('agregar').setDisabled(true);
        this.getField('agregar').setClick(()=>{this.agregar(true);});


        this.getField('nombre_articulo').setOnChange(this.validarBotones);
        this.getField('aceptar').setDisabled(true);
        this.getField('cancelar').setDisabled(true);

        this.getField('cancelar').setClick(this.cancelar);
        this.getField('aceptar').setClick(this.aceptar);

        this.getField('modal_prodclienteprovedor').setCloseButton(this.cerrarModalPCP);
        this.getField('cancelarmodprecios').setClick(this.cancelarModPrecios);

        this.getField('fecha_inicio').setOnChange(this.validarFechas);
        this.getField('fecha_final').setOnChange(this.validarFechas);
        
        this.getField('aceptarmodprecios').setClick(this.aceptarModPrecios);
        this.getField('agregar_modal_prodclienteprovedor').setClick(()=>{this.limpiarModalPCP(true);});

        this.getField('descuento').setKeyUp(()=>{this.dosCaracteres(); this.getField('descuento').getValue().length > 2 ? this.getField('descuento').valid() : this.getField('descuento').valid();});


    }

    dosCaracteres(){
        if (this.getField('descuento').getValue() !== '' && this.getField('descuento').getValue() !== ' ') {
            const str = this.getField('descuento').getValue();
            if (String(str).length > 2) {
                const newStr = String(str).slice(0, (String(str).length-2) * -1);
                this.getField('descuento').setValue(newStr);
            }
        }
    }
    
    crearArticulosTercerosPrecios(){
        this.datosAgregar.datos['articulo_terceros_id']= this.getField('articulo_terceros_id').getValue();
        this.datosAgregar.datos['fecha_inicio']= this.getField('fecha_inicio').getValue();
        this.datosAgregar.datos['tipo_descuento']= this.getField('tipo_descuento').getValue();
        this.datosAgregar.datos['fecha_final']= this.getField('fecha_final').getValue();
        this.datosAgregar.datos['unidades_id']= this.getField('unidades_id').getValue();
        this.datosAgregar.datos['cantidad_maxima']= this.getField('cantidad_maxima').getValue();
        this.datosAgregar.datos['precio']= this.getField('precio').getValue();
        this.datosAgregar.datos['descuento']= this.getField('descuento').getValue();
        this.generalFormatPmv = {tipo_servicio: 'maes-articulotercerosprecios', operacion: '5', operacion_tipo: 'crear'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: this.datosAgregar,
                success: this.successCrearArticulosTercerosPrecios,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successCrearArticulosTercerosPrecios(data){
        if (data.estado_p === 200) {
            this.DatosPrecios();
            this.limpiarModalPCP(false);
        }
    }
    
    aceptarModPrecios(){
        if (this.validarFechas() && this.getField('articulo_terceros_id').getValue() !== '' && this.getField('unidades_id').valid() && 
        this.getField('cantidad_maxima').valid() && this.getField('precio').valid() && this.getField('descuento').valid() && 
        this.getField('tipo_descuento').valid()) {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['fecha_inicio']=this.getField('fecha_inicio').getValue();
            this.datosAgregar.datos['fecha_final']=this.getField('fecha_final').getValue();
            this.datosAgregar.datos['cantidad_maxima']=this.getField('cantidad_maxima').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-productosclienteproveedor', operacion: '20', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successAceptarModPrecios,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                }
            );
        }
    }

    successAceptarModPrecios(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            this.alertGeneral.toggle(true, 'Los campos Fecha Desde,Fecha Hasta,Cantidad máxima ya existen, no se permite duplicar', "error");
        }else if(data.estado_p === 404){
            this.crearArticulosTercerosPrecios();
        }
    }
    
    validarFechas(){
        if (this.getField('fecha_inicio').getValue() !== '' && this.getField('fecha_final').getValue() !== '') {
            let fecha_inicio = new Date(this.getField('fecha_inicio').getValue());
            let fecha_final = new Date(this.getField('fecha_final').getValue());
            let finicioFormateada = fecha_inicio.toISOString().split('T')[0];
            let ffinalFormateada = fecha_final.toISOString().split('T')[0];
    
            if (new Date(finicioFormateada) > new Date(ffinalFormateada)) {
                this.getField('fecha_final').setError(true, "¡La fecha debe ser mayor o igual a la fecha desde!");
                return false;
            }else if (new Date(finicioFormateada) <= new Date(ffinalFormateada)){
                this.getField('fecha_final').setError(false, "");
                return true;
            }
        }
    }
    
    cancelarModPrecios(){
        this.getField('confirmModalCustom').setTitleAndContent(`Cancelar`, '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => {
            this.getField('confirmModalCustom').toggle(false);
            // this.cerrarModalPCP();
            this.getField('modal_prodclienteprovedor').handleClose(); // Con cerrar la modal, automáticamente llama la función "this.cerrarModalPCP();" que por eso la dejé comentada para que se entienda el porqué; y esto sucede, ya que en el initForm, se llama la misma función (this.cerrarModalPCP();) cuando usamos el método handleClose.
        });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    dibujarSelectTipoDescuento(){
        let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
        let data2 = {
            "data": [
                {
                    "valor": "A pie factura",
                    "text": "A pie factura"
                },
                {
                    "valor": "Al pagar la factura",
                    "text": "Al pagar la factura"
                }
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.valor;
            dataOp['text'] = item.text;
            dataOp['campos_cambian'] = {/* separador_select: item.valor */};
            opciones.push(dataOp);
        });
        this.getField('tipo_descuento').setOptions(opciones);
        this.getField('tipo_descuento').setValue('A pie factura');
    }
    
    cerrarModalPCP(){
        this.getField('articulo_terceros_id').setValue('');
        this.gridOptionsTbModPrecios['rowData'] = [];
        this.getField('tb_artercerosprecios').initData(this.gridOptionsTbModPrecios);
        this.getField('tb_artercerosprecios').toggle(false);
        this.limpiarModalPCP(false);
    }

    limpiarModalPCP(visible){
        let fecha = new Date();
        let fecha1 = fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];
        this.getField('fecha_inicio').setValue(fecha2);
        this.getField('fecha_final').setValue(fecha2);
        this.getField('unidades_id').setValue('');
        this.getField('cantidad_maxima').setValue('');
        this.getField('precio').setValue('');
        this.getField('descuento').setValue('');
        this.getField('tipo_descuento').setValue('');
        this.getField('articulo_terceros_precios_id').setValue('');

        this.getField('fecha_inicio').setError(false, '');
        this.getField('fecha_final').setError(false, '');
        this.getField('unidades_id').setError(false, '');
        this.getField('cantidad_maxima').setError(false, '');
        this.getField('precio').setError(false, '');
        this.getField('descuento').setError(false, '');
        this.getField('tipo_descuento').setError(false, '');

        this.getField('agregar_modal_prodclienteprovedor').setVisible(false);
        this.getField('fecha_inicio').setVisible(visible);
        this.getField('fecha_final').setVisible(visible);
        this.getField('unidades_id').setVisible(visible);
        this.getField('cantidad_maxima').setVisible(visible);
        this.getField('precio').setVisible(visible);
        this.getField('descuento').setVisible(visible);
        this.getField('tipo_descuento').setVisible(visible);
        this.getField('aceptarmodprecios').setVisible(visible);
        this.getField('cancelarmodprecios').setVisible(visible);
    }
    
    abrirTabla(){
        if (this.getField('nombre').getValue()!=='' && this.getField('sede').getValue()!=='') {
            let datos={ datos: {nitsx_sedes_id: this.getField('nitsx_sedes_id').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-productosclienteproveedor', operacion: '2_1', operacion_tipo: 'consulta' };
            this.service.send(
                {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successAbrirTabla,
                      error: this.error_,
                      general: this.generalFormatPmv
                });
        }else{
            this.gridOptionsTbPrincipal['rowData'] = [];
            this.getField('rejilla').toggle(false);
    
            this.getField('nitsx_sedes_id').setValue('');
            this.getField('articulo_id').setValue('');
    
            this.getField('agregar').setDisabled(true);
            this.getField('codigo_interno').setVisible(false);
            this.getField('nombre_articulo').setVisible(false);
            this.getField('codigo_tercero').setVisible(false);
            this.getField('descripcion').setVisible(false);
            this.getField('barras').setVisible(false);
            this.getField('aceptar').setVisible(false);
            this.getField('cancelar').setVisible(false);
        }
    }

    successAbrirTabla(data){
        if (data.estado_p === 200) {

            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.setCrearButtonRejilla, field: 'accion' });
            this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);

            // ↓↓ Las siguientes dos líneas es para que los tres botones dentro de la columna "Acción" de la tabla, aparezcan todos ↓↓
            const allColumnIds = ["accion"];
            this.gridOptionsTbPrincipal.columnApi.autoSizeColumns(allColumnIds, true);

            this.agregar(false);
            this.getField('agregar').setDisabled(false);
            this.getField('agregar').setVisible(true);

        }else if(data.estado_p === 404){
            this.getField('rejilla').toggle(false);
            this.agregar(true);
        }else{
            this.getField('rejilla').toggle(false);
            this.alertGeneral.toggle(true, 'Error al consultar los articulos terceros (No hay datos).', 'error');
            this.getField('agregar').setDisabled(false);
        }

    }

    setCrearButtonRejilla(props){

        let fragment = document.createDocumentFragment();
        let id = props.data.plantillas_electronicas_gastos_id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.DatosEliminar(props);
        button1.setAttribute("id", 'button_delete_' + id);
        button1.setAttribute("type", "button");
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        // button2.onclick = () => this.DatosModificar(props);
        button2.setAttribute("id", 'button_add_' + id);
        button2.setAttribute("type", "button");
        button2.setAttribute("value", "Modificar");
        button2.setAttribute("class","buttonStyle2");

        //segundo boton de la rejilla
        let button3 = document.createElement("input");
        button3.onclick = () => this.DatosPrecios(props);
        button3.setAttribute("id", 'button_add_' + id);
        button3.setAttribute("type", "button");
        button3.setAttribute("value", "Precios");
        button3.setAttribute("class","buttonStyle2");

                
        fragment.appendChild(button1);
        fragment.appendChild(button2);
        fragment.appendChild(button3);
        return this.createElementJaivana(fragment);

    }

    DatosPrecios(props){
        // console.log('Estas son las props de precios:\n',props);
        if (!(this.getField('articulo_terceros_id').getValue() !== '')) {
            this.getField('articulo_terceros_id').setValue(props.data.id);
        }

        if (this.getField('articulo_terceros_id').getValue() !== '') {
            let datos={ datos: {articulo_terceros_id: this.getField('articulo_terceros_id').getValue()}};
            this.generalFormatPmv = { tipo_servicio:  'maes-productosclienteproveedor', operacion: '2_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosPrecios,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successDatosPrecios(data){
        // console.log('Data successDatosPrecios:\n',data);
        if (data.estado_p === 200 && data.data.length>= 1) {
            this.getField('tb_artercerosprecios').toggle(true);
            let configCell = new Map();
            this.gridOptionsTbModPrecios['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.setCrearButtonTbModPrecios, field: 'accion' });
            this.getField('tb_artercerosprecios').initData(this.gridOptionsTbModPrecios,configCell);

            // ↓↓ Las siguientes dos líneas es para que los tres botones dentro de la columna "Acción" de la tabla, aparezcan todos ↓↓
            const allColumnIds = ["accion"];
            this.gridOptionsTbModPrecios.columnApi.autoSizeColumns(allColumnIds, true);

            this.getField('agregar_modal_prodclienteprovedor').setDisabled(false);
            this.getField('agregar_modal_prodclienteprovedor').setVisible(true);

        }else if(data.estado_p === 404){
            this.gridOptionsTbModPrecios['rowData'] = [];
            this.getField('tb_artercerosprecios').initData(this.gridOptionsTbModPrecios);
            this.getField('tb_artercerosprecios').toggle(false);
            this.limpiarModalPCP(true);
        }
        this.getField('modal_prodclienteprovedor').handleClickOpen();
        this.dibujarSelectTipoDescuento();
    }

    setCrearButtonTbModPrecios(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.articulo_terceros_precios_id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.DatosEliminarArtTerPre(props);
        button1.setAttribute("id", 'button_delete_' + id);
        button1.setAttribute("type", "button");
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    DatosEliminarArtTerPre(data){
        this.getField('articulo_terceros_precios_id').setValue(data.data.articulo_terceros_precios_id);
        this.getField('confirmModalCustom').setTitleAndContent(`Eliminar`, '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => {
            if (this.getField('articulo_terceros_precios_id').getValue() !== '') {
                let datos={ datos: {articulo_terceros_precios_id: this.getField('articulo_terceros_precios_id').getValue()}};
                this.generalFormatPmv = { tipo_servicio:  'maes-articulotercerosprecios', operacion: '7', operacion_tipo: 'eliminar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarArtTerPre,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
                this.getField('confirmModalCustom').toggle(false);
            }
        });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Eliminar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        
    }

    successEliminarArtTerPre(data){
        if (data.estado_p === 200 ) {
           this.DatosPrecios();
           this.limpiarModalPCP(false);
        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar los articulos terceros precios.', 'error');
        }
    }

    DatosEliminar(data){
        this.getField('confirmModalCustom').setTitleAndContent(`Eliminar`, '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => {
            let datos={ datos: {articulo_terceros_id: data.data.id}};
            this.generalFormatPmv = { tipo_servicio:  'maes-productosclienteproveedor', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
            });
            this.getField('confirmModalCustom').toggle(false);
        });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Eliminar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        
    }

    successEliminar(data){
        if (data.estado_p === 200 ) {
           this.abrirTabla();
           this.agregar(false);
        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar los articulos terceros.', 'error');
        }
    }

    agregar(visible){
        this.getField('codigo_interno').setVisible(visible);
        this.getField('nombre_articulo').setVisible(visible);
        this.getField('codigo_tercero').setVisible(visible);
        this.getField('descripcion').setVisible(visible);
        this.getField('barras').setVisible(visible);
        this.getField('codigo_interno').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('codigo_tercero').setValue('');
        this.getField('descripcion').setValue('');
        this.getField('barras').setValue('');
        this.getField('codigo_interno').setError(false,'');
        this.getField('nombre_articulo').setError(false,'');
        this.getField('codigo_tercero').setError(false,'');
        this.getField('descripcion').setError(false,'');
        this.getField('barras').setError(false,'');
        this.getField('agregar').setVisible(false);
        this.getField('aceptar').setVisible(visible);
        this.getField('cancelar').setVisible(visible);
    }

    cancelar(){

        this.getField('nit_buscar').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.gridOptionsTbPrincipal['rowData'] = [];
        this.getField('rejilla').toggle(false);

        this.getField('nitsx_sedes_id').setValue('');
        this.getField('articulo_id').setValue('');

        this.getField('agregar').setDisabled(true);
        this.getField('codigo_interno').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('codigo_tercero').setValue('');
        this.getField('descripcion').setValue('');
        this.getField('barras').setValue('');  

        this.getField('aceptar').setDisabled(true);
        this.getField('cancelar').setDisabled(true);
    }

    aceptar(){
        if (this.getField('sede').valid() && this.getField('nombre').valid() && this.getField('nombre_articulo').valid() && this.getField('codigo_tercero').valid() && this.getField('descripcion').valid() && this.getField('barras').valid()){
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['nitsx_sedes_id']=this.getField('nitsx_sedes_id').getValue();
            this.datosAgregar.datos['articulo_id']=this.getField('articulo_id').getValue();
            this.datosAgregar.datos['codigo_tercero']=this.getField('codigo_tercero').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-productosclienteproveedor', operacion: '20_1', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successAceptarPrincipal,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                }
            );
        }else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        }
    }

    successAceptarPrincipal(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            this.alertGeneral.toggle(true, 'Los campos Nit,Código interno,Código tercero ya existen, no se permite duplicar', "error");
        }else if(data.estado_p === 404){
            this.crearAticulosTerceros();
        }
    }
    
    crearAticulosTerceros(){
        let datos = {  
            datos: { 
            
                nitsx_sedes_id:this.getField('nitsx_sedes_id').getValue(),
                articulo_id:this.getField('articulo_id').getValue(),
                codigo_tercero:this.getField('codigo_tercero').getValue(),
                descripcion:this.getField('descripcion').getValue(),
                barras:this.getField('barras').getValue()
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'maes-productosclienteproveedor', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearAticulosTerceros,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    
    successCrearAticulosTerceros(data){
        if (data.estado_p === 200 ) {
            this.abrirTabla();
            this.getField('articulo_id').setValue('');
            this.getField('agregar').setDisabled(true);
            this.getField('codigo_interno').setValue('');
            this.getField('nombre_articulo').setValue('');
            this.getField('codigo_tercero').setValue('');
            this.getField('descripcion').setValue('');
            this.getField('barras').setValue('');
            this.getField('aceptar').setDisabled(true);
            this.getField('cancelar').setDisabled(true);
            this.agregar(false);
        } else {
            this.alertGeneral.toggle(true, 'Error al crear los articulos terceros.', 'error');
        } 
    }

    validarBotones(){
        if (this.getField('nombre_articulo').getValue()!== ''){
            this.getField('aceptar').setDisabled(false);
            this.getField('cancelar').setDisabled(false);
        }
    }

}

FormJaivana.addController("maes-productosclienteproveedor",CustomProductosClientesProveedores);
export default CustomProductosClientesProveedores