import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAsentarOrdenProduccion
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomAsentarOrdenProduccion extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.gridOptionsComponentes         = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1        = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes2        = Object.assign({}, this.gridOptions);
        this.gridOptionsConsultarModal      = Object.assign({}, this.gridOptions); 
        this.consultar_orden                = this.consultar_orden.bind(this);                          
        this.successConsultarOrden          = this.successConsultarOrden.bind(this);  
        this.procesar                       = this.procesar.bind(this);                          
        this.successProcesar                = this.successProcesar.bind(this);     
        this.creartemporal                  = this.creartemporal.bind(this);                     
        this.successcreartemporal           = this.successcreartemporal.bind(this);                     
        this.mostrartabla                   = this.mostrartabla.bind(this);                     
        this.successmostrartabla            = this.successmostrartabla.bind(this);             
        this.currencyFormatterGeneral       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo              = this.formatNumberSaldo.bind(this);    
        this.setButtonDelete                = this.setButtonDelete.bind(this);
        this.eliminar                       = this.eliminar.bind(this);
        this.confirmDelete                  = this.confirmDelete.bind(this);
        this.successEliminar                = this.successEliminar.bind(this);       
        this.setButtonModificar             = this.setButtonModificar.bind(this);
        this.Modificar                      = this.Modificar.bind(this);   
        this.saldototal                     = this.saldototal.bind(this);
        this.successSaldoTotal              = this.successSaldoTotal.bind(this);
        this.traerSaldos                    = this.traerSaldos.bind(this);
        this.successtraerSaldos             = this.successtraerSaldos.bind(this);
        this.traerUbicacion                 = this.traerUbicacion.bind(this);
        this.successtraerUbicacion          = this.successtraerUbicacion.bind(this);
        this.abrir_modal                    = this.abrir_modal.bind(this);
        this.calcular_item                  = this.calcular_item.bind(this);
        this.calcular_total                 = this.calcular_total.bind(this);
        this.cargar_factor                  = this.cargar_factor.bind(this);
        this.successcargarFactor            = this.successcargarFactor.bind(this);
        this.traercosto                     = this.traercosto.bind(this);
        this.successtraercosto              = this.successtraercosto.bind(this);
        this.validacionesAgregar            = this.validacionesAgregar.bind(this);
        this.validacionesAgregar2           = this.validacionesAgregar2.bind(this);
        this.sucessValidaciones             = this.sucessValidaciones.bind(this);
        this.successGrabarItem              = this.successGrabarItem.bind(this);
        this.cargarUbicaciones              = this.cargarUbicaciones.bind(this);
        this.successcargarUbicaciones       = this.successcargarUbicaciones.bind(this);
        this.grabarorden                    = this.grabarorden.bind(this);
        this.successGrabarOrden             = this.successGrabarOrden.bind(this);
        this.limpiar                        = this.limpiar.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAsentarOrdenProduccion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.getField("codigo_sucursal").setValue(this.codigo_sucursal);
        this.getField("bodega").setDisabled(false);
        this.getField("fecha").setDisabled(true);
        this.getField("agregar").setDisabled(true);
        this.getField("agregar").setDisabled(true);
        this.getField("numero").setOnBlur(this.consultar_orden);
        this.getField("agregar").setClick(()=>{this.getField("modal_articulos").handleClickOpen(); this.accion="agregar";this.abrir_modal()});
        this.getField("grabar_agregar").setClick(this.validacionesAgregar);
        this.getField("nombre_sucursal").setOnChange(()=>{if(this.getField("nombre_sucursal").getValue() !== ""){
                this.getField("nombre_sucursal").setError(false,"")
            }else if(this.comenzo === true){
                this.limpiar();
            }
        });
        this.getField("factor").setOnBlur(()=>{
            if(this.getField("nombre_articulo1").getValue() !== ""){
                this.getField("cantidad_unidad1").setValue(this.getField("cantidad_unidad").getValue().replaceAll(".",'').replace(",",'.'));
                this.calcular_item();
            }
        });
        this.getField("cantidad_total").setOnChange(()=>{
            if(this.getField("nombre_articulo1").getValue() !== ""){
                this.getField("cantidad_tota1").setValue(this.getField("cantidad_total").getValue().replaceAll(".",'').replace(",",'.'));
                this.calcular_total();
            }
        });
        this.getField("nombre_articulo1").setOnChange(this.abrir_modal);
        this.getField("asentar_orden").setDisabled(true);

        this.creartemporal();
        this.getField('consultar').setClick(this.procesar);
        this.getField('factura_compra').setValue("999999");
        this.getField('modal_articulos').setCloseButton(()=>{
            this.limpiarcampos();
            this.mostrartabla();
        });

        this.comenzo=false;

        this.getField("asentar_orden").setClick(this.grabarorden);
        this.getField("bodega").setOnChange(()=>{if(this.comenzo === true){this.limpiar();}});
        this.getField("nombre_articulo").setOnChange(()=>{if(this.comenzo === true){this.limpiar();}});
        this.getField("cantidad").setOnChange(()=>{if(this.comenzo === true){this.limpiar();}});
    }

    creartemporal(){
        let datos={ datos: {
            codigo_usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'creartemp', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcreartemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successcreartemporal(data){
        if(data.estado_p === 502){
            this.alertGeneral.toggle(true, 'Error al crearla tabla.', 'error');
        }else{
            this.mostrartabla();
        }
    }

    mostrartabla(){
        let datos={ datos: {
            codigo_usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'mostrartabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successmostrartabla,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successmostrartabla(data){
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.factor.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('cantxund', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantxund.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('cant_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cant_presupuestada.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('cant_utilizada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cant_utilizada.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            configCell.set('modificar', { cellRenderer: this.setButtonModificar, width: 110, sortable: false, filter: false, field: 'Modificar' });

            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);

        }else if(data.estado_p ===404){
            this.getField("bodega").setDisabled(false);
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


        
    setButtonDelete(props) {
        if(props.data.codigo !== "."){
            let codigo_articulo = props.data.codigo;
            let button = document.createElement("input");
            button.onclick = () => this.eliminar(codigo_articulo);
            button.setAttribute("codigo_articulo", 'button_delete_' + codigo_articulo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "eliminar");
            return this.createElementJaivana(button);
        }
    }

    eliminar(codigo_articulo) {
        this.codigo_articulo = codigo_articulo;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                codigo_producto: this.codigo_articulo,
                codigo_usuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'eliminar_articulo', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.mostrartabla();
            this.saldototal();
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    setButtonModificar(props) {
        if(props.data.codigo !== "."){
            let codigo = props.data.codigo;
            let nombre = props.data.nombre;
            let factor = props.data.factor;
            let cantxund = props.data.cantxund;
            let cantidad_total = props.data.cant_utilizada;
            let costo = props.data.costo;
            let costo_item = props.data.costo_item;
            let ubicacion = props.data.ubicacion;
            let button = document.createElement("input");
            button.onclick = () => this.Modificar(codigo,nombre,factor,cantxund,cantidad_total,costo,costo_item,ubicacion);
            button.setAttribute("codigo", 'button_delete_' + codigo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "Modificar");
            return this.createElementJaivana(button);
        }
    }

    Modificar(codigo,nombre,factor,cantxund,cantidad_total,costo,costo_item,ubicacion) {
        this.accion="modificar";
        this.getField("modal_articulos").handleClickOpen();
        this.getField("codigo_articulo").setValue(codigo);
        this.getField("nombre_articulo1").setValue(nombre);
        this.getField("codigo_articulo").setDisabled(true);
        this.getField("nombre_articulo1").setDisabled(true);
        this.getField("costo").setValue(costo);
        this.getField("cantidad_unidad").setValue(cantxund);
        this.getField("factor").setValue(factor);
        this.getField("cantidad_total").setValue(cantidad_total);
        this.getField("valor_item").setValue(costo_item);
        this.getField("ubicacion").setValue(ubicacion);
        this.traerSaldos();

    }

    consultar_orden(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() &&
            this.getField("numero").valid()){
            let datos = { datos: {
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                codigo_usuario:this.codigo_usuario,
                numero:this.getField("numero").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'consultar_orden', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successConsultarOrden(data){
        if(data.estado_p === 200){
            this.getField("codigo_producto").setValue(data.data[0].producto);
            this.getField("nombre_articulo").setValue(data.data[0].nombre);
            this.getField("cantidad").setValue(data.data[0].cantidad_presupuestada);
            this.getField("fecha").setValue(data.data[0].fecha_ini);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, data.data.errores, 'error');
        }else{
            this.alertGeneral.toggle(true, 'Error '+data.data.errores, 'error');
        }
    }

    procesar(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() &&
            this.getField("numero").valid() && this.getField("bodega").valid() && this.getField("unidad").valid() ){
            let datos = { datos: {
                sucursal: this.codigo_sucursal,
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                codigo_usuario:this.codigo_usuario,
                bodega:this.getField("bodega").getValue(),
                numero:this.getField("numero").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'insertaritems', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successProcesar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
    }

    successProcesar(data){
        if(data.estado_p === 200){
            this.getField("bodega").setDisabled(true);
            this.wms_bodega= data.data.bodega_wms;
            this.exigefactura= data.data.exigefactura;
            this.wms_nuevo= data.data.wms_nuevo;
            this.lasucursal= data.data.lasucursal;
            this.destino= data.data.destino;
            this.costo_promedio_linea= data.data.costo_promedio_linea;
            this.costo_bodega= data.data.costo_bodega;
            this.mostrartabla();
            this.saldototal();
            this.getField("agregar").setDisabled(false);
            this.getField("asentar_orden").setDisabled(false);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, data.data.errores, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    saldototal(){
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'saldo_total', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSaldoTotal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successSaldoTotal(data){
        if(data.estado_p === 200){
            this.total = data.data[0].valor;
            this.getField("total").setValue(this.currencyFormatterGeneral(data.data[0].valor));
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    limpiarcampos(){
        this.getField("codigo_articulo").setValue("");
        this.getField("nombre_articulo1").setValue("");
        this.getField("costo").setValue(0);
        this.getField("cantidad_unidad").setValue(0);
        this.getField("factor").setValue(0);
        this.getField("cantidad_total").setValue(0);
        this.getField("valor_item").setValue(0);
        this.getField("cantidad_unidad1").setValue(0);
        this.getField("cantidad_tota1").setValue(0);
        this.getField("valor_item1").setValue(0);
        this.getField("valor_item").setError(false,"");
        this.getField("ubicacion").setValue("");
        this.gridOptionsComponentes1['rowData'] = [];
        this.getField('rejilla_bodega').toggle(false);
        this.gridOptionsComponentes2['rowData'] = [];
        this.getField('rejilla_ubicacion').toggle(false);
    }

    traerSaldos(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'traerSaldos', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraerSaldos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraerSaldos(data){
        if(data.estado_p === 200){
            this.gridOptionsComponentes1['rowData'] = [];
            this.getField('rejilla_bodega').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data.saldo_bodegas;
            this.getField('rejilla_bodega').initData(this.gridOptionsComponentes1, configCell);
            this.traerUbicacion();
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    traerUbicacion(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue(),
            bodega_wms:this.wms_bodega,
            wms_nuevo:this.wms_nuevo
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'traerUbicacion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraerUbicacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraerUbicacion(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.gridOptionsComponentes2['rowData'] = [];
            this.getField('rejilla_ubicacion').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes2['rowData'] = data.data.saldos_ubicacion;
            this.getField('rejilla_ubicacion').initData(this.gridOptionsComponentes2, configCell);
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores.mensaje;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    abrir_modal(){
        
        this.getField("codigo_articulo").setDisabled(false);
        this.getField("nombre_articulo1").setDisabled(false);

        if(this.accion==="agregar" && this.getField("nombre_articulo1").getValue() !== ""){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            this.traerSaldos();
            this.cargar_factor(); 
            this.traercosto();
            if(this.wms_bodega === "S" && this.wms_nuevo==="S"){
                this.cargarUbicaciones();
            }
        }else{
            this.getField("costo").setValue(0);
            this.getField("cantidad_unidad").setValue(0);
            this.getField("factor").setValue(0);
            this.getField("cantidad_total").setValue(0);
            this.getField("valor_item").setValue(0);

            if(this.wms_bodega === "S" && this.wms_nuevo==="S"){
                this.getField("ubicacion").setDisabled(true);
                this.getField("select_ubicacion").setDisabled(false);
                this.getField("ubicacion").setValue("."); 
            }else{
                this.getField("ubicacion").setValue("999999999999"); 
                this.getField("ubicacion").setDisabled(false);
                this.getField("select_ubicacion").setDisabled(true);
            }
            this.getField("valor_item").setError(false,"");

        }
    }

    calcular_item() {
        //calular el valor del item
        
        let xcosto = this.getField("costo").getValue();
        let xcantidad = this.getField("cantidad_unidad1").getValue();
        let xfactor = this.getField("factor").getValue();

        //Armar los calculos
        let xcosto1 = 0.0, xcantidad1 = 0.0;
        let xfactor1 = 1.0;
        let xcanttotal = 0.0;
        let xvalor_item = 0.0;


        xfactor1 =xfactor;
        xcantidad1 = xcantidad;
        xcosto1 = xcosto;

        xcanttotal = this.getField("unidad").getValue() * xfactor1 * xcantidad1;
        xvalor_item = xcanttotal * xcosto1;

        this.getField("cantidad_total").setValue(this.currencyFormatterGeneral(xcanttotal.toFixed(4)));
        this.getField("valor_item").setValue(this.currencyFormatterGeneral(xvalor_item.toFixed(4)));
        this.getField("cantidad_tota1").setValue(xcanttotal);
        this.getField("valor_item1").setValue(xvalor_item);

    }

    calcular_total() {
        //calular el valor del item
        
        let xcosto = this.getField("costo").getValue();
        let xcantidad_total = this.getField("cantidad_tota1").getValue();
        let xfactor = this.getField("factor").getValue();

        //Armar los calculos
        let xcosto1 = 0.0, xcantidad1 = 0.0;
        let xfactor1 = 1.0;
        let xcanttotal = 0.0;
        let xvalor_item = 0.0;


        xfactor1 =xfactor;
        xcanttotal = xcantidad_total;
        xcosto1 = xcosto;

        xcantidad1 = xcanttotal/(this.getField("unidad").getValue() * xfactor1);
        xvalor_item = xcanttotal * xcosto1;

        
        this.getField("cantidad_unidad").setValue(this.currencyFormatterGeneral(xcantidad1.toFixed(4)));
        this.getField("valor_item").setValue(this.currencyFormatterGeneral(xvalor_item.toFixed(4)));
        this.getField("cantidad_unidad1").setValue(xcantidad1);
        this.getField("valor_item1").setValue(xvalor_item);
        
    }

    cargar_factor(){
        let datos = { datos: {
            codigo_articulo:this.getField("codigo_articulo").getValue(),
            codigo_producto:this.getField("codigo_producto").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'cargarfactor', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcargarFactor,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successcargarFactor(data){
        if(data.estado_p === 200){
            this.getField("cantidad_unidad").setValue(data.data[0].cantidad);
            this.getField("factor").setValue(data.data[0].factor);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    traercosto(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.codigo_sucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'traercosto', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraercosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraercosto(data){
        if(data.estado_p === 200){
            this.getField("costo").setValue(data.data.costo);
        }else if(data.estado_p ===404){
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    validacionesAgregar(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo1").valid() && this.getField("costo").valid()
        && this.getField("cantidad_unidad").valid() && this.getField("factor").valid() && this.getField("cantidad_total").valid()
        && this.getField("ubicacion").valid()){
            if(this.wms_bodega === "S" && this.wms_nuevo === "S"){
                if(this.getField("select_ubicacion").valid()){
                    if(Number(this.getField('valor_item').getValue()!==0)){
                        this.getField('grabar_agregar').setDisabled(true);
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                        this.getField('confirmModalCustom').setClickDialog(()=>{});  
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                            let datos={ datos: {
                                codigo_sucursal:this.codigo_sucursal,
                                bodega:this.getField('bodega').getValue(),
                                costo:this.getField('costo').getValue(),
                                codigo_articulo:this.getField('codigo_articulo').getValue()
                            }};
                            this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                            this.service.send(
                            {
                                endpoint: this.constant.formConfiguration(),
                                method:'GET',
                                body: datos,
                                success: this.sucessValidaciones,
                                error: this.error_,
                                general: this.generalFormatPmv,
                                showMessage: false
                            });
                    }else{
                        this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
                    } 
                }else if(this.accion === "modificar"){
                    this.getField("select_ubicacion").setError(false,"");
                    this.getField('grabar_agregar').setDisabled(true);
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                    this.getField('confirmModalCustom').setClickDialog(()=>{});  
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos={ datos: {
                        codigo_sucursal:this.codigo_sucursal,
                        bodega:this.getField('bodega').getValue(),
                        costo:this.getField('costo').getValue(),
                        codigo_articulo:this.getField('codigo_articulo').getValue()
                    }};
                    this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessValidaciones,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
                }
            }else{
                if(Number(this.getField('valor_item').getValue()!==0)){
                    this.getField('grabar_agregar').setDisabled(true);
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                    this.getField('confirmModalCustom').setClickDialog(()=>{});  
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                        let datos={ datos: {
                            codigo_sucursal:this.codigo_sucursal,
                            bodega:this.getField('bodega').getValue(),
                            costo:this.getField('costo').getValue(),
                            codigo_articulo:this.getField('codigo_articulo').getValue()
                        }};
                        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.sucessValidaciones,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                }else{
                    this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
                }    
            }
        }
    }

    validacionesAgregar2(input){
        this.getField('grabar_agregar').setDisabled(true);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.getField('codigo_articulo').valid()){
            let datos={ datos: {
                  sucursal:this.codigo_sucursal,
                  input:input,
                  bodega:this.getField('bodega').getValue(),
                  costo:this.getField('costo').getValue(),
                  codigo_articulo:this.getField('codigo_articulo').getValue(),
                  codigo_usuario:this.codigo_usuario
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    sucessValidaciones(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200 && data.data.paso === "")
        { 
            if(data.data.costo_pro!==undefined){
                //pedir el input
                let costo_pro=data.data.costo_pro;
                if(costo_pro===0){
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('bodega').getValue()+' es cero. ¿Está seguro de continuar?');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }else{
                    let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                    let margen_costo_promedio=data.data.margen_costo_promedio;
                    if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+valor_costo+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    }else{
                    this.validacionesAgregar2(0)
                    }
                }
            }else{
                return true;
            }
        }
        else 
        {
            if(data.estado_p===200 && data.data.paso==="true") {
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                let datos = { datos: {
                    bodega_wms: this.wms_bodega,
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega").getValue(),
                    codigo_articulo:this.getField("codigo_articulo").getValue(),
                    nombre_articulo1:this.getField("nombre_articulo1").getValue(),
                    ubicacion:this.getField("ubicacion").getValue(),
                    cantidad_unidad:this.getField("cantidad_unidad1").getValue(),
                    costo:this.getField("costo").getValue(),
                    factor:this.getField("factor").getValue(),
                    cantidad_total:this.getField("cantidad_tota1").getValue(),
                    valor_item:this.getField("valor_item1").getValue(),
                    pantalla:"asentar",
                    accion:this.accion,
                    wms_nuevo:this.wms_nuevo,
                    codigo_usuario:this.codigo_usuario
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGrabarItem,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    successGrabarItem(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.saldototal();
            this.limpiarcampos();
            this.alertGeneral.toggle(true, 'Registro grabado. ', 'success');
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    cargarUbicaciones(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'cargarubicaciones', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcargarUbicaciones,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successcargarUbicaciones(data){
        if (data.estado_p === 200 )
        {
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.ubicacion_codigo;
                dataOp['campos_cambian'] = { ubicacion: item.ubicacion_codigo};
                opciones.push(dataOp);
            });
            this.getField('select_ubicacion').setOptions(opciones);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    grabarorden(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() 
        && this.getField("codigo_producto").valid() && this.getField("nombre_articulo").valid() && this.getField("cantidad").valid()
        && this.getField("bodega").valid() && this.getField("nombre_bodega").valid() && this.getField("unidad").valid() 
        && this.getField("total").valid() && this.getField("factura_compra").valid() && this.getField("nit").valid()
        && this.getField("sede").valid() && this.getField("nombre").valid()){
            //if(this.getField("total").getValue() > 0){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                let datos = { datos: {
                    codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                    sucursal:this.lasucursal,
                    bodega:this.getField("bodega").getValue(),
                    codigo_producto:this.getField("codigo_producto").getValue(),
                    cantidad:this.getField("cantidad").getValue(),
                    numero:this.getField("numero").getValue(),
                    nit:this.getField("nit").getValue(),
                    sede:this.getField("sede").getValue(),
                    factura_compra:this.getField("factura_compra").getValue(),
                    wms_nuevo:this.wms_nuevo,
                    total:this.total,
                    fecha:this.getField("fecha").getValue(),
                    bodega_wms:this.wms_bodega,
                    exigefactura:this.exigefactura,
                    codigo_usuario:this.codigo_usuario,
                    fecha_fin:"now ()",
                    xdestino:this.destino,
                    costo_promedio_linea:this.costo_bodega,
                    costo_por_bodega: this.costo_promedio_linea
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-asentarordenproduccion', operacion: 'grabarasentar', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGrabarOrden,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
            /*}else{
                this.alertGeneral.toggle(true, 'Valor de unidades producidas inválido.', 'error');
            }*/
        }
    }

    successGrabarOrden(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.numero_consecutivo=data.data.consecutivo;
            this.alertGeneral.toggle(true, 'DATOS GRABADOS CORRECTAMENTE. ', 'success');
            this.limpiarTodo();
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores.mensaje;
            //this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', respuesta);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else{
            let respuesta=data.data.errores.mensaje;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    limpiarTodo(){
        this.getField("codigo_producto").setValue("");
        this.getField("nombre_articulo").setValue("");
        this.getField("codigo_sucursal").setValue("");
        this.getField("nombre_sucursal").setValue("");
        this.getField("numero").setValue("");
        this.getField("bodega").setValue("");
        this.getField("nombre_bodega").setValue("");
        this.getField("unidad").setValue("");
        this.getField("fecha").setValue("");
        this.getField("nit").setValue("");
        this.getField("sede").setValue("");
        this.getField("nombre").setValue("");
        this.getField("cantidad").setValue("");
        this.getField("total").setValue("");
        this.gridOptionsComponentes['rowData'] = [];
        this.getField('rejilla').toggle(false);
        this.initForm();
    }

    limpiar(){
        this.comenzo=false;
        this.getField("total").setValue("");
        this.gridOptionsComponentes['rowData'] = [];
        this.getField('rejilla').toggle(false);
    }
   
}
FormJaivana.addController("inve-asentarordenproduccion", CustomAsentarOrdenProduccion);
export default CustomAsentarOrdenProduccion;