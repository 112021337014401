import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomCateraGeneral
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomCateraGeneral extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChangedPrincipal          = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']                            = this.onSelectionChangedPrincipal.bind(this);


    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);


    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.successCrearTemporal                         =this.successCrearTemporal.bind(this);
    this.successTraerDatosCartera                         =this.successTraerDatosCartera.bind(this);
    this.abrirModalCuentas                         =this.abrirModalCuentas.bind(this);
    this.generarTablasSaldosNit                         =this.generarTablasSaldosNit.bind(this);
    this.successSaldosNit                         =this.successSaldosNit.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);

    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.boton='';
    this.fecha_inicio='';
    this.sucursal_ingreso='';

    this.nombre_archivo='';
    this.archivo_servicio='';
    this.archivo_operacion='';
    this.fila_seleccionada=[];
    this.cuenta_inicial='';
    this.cuenta_final='';
  }
  
  initForm() {
    console.log("Formulario CustomCateraGeneral,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    //nuevo para consulta
    this.getField('consultar').setClick(this.validarConsultar);

    this.getField('codigo_sucursal').setOnChange(()=>{
        this.getField('nombre_sucursal').setError(false,'');
        this.ocultarCampos();
        
    });

    this.getField('codigo_cuenta').setOnChange(()=>{
        this.getField('nombre_cuenta').setError(false,'');
        this.ocultarCampos();
        
    });

    this.getField('codigo_cuenta_inicial').setOnChange(()=>{
        this.getField('nombre_cuenta_inicial').setError(false,'');
        
    });
    this.getField('codigo_cuenta_final').setOnChange(()=>{
        this.getField('nombre_cuenta_final').setError(false,'');
        
    });


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    //botones principal
    this.getField('bt_imprimir').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='solo';
            this.nombre_archivo='carterap';
            this.archivo_operacion='archivoitems_pag';
            this.abrirModalCuentas();
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    });

    this.getField('bt_imprimir_todo').setClick(()=>{
        this.boton='todo';
        this.nombre_archivo='carterap_todo';
        this.archivo_operacion='archivoitems_pag';
        this.abrirModalCuentas();
    });
    //se deja deshabilitado este boton ya que en escritorio
    //genera error al generar el pdf, así que no se sabe bien como es el jasper
    //ya que este no abre
    this.getField('bt_resumen').setDisabled(true);
    this.getField('bt_resumen').setClick(()=>{
        this.nombre_archivo='carterap_resumen';
        this.boton='resumen';
        this.archivo_operacion='archivoitemsresumen_pag';
        this.abrirModalCuentas();
    });

    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>{
        this.procesar();
    });
    this.getField('bt_aceptar_cuenta').setClick(()=>{
        if(this.getField('ch_imprimir_todo').getValue()==='true'){
            this.cuenta_inicial='';
            this.cuenta_final='';
            this.generarTablasSaldosNit();
        }else{
            if(this.getField('codigo_cuenta_inicial').valid() && this.getField('nombre_cuenta_inicial').valid() 
            && this.getField('codigo_cuenta_final').valid() && this.getField('nombre_cuenta_final').valid())
            {
                this.cuenta_inicial=this.getField('codigo_cuenta_inicial').getValue();
                this.cuenta_final=this.getField('codigo_cuenta_final').getValue();
                this.generarTablasSaldosNit();
            }else{
                this.mostrarMensajeAdicional('Por favor, ingrese dos cuentas válidas.');
            }
        }
    });
    this.getField('bt_cancelar_cuenta').setClick(()=>{
        this.getField('modal_cuentas').handleClose();
}   );

    this.getField('ch_imprimir_todo').setOnChange((props)=>{
        if(this.getField('ch_imprimir_todo').getValue()==='false'){
            this.getField('codigo_cuenta_inicial').setDisabled(true);
            this.getField('codigo_cuenta_final').setDisabled(true);
            this.getField('codigo_cuenta_inicial').setValue('');
            this.getField('nombre_cuenta_inicial').setValue('');
            this.getField('codigo_cuenta_final').setValue('');
            this.getField('nombre_cuenta_final').setValue('');
            this.getField('codigo_cuenta_inicial').setError(false,'');
            this.getField('nombre_cuenta_inicial').setError(false,'');
            this.getField('codigo_cuenta_final').setError(false,'');
            this.getField('nombre_cuenta_final').setError(false,'');
        }else{
            this.getField('codigo_cuenta_inicial').setDisabled(false);
            this.getField('codigo_cuenta_final').setDisabled(false);
        }
    });


    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });

  }

  generarTablasSaldosNit(){
    let datos={datos:{
        sucursal_ingreso: this.sucursal_ingreso,
        operacion : this.operacion_actual,
        email : this.getField('email').getValue(),
        radio_correo : this.getField('radio_correo').getValue(),
    }}
    if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
        datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
    if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' ')
        datos.datos.codigo_cuenta=this.getField('codigo_cuenta').getValue();

    if(this.cuenta_inicial==='' && this.cuenta_final===''){
        //se entiende en backend que seleccionaron imprimir todo
    }else{
        datos.datos.codigo_cuenta_inicial=this.cuenta_inicial;
        datos.datos.codigo_cuenta_final=this.cuenta_final;
    }
    if(this.boton==='solo'){
        datos.datos.nit=this.fila_seleccionada[0].nit;
        datos.datos.nproveedor=this.fila_seleccionada[0].nproveedor;
    }
    let op='';
    if(this.boton==='resumen')
        op='generarresumen'; //crear temporal de resumen
    else
        op='generarsaldosnit'; //crear temporal de imprimir
    this.mostrarMensajeGenerando();
    this.generalFormatPmv = { tipo_servicio: 'comp-carterageneral', operacion:  op, operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successSaldosNit,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  successSaldosNit(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('modal_cuentas').handleClose();
        this.abrirImprimir();
    }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  abrirModalCuentas(){
    this.getField('modal_cuentas').handleClickOpen();
    this.getField('ch_imprimir_todo').setValueSwitch(false);
    this.getField('codigo_cuenta_inicial').setDisabled(false);
    this.getField('codigo_cuenta_final').setDisabled(false);
  }

  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }

  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('rejilla_items').toggle(false);
    this.getField('bt_imprimir').setVisible(false);
    this.getField('bt_imprimir_todo').setVisible(false);
    this.getField('bt_resumen').setVisible(false);
  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-carterageneral', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



  validarConsultar(){

    this.getField('codigo_sucursal').setError(false,'');
    this.getField('nombre_sucursal').setError(false,'');
    this.getField('codigo_cuenta').setError(false,'');
    this.getField('nombre_cuenta').setError(false,'');

    let errores=0;
    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
    }
    if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' '){
        if(!this.getField('codigo_cuenta').valid())
            errores++;
        if(!this.getField('nombre_cuenta').valid())
            errores++;
    }
    if(errores===0){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('nombre_cuenta').getValue()!=='' && this.getField('nombre_cuenta').getValue()!==' ')
            datos.datos.codigo_cuenta=this.getField('codigo_cuenta').getValue();
        this.mostrarMensajeGenerando();
        this.generalFormatPmv = { tipo_servicio: 'comp-carterageneral', operacion:  'generarcarteraxsucursal', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }

  }

  successCrearTemporal(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
        }}
        this.mostrarMensajeGenerando();
        this.getField("rejilla_items").setTipoServicioOperacion('comp-carterageneral-1_cartera');
        this.getField("rejilla_items").sendRequest(this.successTraerDatosCartera, datos); 
    }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  successTraerDatosCartera(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
          this.getField('rejilla_items').toggle(true);
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data;
          //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
          configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});

          this.getField('rejilla_items').initData(this.gridOptionsComponentes,configCell);
          this.gridOptionsComponentes.columnApi.autoSizeAllColumns(false);// false: para acomodar los anchos de las columnas, según el ancho del encabezado.

          this.getField('bt_imprimir').setVisible(true);
          this.getField('bt_imprimir_todo').setVisible(true);
          this.getField('bt_resumen').setVisible(true);
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

  onSelectionChanged(){
    
  }


    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    //modal imprimir

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir(){
            
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }

    procesar(){
        this.archivo_servicio='comp-carterageneral';
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' ')
            datos.datos.codigo_cuenta=this.getField('codigo_cuenta').getValue();

        if(this.cuenta_inicial==='' && this.cuenta_final===''){
            //se entiende en backend que seleccionaron imprimir todo
        }else{
            datos.datos.codigo_cuenta_inicial=this.cuenta_inicial;
            datos.datos.codigo_cuenta_final=this.cuenta_final;
        }
        if(this.boton==='solo'){
            datos.datos.nit=this.fila_seleccionada[0].nit;
            datos.datos.sede=this.fila_seleccionada[0].sede;
        }
        if(this.boton==='resumen'){
            //no sé conoce la estructura del jasper,no es posible establecer pantalla
            this.mostrarMensajeAdicional("Funcionalidad no disponible.");
        }else{
            this.getField("rejilla_items_result").setTipoServicioOperacion('comp-carterageneral-'+this.archivo_operacion.replace('_pag',''));
            this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos); 
        }
  }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "ruc": this.lista.nit,
                    "titulo": this.lista.titulo
                };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);
            this.getField('modal_result').setLabel('ANÁLISIS DE CARTERA  FECHA DE CORTE: '+this.lista.fecha_sistema);
            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('por_vencer', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.por_vencer) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('del_1_al_30', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.del_1_al_30) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('del_31_al_60', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.del_31_al_60) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('del_61_al_90', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.del_61_al_90) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mas_de_90', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mas_de_90) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
            this.gridOptionsComponentesItems.columnApi.autoSizeAllColumns(false);

            this.getField('saldo_total').setValue(this.arreglarDecimales(this.lista.saldo_total));
            this.getField('total_por_vencer').setValue(this.arreglarDecimales(this.lista.total_por_vencer));
            this.getField('porcentajes_1').setValue(this.lista.porcentaje1);

            this.getField('total_30').setValue(this.arreglarDecimales(this.lista.total_30));
            this.getField('porcentajes_2').setValue(this.lista.porcentaje2);

            this.getField('total_60').setValue(this.arreglarDecimales(this.lista.total_60));
            this.getField('porcentajes_3').setValue(this.lista.porcentaje3);

            this.getField('total_90').setValue(this.arreglarDecimales(this.lista.total_90));
            this.getField('porcentajes_4').setValue(this.lista.porcentaje4);

            this.getField('total_mas_de_90').setValue(this.arreglarDecimales(this.lista.total_mas_de_90));
            this.getField('porcentajes_5').setValue(this.lista.porcentaje5);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' ')
            datos.datos.codigo_cuenta=this.getField('codigo_cuenta').getValue();

        if(this.cuenta_inicial==='' && this.cuenta_final===''){
            //se entiende en backend que seleccionaron imprimir todo
        }else{
            datos.datos.codigo_cuenta_inicial=this.cuenta_inicial;
            datos.datos.codigo_cuenta_final=this.cuenta_final;
        }
        if(this.boton==='solo'){
            datos.datos.nit=this.fila_seleccionada[0].nit;
            datos.datos.sede=this.fila_seleccionada[0].sede;
        }
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else if(data.estado_p === 404){
        this.mostrarMensajeNoHayDatos();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
            }
            if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' ')
                datos2.codigo_cuenta=this.getField('codigo_cuenta').getValue();

            if(this.cuenta_inicial==='' && this.cuenta_final===''){
                //se entiende en backend que seleccionaron imprimir todo
            }else{
                datos2.codigo_cuenta_inicial=this.cuenta_inicial;
                datos2.codigo_cuenta_final=this.cuenta_final;
            }
            if(this.boton==='solo'){
                datos2.nit=this.fila_seleccionada[0].nit;
                datos2.sede=this.fila_seleccionada[0].sede;
            }
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
            }
            if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('codigo_cuenta').getValue()!=='' && this.getField('codigo_cuenta').getValue()!==' ')
                datos2.codigo_cuenta=this.getField('codigo_cuenta').getValue();

            if(this.cuenta_inicial==='' && this.cuenta_final===''){
                //se entiende en backend que seleccionaron imprimir todo
            }else{
                datos2.codigo_cuenta_inicial=this.cuenta_inicial;
                datos2.codigo_cuenta_final=this.cuenta_final;
            }
            if(this.boton==='solo'){
                datos2.nit=this.fila_seleccionada[0].nit;
                datos2.sede=this.fila_seleccionada[0].sede;
            }
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }
   
}


FormJaivana.addController("comp-carterageneral", CustomCateraGeneral);
export default CustomCateraGeneral;