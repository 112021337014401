import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomIntegrarBancosMulticash
 * @author Santiago Hernández N.
 * @Create 27-10-2023
 * @updateBy Sebastian Rios Echeverri
 * @version: jdesk_1.01.0002
 * @updated 04-03-2024
*/
class CustomIntegrarBancosMulticash extends FormJaivana.form {


    constructor(props) {

        super(props);
        this.initForm                                       = this.initForm.bind(this);
        this.cargarSelect                                   = this.cargarSelect.bind(this);
        this.cargarSelectBancosCuentas                      = this.cargarSelectBancosCuentas.bind(this)
        this.successCargarSelectBancos                      = this.successCargarSelectBancos.bind(this);
        this.successCargarSelectBancosCuentas               = this.successCargarSelectBancosCuentas.bind(this);
        this.validarCampos                                  = this.validarCampos.bind(this);
        this.validarFechaMenorActual                        = this.validarFechaMenorActual.bind(this);
        this.validRangoFechas                               = this.validRangoFechas.bind(this);
        this.successMostrarTabla                            = this.successMostrarTabla.bind(this);
        this.gridOptionsComponentesMulticash                = Object.assign({},this.gridOptions);
        this.gridOptionsTabla                               = Object.assign({},this.gridOptions);
        this.gridOptionsTablaItemsTercero                   = Object.assign({},this.gridOptions);
        this.gridOptionsTablaConfigReglasDetalleTerceros    = Object.assign({},this.gridOptions);
        this.setButtonGraficarLinea                         = this.setButtonGraficarLinea.bind(this);
        this.crearBotonTabla                                = this.crearBotonTabla.bind(this);
        this.accionAbrirModal                               = this.accionAbrirModal.bind(this);
        this.successCargarSelectOperadores                  = this.successCargarSelectOperadores.bind(this);

        this.abrirModalReglas                               = this.abrirModalReglas.bind(this);
        this.validarCamposModalMulticash                    = this.validarCamposModalMulticash.bind(this);
        this.pantallaPrincipal                              = this.pantallaPrincipal.bind(this);

        this.regla                                          = {};
        this.reglaTercero                                   = {};
        this.camposArrayMulticash                           = [];
        this.campo                                          = 0;
        this.nuevoArray                                     = [];
        this.nuevoArrayTercero                              = [];
        this.arrayTemporal                                  = [];
        this.arrayTemporalTercero                           = [];
        this.loadTablaItems                                 = this.loadTablaItems.bind(this);
        this.crearBotonesTablaItem                          = this.crearBotonesTablaItem.bind(this);
        this.crearBotonTabla2                               = this.crearBotonTabla2.bind(this);
        this.accionBoton                                    = this.accionBoton.bind(this);
        this.eliminarItemTablaItem                          = this.eliminarItemTablaItem.bind(this);
        this.eliminarItemTabla                              = this.eliminarItemTabla.bind(this);
        this.validarModalFinal                              = this.validarModalFinal.bind(this);
        this.banco_cuenta                                   = "";
        this.successTraerBanco                              = this.successTraerBanco.bind(this);
        this.grabarRegla                                    = this.grabarRegla.bind(this);
        this.successCrearRegla                              = this.successCrearRegla.bind(this);
        this.mostrarConfigReglas                            = this.mostrarConfigReglas.bind(this);
        this.successConfigReglas                            = this.successConfigReglas.bind(this);
        this.gridOptionsTablaConfigReglas                   = Object.assign({},this.gridOptions);
        this.setButtonGraficarReglas                        = this.setButtonGraficarReglas.bind(this);
        this.DatosVerMas                                    = this.DatosVerMas.bind(this);
        this.successDatosVerMas                             = this.successDatosVerMas.bind(this);
        this.gridOptionsTablaConfigReglasDetalle            = Object.assign({},this.gridOptions);
        this.setButtonAplicarReglas                         = this.setButtonAplicarReglas.bind(this);
        this.AplicarReglaArchivos                           = this.AplicarReglaArchivos.bind(this);
        this.successAplicarRegla                            = this.successAplicarRegla.bind(this);
        this.selectionRegistroAplica                        = this.selectionRegistroAplica.bind(this);
        this.selectionRegistroAplica2                       = this.selectionRegistroAplica2.bind(this);
        this.selectionRegistroAplica3                       = this.selectionRegistroAplica3.bind(this);
        this.selectionRegistroAplica4                       = this.selectionRegistroAplica4.bind(this);
        this.selectionRegistroAplica5                       = this.selectionRegistroAplica5.bind(this);
        this.selectionRegistroAplica6                       = this.selectionRegistroAplica6.bind(this);
        this.selectionRegistroAplica7                       = this.selectionRegistroAplica7.bind(this);
        this.selectionRegistroAplica8                       = this.selectionRegistroAplica8.bind(this);
        this.selectionRegistroAplica9                       = this.selectionRegistroAplica9.bind(this);
        this.selectionRegistroAplica10                      = this.selectionRegistroAplica10.bind(this);
        this.selectionRegistroAplica11                      = this.selectionRegistroAplica11.bind(this);
        this.selectionRegistroAplica12                      = this.selectionRegistroAplica12.bind(this);
        this.selectionRegistroAplica13                      = this.selectionRegistroAplica13.bind(this);
        this.selectionRegistroAplica14                      = this.selectionRegistroAplica14.bind(this);
        this.selectionRegistroAplica15                      = this.selectionRegistroAplica15.bind(this);
        this.selectionRegistroAplica16                      = this.selectionRegistroAplica16.bind(this);
        this.selectionRegistroAplica17                      = this.selectionRegistroAplica17.bind(this);
        this.selectionRegistroAplica18                      = this.selectionRegistroAplica18.bind(this);
        this.selectionRegistroAplica19                      = this.selectionRegistroAplica19.bind(this);
        this.selectionRegistroAplica20                      = this.selectionRegistroAplica20.bind(this);
        this.selectionRegistroAplica21                      = this.selectionRegistroAplica21.bind(this);
        this.selectionRegistroAplica22                      = this.selectionRegistroAplica22.bind(this);
        this.selectionRegistroAplica23                      = this.selectionRegistroAplica23.bind(this);
        this.selectionRegistroAplica24                      = this.selectionRegistroAplica24.bind(this);
        this.selectionRegistroAplica25                      = this.selectionRegistroAplica25.bind(this);
        this.selectionRegistroAplica26                      = this.selectionRegistroAplica26.bind(this);
        this.selectionRegistroAplica27                      = this.selectionRegistroAplica27.bind(this);
        this.selectionRegistroAplica28                      = this.selectionRegistroAplica28.bind(this);
        this.selectionRegistroAplica29                      = this.selectionRegistroAplica29.bind(this);
        this.selectionRegistroAplica30                      = this.selectionRegistroAplica30.bind(this);
        this.selectionRegistroAplica31                      = this.selectionRegistroAplica31.bind(this);
        this.selectionRegistroAplica32                      = this.selectionRegistroAplica32.bind(this);
        this.selectionRegistroAplica33                      = this.selectionRegistroAplica33.bind(this);
        this.selectionRegistroAplica34                      = this.selectionRegistroAplica34.bind(this);
        this.selectionRegistroAplica35                      = this.selectionRegistroAplica35.bind(this);
        this.selectionRegistroAplica36                      = this.selectionRegistroAplica36.bind(this);
        this.selectionRegistroAplica37                      = this.selectionRegistroAplica37.bind(this);
        this.selectionRegistroAplica38                      = this.selectionRegistroAplica38.bind(this);
        this.selectionRegistroAplica39                      = this.selectionRegistroAplica39.bind(this);
        this.selectionRegistroAplica40                      = this.selectionRegistroAplica40.bind(this);
        this.selectionRegistroAplica41                      = this.selectionRegistroAplica41.bind(this);
        this.selectionRegistroAplica42                      = this.selectionRegistroAplica42.bind(this);
        this.selectionRegistroAplica43                      = this.selectionRegistroAplica43.bind(this);
        this.selectionRegistroAplica44                      = this.selectionRegistroAplica44.bind(this);
        this.selectionRegistroAplica45                      = this.selectionRegistroAplica45.bind(this);
        this.selectionRegistroAplica46                      = this.selectionRegistroAplica46.bind(this);
        this.selectionRegistroAplica47                      = this.selectionRegistroAplica47.bind(this);
        this.selectionRegistroAplica48                      = this.selectionRegistroAplica48.bind(this);
        this.selectionRegistroAplica49                      = this.selectionRegistroAplica49.bind(this);
        this.selectionRegistroAplica50                      = this.selectionRegistroAplica50.bind(this);
        this.selectionRegistroAplica51                      = this.selectionRegistroAplica51.bind(this);
        this.selectionRegistroAplica52                      = this.selectionRegistroAplica52.bind(this);
        this.selectionRegistroAplica53                      = this.selectionRegistroAplica53.bind(this);
        this.selectionRegistroAplica54                      = this.selectionRegistroAplica54.bind(this);
        this.selectionRegistroAplica55                      = this.selectionRegistroAplica55.bind(this);
        this.selectionRegistroAplica56                      = this.selectionRegistroAplica56.bind(this);
        this.mostrarReglas                                  = this.mostrarReglas.bind(this);
        this.llenarOperadorLogico                           = this.llenarOperadorLogico.bind(this);
        this.abrirtabla                                     = this.abrirtabla.bind(this);
        this.successAbrirTabla                              = this.successAbrirTabla.bind(this);
        this.gridOptionsComponentes                         = Object.assign({},this.gridOptions);
        this.gridOptionsTablaPlantillasPredefrinidos        = Object.assign({},this.gridOptions);
        this.DatosVerMasPlantillas                          = this.DatosVerMasPlantillas.bind(this);
        this.gridOptionsComponentesPredefinidos             = Object.assign({},this.gridOptions);
        this.successDatosVerMasPlantillas                   = this.successDatosVerMasPlantillas.bind(this);
        this.pantallaInicio                                 = this.pantallaInicio.bind(this);
        this.configurarTercero                              = this.configurarTercero.bind(this);
        this.traerValores                                   = this.traerValores.bind(this);
        this.successDatosValores                            = this.successDatosValores.bind(this);
        this.quitarCaracteresFinal                          = this.quitarCaracteresFinal.bind(this);
        this.quitarCaracteresInicio                         = this.quitarCaracteresInicio.bind(this);

        this.mostrarReglasTerceros                          = this.mostrarReglasTerceros.bind(this);
        this.loadTablaItemsTerceros                         = this.loadTablaItemsTerceros.bind(this);
        this.limpiarTercero                                 = this.limpiarTercero.bind(this);
        this.crearBotonesTablaItemTerceros                  = this.crearBotonesTablaItemTerceros.bind(this);
        this.crearBotonTabla3                               = this.crearBotonTabla3.bind(this);
        this.accionBotonTercero                             = this.accionBotonTercero.bind(this);
        this.eliminarItemTablaItemTercero                   = this.eliminarItemTablaItemTercero.bind(this);
        this.eliminarItemTablaTercero                       = this.eliminarItemTablaTercero.bind(this);
        this.finalizarReglas                                = this.finalizarReglas.bind(this);
        this.setButtonGraficarReglasTercero                 = this.setButtonGraficarReglasTercero.bind(this);
        this.setButtonAplicarReglasTercero                  = this.setButtonAplicarReglasTercero.bind(this);
        this.DatosVerMasTercero                             = this.DatosVerMasTercero.bind(this);
        this.successDatosVerMasTerceros                     = this.successDatosVerMasTerceros.bind(this);
        this.setButtonGraficarPLantillas                    = this.setButtonGraficarPLantillas.bind(this);
        this.DatosVerMasPlantillasDetalle                   = this.DatosVerMasPlantillasDetalle.bind(this);
        this.successDatosVerMasPlantillasDetalle            = this.successDatosVerMasPlantillasDetalle.bind(this);
        this.AplicarReglaArchivosTercero                    = this.AplicarReglaArchivosTercero.bind(this);
        this.successAplicarReglaTercero                     = this.successAplicarReglaTercero.bind(this);
        this.selectionRegistroAplicaTercero                 = this.selectionRegistroAplicaTercero.bind(this);
        this.selectionRegistroEstadoContabilidad            = this.selectionRegistroEstadoContabilidad.bind(this);
        this.contabilizarArchivo                            = this.contabilizarArchivo.bind(this);
        this.successContabilizacionArchivo                  = this.successContabilizacionArchivo.bind(this);
        this.rowVerMasPlantillas                            = this.rowVerMasPlantillas.bind(this);
    }

    initForm() {
        console.log('Formulario CustomIntegrarBancosMulticash,  @version: jdesk_1.01.0002, @author:Santiago Hernández N., @updateBy: Sebastian Rios Echeverri.');
        
        this.getField('btn_contabilizar').setDisabled(true);
        this.getField('btn_contabilizar').setClick(this.contabilizarArchivo);

        this.getField('campo1').setDisabled(true);
        this.getField('campo2').setDisabled(true);
        this.getField('campo3').setDisabled(true);
        this.getField('campo4').setDisabled(true);
        this.getField('campo5').setDisabled(true);
        this.getField('campo6').setDisabled(true);
        this.getField('campo7').setDisabled(true);
        this.getField('campo8').setDisabled(true);
        this.getField('campo9').setDisabled(true);
        this.getField('campo10').setDisabled(true);

        this.getField('campo11').setDisabled(true);
        this.getField('campo12').setDisabled(true);
        this.getField('campo13').setDisabled(true);
        this.getField('campo14').setDisabled(true);
        this.getField('campo15').setDisabled(true);
        this.getField('campo16').setDisabled(true);
        this.getField('campo17').setDisabled(true);
        this.getField('campo18').setDisabled(true);
        this.getField('campo19').setDisabled(true);
        this.getField('campo20').setDisabled(true);

        this.getField('campo21').setDisabled(true);
        this.getField('campo22').setDisabled(true);
        this.getField('campo23').setDisabled(true);
        this.getField('campo24').setDisabled(true);
        this.getField('campo25').setDisabled(true);
        this.getField('campo26').setDisabled(true);
        this.getField('campo27').setDisabled(true);
        this.getField('campo28').setDisabled(true);
        this.getField('campo29').setDisabled(true);
        this.getField('campo30').setDisabled(true);

        this.getField('campo31').setDisabled(true);
        this.getField('campo32').setDisabled(true);
        this.getField('campo33').setDisabled(true);
        this.getField('campo34').setDisabled(true);
        this.getField('campo35').setDisabled(true);
        this.getField('campo36').setDisabled(true);
        this.getField('campo37').setDisabled(true);
        this.getField('campo38').setDisabled(true);
        this.getField('campo39').setDisabled(true);
        this.getField('campo40').setDisabled(true);

        this.getField('campo41').setDisabled(true);
        this.getField('campo42').setDisabled(true);
        this.getField('campo43').setDisabled(true);
        this.getField('campo44').setDisabled(true);
        this.getField('campo45').setDisabled(true);
        this.getField('campo46').setDisabled(true);
        this.getField('campo47').setDisabled(true);
        this.getField('campo48').setDisabled(true);
        this.getField('campo49').setDisabled(true);
        this.getField('campo50').setDisabled(true);
        this.getField('btn_crear_regla').setDisabled(true);
        this.getField('btn_crear_regla').setVisible(false);
        this.getField('btn_cancelar_regla').setDisabled(true);
        this.getField('btn_cancelar_regla').setVisible(false);


        this.getField('fecha_desde').setOnChange(()=>{
            //this.limpiarCampos();
            this.getField('consultar').setDisabled(false);

            let fechaDesde = this.getField('fecha_desde').getValue();
            let validFechaDesde = this.validarFechaMenorActual(fechaDesde);
            if(!validFechaDesde){
                this.getField('fecha_desde').setError(true,'* La fecha debe ser menor o igual a la fecha actual');
                this.valid_fecha_desde =false;
                this.getField('consultar').setDisabled(true);
            } else {
                this.getField('fecha_desde').setError(false,'');
                this.valid_fecha_desde=true;
            }
    
            if(this.valid_fecha_desde){
                let fd = 'fd'; // este parametro se envia para identificar desde cual setonchage se llama la funcion validarRangoFechas(); fecha_desde o fecha_hasta
                this.validRangoFechas(fd);
            }
    
        });

        this.getField('fecha_hasta').setOnChange(()=>{
            this.getField('consultar').setDisabled(false);

            let validFechaHasta = this.validarFechaMenorActual(this.getField('fecha_hasta').getValue());

            if(!validFechaHasta){
                this.getField('fecha_hasta').setError(true,'* La fecha debe ser menor o igual a la fecha actual');
                this.valid_fecha_hasta=false;
                this.getField('consultar').setDisabled(true);

            } else {
                this.getField('fecha_hasta').setError(false,'');
                this.valid_fecha_hasta=true;
            }

            if(this.valid_fecha_hasta){
                let fh = 'fh'; // este parametro se envia para identificar desde cual setonchage se llama la funcion validarRangoFechas(); fecha_desde o fecha_hasta
                this.validRangoFechas(fh);
            }
            
        });
        
        this.cargarSelect();

        this.getField('bancos_id').setOnChange(()=>{this.cargarSelectBancosCuentas(); /*this.ocultarTbPrincipal();*/});
        this.getField('consultar').setClick(()=>{this.validarCampos()});


        this.getField("bancos_cuentas_id").setOnChange(()=>{
            this.getField("bancos_cuentas_id").getValue() === '' || this.getField("bancos_cuentas_id").getValue() === ' ' ? 
            this.getField("id_bancos_cuentas").setValue("") :
            this.getField("bancos_cuentas_id").valid();
        });

        this.getField("btn_crear_regla").setClick(this.validarModalFinal);
        this.getField("btn_cancelar").setClick(this.pantallaPrincipal);   
        this.getField("btn_cancelar_regla").setClick(this.pantallaInicio);                

        this.getField("btn_finalizar").setClick(this.grabarRegla);
        this.getField("btn_finalizar_reglas").setClick(this.finalizarReglas);

        this.llenarOperadorLogico();

        this.getField('tercero_id').setOnChange(this.abrirtabla);
        this.getField('plantillas').setOnChange(this.DatosVerMasPlantillas);
        
        this.getField('modal_finalizar').setCloseButton(()=>{
            this.getField('nit_defecto').setValue('');
            this.getField('sede_defecto').setValue('');
            this.getField('nombre_consulta').setValue('');
            this.getField('plantillas').setValue('');
            this.getField('tercero_id').setValue('');
            this.getField('operador_logico').setValue('');
            this.getField('camposparatomarvalor').setValue('');
           
            this.getField('nit_defecto').setError(false, "");
            this.getField('sede_defecto').setError(false, "");
            this.getField('nombre_consulta').setError(false, "");
            this.getField('plantillas').setError(false, "");
            this.getField('tercero_id').setError(false, "");
            this.getField('operador_logico').setError(false, "");
            this.getField('camposparatomarvalor').setError(false, "");
            
            this.getField('label').setVisible(false);
            this.getField('label8').setVisible(false);
            this.getField('plantillas').setVisible(false);
            this.getField('rejillapredefinidos').toggle(false);

        });

        this.getField('modal_multicash').setCloseButton(()=>{
            this.getField('nit_defecto').setValue('');
            this.getField('sede_defecto').setValue('');
            this.getField('nombre_consulta').setValue('');
            this.getField('plantillas').setValue('');
            this.getField('tercero_id').setValue('');
            this.getField('operador_logico').setValue('');
            this.getField('camposparatomarvalor').setValue('');
            
            this.getField('nit_defecto').setError(false, "");
            this.getField('sede_defecto').setError(false, "");
            this.getField('nombre_consulta').setError(false, "");
            this.getField('plantillas').setError(false, "");
            this.getField('tercero_id').setError(false, "");
            this.getField('operador_logico').setError(false, "");
            this.getField('camposparatomarvalor').setError(false, "");
            
            this.getField('label').setVisible(false);
            this.getField('label8').setVisible(false);
            this.getField('plantillas').setVisible(false);
            this.getField('rejillapredefinidos').toggle(false);


            this.regla                                      = {};
            this.camposArrayMulticash                       = [];
            this.campo                                      = 0;
            this.nuevoArray                                 = [];
            this.arrayTemporal                              = [];
            
            
            this.getField('btn_crear_regla').setDisabled(true);
            this.getField('btn_crear_regla').setVisible(false);
            this.getField('btn_cancelar_regla').setDisabled(true);
            this.getField('btn_cancelar_regla').setVisible(false);
        });

        this.getField("campos").setOnChange(this.traerValores);     
        this.getField("campos_valor").setOnChange(this.limpiarTercero);        
        this.getField('caracteres').setValue(0);
        this.getField('caracteres').setOnBlur(this.quitarCaracteresFinal);
        this.getField('caracteres2').setValue(0);
        this.getField('caracteres2').setOnBlur(this.quitarCaracteresInicio);

        this.getField('modal_config_tercero').setCloseButton(()=>{
            this.getField('campos').setValue('');
            this.getField('campos_valor').setValue('');
            this.getField('caracteres2').setValue(0);
            this.getField('caracteres').setValue(0);
            this.getField('resultado').setValue('');
            
            this.getField('campos').setError(false, "");
            this.getField('campos_valor').setError(false, "");
            this.getField('caracteres2').setError(false, "");
            this.getField('caracteres').setError(false, "");
            this.getField('resultado').setError(false, "");

            this.reglaTercero = {};
            this.nuevoArrayTercero = [];
            this.arrayTemporalTercero = [];
        });

        this.getField('btn_regla_tercero').setClick(this.mostrarReglasTerceros);

        
    }

    limpiarTercero(){

        this.getField('caracteres2').setValue(0);
        this.getField('caracteres').setValue(0);
       

        this.getField('caracteres2').setError(false, "");
        this.getField('caracteres').setError(false, "");

    }

    
    cargarSelect(){
        
        let datos={ datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'maes-bancos', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelectBancos,
                error: this.error_,
                general: this.generalFormatPmv,
        });

        let datos2={ datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: '1_operadores', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos2,
                success: this.successCargarSelectOperadores,
                error: this.error_,
                general: this.generalFormatPmv,
        });

    }

    successCargarSelectBancos(data){
        if(data.estado_p === 200){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo;
            dataOp['text'] = `${item.codigo}-${item.nombre}`;
            dataOp['campos_cambian'] = { id_bancos: item.bancos_id};
            opciones.push(dataOp); })
            this.getField('bancos_id').setOptions(opciones);

        }
    }

    successCargarSelectOperadores(data){
        if(data.estado_p === 200){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = `${item.descripcion}`;
                dataOp['campos_cambian'] = { };
                opciones.push(dataOp); 
            });
            for(let i=1; i<51;i++){
                this.getField('operadores'+i).setOptions(opciones);
            }

        }
    }

    
    cargarSelectBancosCuentas(){
        this.getField('bancos_cuentas_id').setOptions([{'text':'Ninguno','value':' ','campos_cambian':{}}]);
        this.getField("id_bancos_cuentas").setValue("");
        if (this.getField('bancos_id').getValue() !== ''){
            let datos = {datos: {value:this.getField('bancos_id').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-bancoscuentas', operacion: '2_1', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelectBancosCuentas,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }

    }

    successCargarSelectBancosCuentas(data){

        if(data.estado_p === 200){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.numero_cuenta;
            dataOp['text'] = `${item.numero_cuenta_original}-${item.nombre_banco_oficina}`;
            dataOp['campos_cambian'] = { id_bancos_cuentas: item.bancos_cuentas_id};
            opciones.push(dataOp); })
            this.getField('bancos_cuentas_id').setOptions(opciones);
            this.getField('bancos_cuentas_id').setValue("");
        }else {
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.numero_cuenta;
            dataOp['text'] = `${item.numero_cuenta_original}-${item.nunombre_banco_oficinaero_cuenta}`;
            dataOp['campos_cambian'] = { id_bancos_cuentas: item.bancos_cuentas_id};
            opciones.push(dataOp); })
            this.getField('bancos_cuentas_id').setOptions(opciones); 


            this.getField('confirmModalCustom').setTitleAndContent('Banco cuenta', 'El banco '+this.getField('bancos_id').getValue()+' no tiene configurado ninguna cuenta.');
            this.getField('confirmModalCustom').setClickDialog(() => {

                this.getField('confirmModalCustom').toggle(false);
                this.validarCampos();
                

            });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);


        }
    }

    
    validarCampos(){
        if (this.getField('bancos_id').valid()){

            let datos = {datos:{
                bancos_id:this.getField('bancos_id').getValue(),
                bancos_cuentas_id:this.getField('bancos_cuentas_id').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
            }};            
            this.getField("rejilla_multicash").setTipoServicioOperacion("maes-integrarbancosmulticash-1_multicash");
            this.getField("rejilla_multicash").sendRequest(this.successMostrarTabla, datos);
            

        }
    }

    successMostrarTabla(data){

        if(data.estado_p === 200){  
            this.gridOptionsComponentesMulticash['rowData'] = [];   


            let configCell = new Map();
            configCell.set('ver_linea', { cellRenderer: this.setButtonGraficarLinea, headerName: 'ver_linea'/* ,width:360 */, sortable: false, filter: false });
            configCell.set('numero_integracion', { cellRenderer: this.selectionRegistroAplica, field: 'numero_integracion' });
            configCell.set('linea_del_archivo', { cellRenderer: this.selectionRegistroAplica2, field: 'linea_del_archivo' });
            configCell.set('aplica_regla', { cellRenderer: this.selectionRegistroAplica3, field: 'aplica_regla' });
            configCell.set('aplica_regla_tercero', { cellRenderer: this.selectionRegistroAplicaTercero, field: 'aplica_regla_tercero' });
            configCell.set('estado_contabilizacion', { cellRenderer: this.selectionRegistroEstadoContabilidad, field: 'estado_contabilizacion' });
            configCell.set('nombre_archivo', { cellRenderer: this.selectionRegistroAplica4, field: 'nombre_archivo' });
            configCell.set('bancos', { cellRenderer: this.selectionRegistroAplica5, field: 'bancos' });
            configCell.set('bancos_cuentas', { cellRenderer: this.selectionRegistroAplica6, field: 'bancos_cuentas' });
            configCell.set('fecha_sistema', { cellRenderer: this.selectionRegistroAplica7, field: 'fecha_sistema' });
            configCell.set('campo1', { cellRenderer: this.selectionRegistroAplica8, field: 'campo1' });
            configCell.set('campo2', { cellRenderer: this.selectionRegistroAplica9, field: 'campo2' });
            configCell.set('campo3', { cellRenderer: this.selectionRegistroAplica10, field: 'campo3' });
            configCell.set('campo4', { cellRenderer: this.selectionRegistroAplica11, field: 'campo4' });
            configCell.set('campo5', { cellRenderer: this.selectionRegistroAplica12, field: 'campo5' });
            configCell.set('campo6', { cellRenderer: this.selectionRegistroAplica13, field: 'campo6' });
            configCell.set('campo7', { cellRenderer: this.selectionRegistroAplica14, field: 'campo7' });
            configCell.set('campo8', { cellRenderer: this.selectionRegistroAplica15, field: 'campo8' });
            configCell.set('campo9', { cellRenderer: this.selectionRegistroAplica16, field: 'campo9' });
            configCell.set('campo10', { cellRenderer: this.selectionRegistroAplica17, field: 'campo10' });
            configCell.set('campo11', { cellRenderer: this.selectionRegistroAplica18, field: 'campo11' });
            configCell.set('campo12', { cellRenderer: this.selectionRegistroAplica19, field: 'campo12' });
            configCell.set('campo13', { cellRenderer: this.selectionRegistroAplica20, field: 'campo13' });
            configCell.set('campo14', { cellRenderer: this.selectionRegistroAplica21, field: 'campo14' });
            configCell.set('campo15', { cellRenderer: this.selectionRegistroAplica22, field: 'campo15' });
            configCell.set('campo16', { cellRenderer: this.selectionRegistroAplica23, field: 'campo16' });
            configCell.set('campo17', { cellRenderer: this.selectionRegistroAplica24, field: 'campo17' });
            configCell.set('campo18', { cellRenderer: this.selectionRegistroAplica25, field: 'campo18' });
            configCell.set('campo19', { cellRenderer: this.selectionRegistroAplica26, field: 'campo19' });
            configCell.set('campo20', { cellRenderer: this.selectionRegistroAplica27, field: 'campo20' });
            configCell.set('campo21', { cellRenderer: this.selectionRegistroAplica28, field: 'campo21' });
            configCell.set('campo22', { cellRenderer: this.selectionRegistroAplica29, field: 'campo22' });
            configCell.set('campo23', { cellRenderer: this.selectionRegistroAplica30, field: 'campo23' });
            configCell.set('campo24', { cellRenderer: this.selectionRegistroAplica31, field: 'campo24' });
            configCell.set('campo25', { cellRenderer: this.selectionRegistroAplica32, field: 'campo25' });
            configCell.set('campo26', { cellRenderer: this.selectionRegistroAplica33, field: 'campo26' });  
            configCell.set('campo27', { cellRenderer: this.selectionRegistroAplica34, field: 'campo27' });  
            configCell.set('campo28', { cellRenderer: this.selectionRegistroAplica35, field: 'campo28' });  
            configCell.set('campo29', { cellRenderer: this.selectionRegistroAplica36, field: 'campo29' });  
            configCell.set('campo30', { cellRenderer: this.selectionRegistroAplica37, field: 'campo30' });  
            configCell.set('campo31', { cellRenderer: this.selectionRegistroAplica38, field: 'campo31' });  
            configCell.set('campo32', { cellRenderer: this.selectionRegistroAplica39, field: 'campo32' });  
            configCell.set('campo33', { cellRenderer: this.selectionRegistroAplica40, field: 'campo33' });  
            configCell.set('campo34', { cellRenderer: this.selectionRegistroAplica41, field: 'campo34' });  
            configCell.set('campo35', { cellRenderer: this.selectionRegistroAplica42, field: 'campo35' });  
            configCell.set('campo36', { cellRenderer: this.selectionRegistroAplica43, field: 'campo36' });  
            configCell.set('campo37', { cellRenderer: this.selectionRegistroAplica44, field: 'campo37' });  
            configCell.set('campo38', { cellRenderer: this.selectionRegistroAplica45, field: 'campo38' });   
            configCell.set('campo39', { cellRenderer: this.selectionRegistroAplica46, field: 'campo39' });   
            configCell.set('campo40', { cellRenderer: this.selectionRegistroAplica47, field: 'campo40' });   
            configCell.set('campo41', { cellRenderer: this.selectionRegistroAplica48, field: 'campo41' });   
            configCell.set('campo42', { cellRenderer: this.selectionRegistroAplica49, field: 'campo42' });   
            configCell.set('campo43', { cellRenderer: this.selectionRegistroAplica49, field: 'campo43' });   
            configCell.set('campo44', { cellRenderer: this.selectionRegistroAplica50, field: 'campo44' });   
            configCell.set('campo45', { cellRenderer: this.selectionRegistroAplica51, field: 'campo45' });   
            configCell.set('campo46', { cellRenderer: this.selectionRegistroAplica52, field: 'campo46' });   
            configCell.set('campo47', { cellRenderer: this.selectionRegistroAplica53, field: 'campo47' });   
            configCell.set('campo48', { cellRenderer: this.selectionRegistroAplica54, field: 'campo48' });   
            configCell.set('campo49', { cellRenderer: this.selectionRegistroAplica55, field: 'campo49' });   
            configCell.set('campo50', { cellRenderer: this.selectionRegistroAplica56, field: 'campo50' });
            
            this.gridOptionsComponentesMulticash['rowData'] = data.data;
            this.getField('rejilla_multicash').initData(this.gridOptionsComponentesMulticash,configCell);
            /*const allColumnIds = [];
            this.gridOptionsComponentesMulticash.columnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            this.gridOptionsComponentesMulticash.columnApi.autoSizeColumns(allColumnIds, false);*/

            this.mostrarConfigReglas();
        }else{
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.gridOptionsComponentesMulticash['rowData'] = [];  
        }


    }

    selectionRegistroAplica(props){
        let numero_integracion = props.data.numero_integracion;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = numero_integracion;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = numero_integracion;
            return this.createElementJaivana(div);
        }
    }
    selectionRegistroAplica2(props){
        let linea_del_archivo = props.data.linea_del_archivo;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = linea_del_archivo;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = linea_del_archivo;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica3(props){
        let aplica_regla = props.data.aplica_regla;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = aplica_regla;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = aplica_regla;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica4(props){
        let nombre_archivo = props.data.nombre_archivo;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = nombre_archivo;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = nombre_archivo;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica5(props){
        let bancos = props.data.bancos;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = bancos;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = bancos;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica6(props){
        let bancos_cuentas = props.data.bancos_cuentas;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = bancos_cuentas;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = bancos_cuentas;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica7(props){
        let fecha_sistema = props.data.fecha_sistema;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = fecha_sistema;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = fecha_sistema;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica8(props){
        let campo1 = props.data.campo1;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo1;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo1;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica9(props){
        let campo2 = props.data.campo2;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo2;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo2;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica10(props){
        let campo3 = props.data.campo3;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo3;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo3;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica11(props){
        let campo4 = props.data.campo4;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo4;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo4;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica12(props){
        let campo5 = props.data.campo5;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo5;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo5;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica13(props){
        let campo6 = props.data.campo6;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo6;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo6;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica14(props){
        let campo7 = props.data.campo7;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo7;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo7;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica15(props){
        let campo8 = props.data.campo8;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo8;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo8;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica16(props){
        let campo9 = props.data.campo9;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo9;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo9;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica17(props){
        let campo10 = props.data.campo10;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo10;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo10;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica18(props){
        let campo11 = props.data.campo11;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo11;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo11;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica19(props){
        let campo12 = props.data.campo12;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo12;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo12;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica20(props){
        let campo13 = props.data.campo13;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo13;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo13;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica21(props){
        let campo14 = props.data.campo14;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo14;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo14;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica22(props){
        let campo15 = props.data.campo15;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo15;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo15;
            return this.createElementJaivana(div);
        }
    }

    
    selectionRegistroAplica23(props){
        let campo16 = props.data.campo16;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo16;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo16;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica24(props){
        let campo17 = props.data.campo17;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo17;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo17;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica25(props){
        let campo18 = props.data.campo18;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo18;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo18;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica26(props){
        let campo19 = props.data.campo19;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo19;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo19;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica27(props){
        let campo20 = props.data.campo20;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo20;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo20;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica28(props){
        let campo21 = props.data.campo21;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo21;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo21;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica29(props){
        let campo22 = props.data.campo22;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo22;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo22;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica30(props){
        let campo23 = props.data.campo23;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo23;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo23;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica31(props){
        let campo24 = props.data.campo24;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo24;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo24;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica32(props){
        let campo25 = props.data.campo25;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo25;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo25;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica33(props){
        let campo26 = props.data.campo26;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo26;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo26;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica34(props){
        let campo27 = props.data.campo27;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo27;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo27;
            return this.createElementJaivana(div);
        }
    }


    selectionRegistroAplica35(props){
        let campo28 = props.data.campo28;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo28;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo28;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica36(props){
        let campo29 = props.data.campo29;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo29;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo29;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica37(props){
        let campo30 = props.data.campo30;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo30;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo30;
            return this.createElementJaivana(div);
        }
    }
    
    selectionRegistroAplica38(props){
        let campo31 = props.data.campo31;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo31;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo31;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica39(props){
        let campo32 = props.data.campo32;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo32;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo32;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica40(props){
        let campo33 = props.data.campo33;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo33;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo33;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica41(props){
        let campo34 = props.data.campo34;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo34;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo34;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica42(props){
        let campo35 = props.data.campo35;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo35;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo35;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica43(props){
        let campo36 = props.data.campo36;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo36;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo36;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica44(props){
        let campo37 = props.data.campo37;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo37;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo37;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica45(props){
        let campo38 = props.data.campo38;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo38;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo38;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica46(props){
        let campo39 = props.data.campo39;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo39;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo39;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica47(props){
        let campo40 = props.data.campo40;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo40;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo40;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica48(props){
        let campo41 = props.data.campo41;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo41;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo41;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica49(props){
        let campo42 = props.data.campo42;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo42;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo42;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica50(props){
        let campo43 = props.data.campo43;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo43;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo43;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica51(props){
        let campo44 = props.data.campo44;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo44;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo44;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica52(props){
        let campo45 = props.data.campo45;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo45;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo45;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica53(props){
        let campo46 = props.data.campo46;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo46;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo46;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica54(props){
        let campo47 = props.data.campo47;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo47;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo47;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica55(props){
        let campo48 = props.data.campo48;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle7");
            div.textContent = campo48;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo48;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroAplica56(props){
        let campo49 = props.data.campo49;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');            
            div.setAttribute("class","divStyle7");
            div.textContent = campo49;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo49;
            return this.createElementJaivana(div);
        }
    }

    /*selectionRegistroAplica57(props){
        let campo50 = props.data.campo50;
        if (props.data.aplica_regla === 'S') {
            let div = document.createElement('div');
            
            div.setAttribute("class","divStyle7");
            div.textContent = campo50;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = campo50;
            return this.createElementJaivana(div);
        }
    }*/


    setButtonGraficarLinea(props){
        let fragment = document.createDocumentFragment();
        let buttonVerLinea = this.crearBotonTabla(props.data, props.data.linea_del_archivo);
        buttonVerLinea.setAttribute("class","buttonStyle2");    
        fragment.appendChild(buttonVerLinea);
        return this.createElementJaivana(fragment);
    }

    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Linea':
                button.setAttribute("id", `buttonLinea_${data.linea_del_archivo}`);
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionAbrirModal(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }


    accionAbrirModal(data,boton){

        /**
         * Esta constante de tipo función flecha se crea con el fin de reducir código ya que son demasiados campos que hay que validar
         * en la modal "BANCOS MULTICASH ARCHIVOS"
         * @param {Viene siendo el nombre_campo_servicio del campo} sObjetos 
         * @param {Viene siendo la propiedad "setDisabled" para habilitar o deshabilitar los campos} sPropiedad 
         * @param {Viene siendo la propiedad "setVisible" para VER o NO los campos} sPropiedad 
         * @param {Viene siendo un valor booleano} sValor 
         */
        const establecerPropiedades = (sObjetos, sPropiedad, sValor)=>{
            const array = sObjetos.split(',');
            array.forEach((element) => {
                if (sPropiedad === 'disable') {
                    (sValor === 'true') ? this.getField(element).setDisabled(true) : this.getField(element).setDisabled(false);
                    (sValor === 'true') ? this.getField(element).setVisible(false) : this.getField(element).setVisible(true);
                }
            });
        }
        
        let datosFicha = [];
        let data2 = {                  
            "archivo": data.nombre_archivo,
            "numero_integracion": data.numero_integracion,
            "numero_linea": data.linea_del_archivo             
        };

        datosFicha.push(data2);
        this.getField('lista_datos').setItemsFichas(datosFicha);    
        this.banco_cuenta=data.bancos_cuentas;  

        for (const clave in data) {
            if (Object.hasOwnProperty.call(data, clave)) {
                const element = data[clave];
                if (clave.includes('campo')) {
                    let claveEntrada = clave.replace('campo', '');
                    if (element !== "" && element !== null && element !== undefined) {
                        this.getField(clave).setValue(element);
                        establecerPropiedades(`${clave},operadores${claveEntrada},text${claveEntrada}_1,text${claveEntrada}_2,estadoactivo_id${claveEntrada},agregar_regla_${claveEntrada}`,'disable','false');
                        this.getField(`agregar_regla_${claveEntrada}`).setClick(()=>{this.abrirModalReglas(claveEntrada)});
                        
                    }else{
                        establecerPropiedades(`${clave},operadores${claveEntrada},text${claveEntrada}_1,text${claveEntrada}_2,estadoactivo_id${claveEntrada},agregar_regla_${claveEntrada}`,'disable','true');
                    }
                }
            }
        }
        this.getField('modal_multicash').handleClickOpen();
    }

    validarFechaMenorActual(date){
        var x = new Date(date);//.toGMTString()
        //var x = new Date(y);
        var today = new Date();
        if (x <= today)
            return true;
        else
            return false;
    }

    
    validRangoFechas(campo_fecha){
        let fecha_desde = new Date(this.getField('fecha_desde').getValue());
        let  fd = new Date(fecha_desde.getFullYear()+ "/" + (fecha_desde.getMonth() +1) + "/" + fecha_desde.getDate());
        let fecha_hasta = new Date(this.getField('fecha_hasta').getValue());
        let  fh = new Date(fecha_hasta.getFullYear() + "/" + (fecha_hasta.getMonth() +1) + "/" + fecha_hasta.getDate() );
        
        let rango = Math.round(((fh.getTime() - fd.getTime()) / (1000*60*60*24)));

        if(campo_fecha === 'fd'){
            if(rango < -1){
                this.valid_fecha_desde = false;
                this.getField('fecha_desde').setError(true,'* La fecha desde debe ser menor o igual a la fecha hasta');
                this.getField('consultar').setDisabled(true);
            } else if(rango >= 59){
                this.valid_fecha_desde = false;
                this.getField('fecha_desde').setError(true,'* El rango de fecha debe ser máximo de 60 días');
                this.getField('consultar').setDisabled(true);
            } else{
                
                let validFechaHasta = this.validarFechaMenorActual(this.getField('fecha_hasta').getValue());//
        
                if(!validFechaHasta){
                    this.getField('fecha_hasta').setError(true,'* La fecha debe ser menor o igual a la fecha actual');
                    this.valid_fecha_hasta=false;
        
                } else {
                    this.getField('fecha_hasta').setError(false,'');
                    this.valid_fecha_hasta=true;
                }
                //this.getField('fecha_hasta').setError(false,'');
                this.getField('fecha_desde').setError(false,'');
                this.valid_fecha_desde = true;
                //this.valid_fecha_hasta = true;

            }
        }else if(campo_fecha === 'fh'){
            if(rango < 1){
                this.getField('fecha_hasta').setError(true,'* La fecha hasta debe ser mayor o igual a la fecha desde');
                this.valid_fecha_hasta = false;
                this.getField('consultar').setDisabled(true);
            }else if(rango >= 61){
                this.getField('fecha_hasta').setError(true,'* El rango de fechas debe ser máximo de 60 días');
                this.valid_fecha_hasta = false;
                this.getField('consultar').setDisabled(true);
            }else{
                this.valid_fecha_hasta = true;
                this.valid_fecha_desde = true;

                this.getField('fecha_hasta').setError(false,'');
                this.getField('fecha_desde').setError(false,'');

            }
        }
    }


    abrirModalReglas(campo){
        if (this.validarCamposModalMulticash(campo)) {

            this.mostrarReglas();
        }
        
    }

    validarCamposModalMulticash(campo){
        let errores = 0;
        this.camposArrayMulticash = [];
            if (this.getField(`campo${campo}`).getValue() !== '' && this.getField(`campo${campo}`).getValue() !== ' ' &&
            this.getField(`operadores${campo}`).getValue() !== '' && this.getField(`operadores${campo}`).getValue() !== '') {
                if (this.getField(`estadoactivo_id${campo}`).getValue() !== '' && this.getField(`estadoactivo_id${campo}`).getValue() !== ' ') {
                    if ((this.getField(`text${campo}_1`).getValue() !== '' && this.getField(`text${campo}_1`).getValue() !== ' ') || (this.getField(`text${campo}_2`).getValue() !== '' && this.getField(`text${campo}_2`).getValue() !== ' ')) {
                        this.getField(`campo${campo}`).setError(false, '');
                        this.getField(`operadores${campo}`).setError(false, '');
                        this.getField(`text${campo}_1`).setError(false, '');
                        this.getField(`text${campo}_2`).setError(false, '');
                        this.getField(`estadoactivo_id${campo}`).setError(false, '');

                        this.regla = {};
                        this.regla[`campo${campo}`] = this.getField(`campo${campo}`).getValue();
                        this.regla[`bancos_multicash_archivos_original_operadores_id`] = Number(this.getField(`operadores${campo}`).getValue());
                        this.regla[`valor1`] = this.getField(`text${campo}_1`).getValue() !== '' && this.getField(`text${campo}_1`).getValue() !== '' ?
                        this.getField(`text${campo}_1`).getValue() : '.';
                        this.regla[`valor2`] = this.getField(`text${campo}_2`).getValue() !== '' && this.getField(`text${campo}_2`).getValue() !== '' ?
                        this.getField(`text${campo}_2`).getValue() : '.';                        
                        this.regla[`campo`] = Number(campo);
                        this.campo = campo;
                        this.regla[`estadoactivo_id`] = Number(this.getField(`estadoactivo_id${campo}`).getValue());                        
                        this.regla[`operador`] = this.getField(`operadores${campo}`).getValue(); 
                        this.camposArrayMulticash.push(this.regla);
                    }else{
                        errores++;
                        this.alertGeneral.toggle(true, `Alguna de las dos entradas para campo ${campo}, debe estar diligenciada.`, 'error');
                    }
                }else{
                    this.getField(`estadoactivo_id${campo}`).setError(true, '* Este campo es requerido.');
                    errores++;
                    this.alertGeneral.toggle(true, 'Valide que todos los campos estén correctamente diligenciados.', 'error');
                }
            
        }
    
        if (errores > 0) {
            return false;
        }else{

            if (this.camposArrayMulticash.length >= 1) {
                return true;
            }else{
                this.alertGeneral.toggle(true, 'Debe configurar al menos un campo.', 'error');
                return false;
            }
        }
    }


    mostrarReglas(){
        this.arrayTemporal.push(this.regla); //el ítem agregado se carga primero en un array temporal
        let campoArray1 = this.campo; //el campo se guarda en un array
        let campoArray2 = []; // se crea un array vacio
        this.nuevoArray.forEach((item) => {
            //se recorre el array principal, y se busca si el campo en el array1 ya se encuentra en el array principal
            if (Number(item.campo) === Number(campoArray1)) {
                campoArray2.push(item); // si es asi, se carga ese campo en el array2
            }
        });

        if (campoArray2.length === 0) {
            this.nuevoArray.push(this.regla);
            this.loadTablaItems(this.nuevoArray);
            this.regla = {};
        } else {
            this.regla = {};
            //si el array2 es diferente de 0, quiere decir que el campo ya fue agregado y se muestra el mensaje
            this.alertGeneral.toggle(true, "El campo ya fue agregado", "error");
        }
    }

    loadTablaItems(data) {
        let configCell = new Map();
        this.gridOptionsTabla["rowData"] = data; //y se recarga la tabla
        configCell.set('accion', { cellRenderer: this.crearBotonesTablaItem, sortable: false, filter: false, headerName: "Acción"});               
        this.gridOptionsTabla["onGridReady"] = (event) => event.api.sizeColumnsToFit();
        this.getField("rejilla_reglas").initData(this.gridOptionsTabla,configCell);


        this.getField('nit_defecto').setError(false, "");
        this.getField('sede_defecto').setError(false, "");
        this.getField('nombre_consulta').setError(false, "");
        this.getField('btn_crear_regla').setDisabled(false);
        this.getField('btn_crear_regla').setVisible(true);
        this.getField('btn_cancelar_regla').setDisabled(false);
        this.getField('btn_cancelar_regla').setVisible(true);
    }

    crearBotonesTablaItem(props) {
        let fragment = document.createDocumentFragment();
        let btnEliminarTablaItem = this.crearBotonTabla2(props.data, 'Eliminar Tabla Item');
        btnEliminarTablaItem.setAttribute("class","buttonStyle2");
        fragment.appendChild(btnEliminarTablaItem);
        return this.createElementJaivana(fragment);
    }

    crearBotonTabla2(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar Tabla Item':
                button.setAttribute("id", `boton_eliminar_tbItem_${data.campo}`);
                break;
            default:
                break;
        }

        button.onclick = () => { this.accionBoton(data, boton) };
        if (boton === 'Eliminar Tabla Item') {
            button.setAttribute("value", 'Eliminar');
        }else {
            button.setAttribute("value", boton);
        }
        button.setAttribute("type", "button");
        return button;
    }


    accionBoton(data, boton) {

        if (boton === 'Eliminar Tabla Item') {
            this.eliminarItemTablaItem(data, 'Eliminar');
        }
    }

    eliminarItemTablaItem(data, boton) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => { this.eliminarItemTabla(data) });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarItemTabla(data) {
        this.getField('confirmModalCustom').toggle(false);
        let indexItem = this.nuevoArray.indexOf(data);
        this.nuevoArray.splice(indexItem, 1);
        if (this.nuevoArray.length >= 1) {
            this.loadTablaItems(this.nuevoArray);

            this.getField('btn_crear_regla').setDisabled(false);
            this.getField('btn_crear_regla').setVisible(true);
            this.getField('btn_cancelar_regla').setDisabled(false);
            this.getField('btn_cancelar_regla').setVisible(true);
        } else {
            this.getField('rejilla_reglas').toggle(false);
            this.getField('btn_crear_regla').setDisabled(true);
            this.getField('btn_crear_regla').setVisible(false);
            this.getField('btn_cancelar_regla').setDisabled(false);
            this.getField('btn_cancelar_regla').setVisible(true);
        }
    }


    pantallaPrincipal(){
        
        this.getField("modal_finalizar").handleClose();
        this.getField('nit_defecto').setValue('');
        this.getField('sede_defecto').setValue('');
        this.getField('nombre_consulta').setValue('');
        this.getField('plantillas').setValue('');
        this.getField('tercero_id').setValue('');
        this.getField('operador_logico').setValue('');
        this.getField('camposparatomarvalor').setValue('');
        this.getField('nit_defecto').setError(false, "");
        this.getField('sede_defecto').setError(false, "");
        this.getField('nombre_consulta').setError(false, "");
        this.getField('plantillas').setError(false, "");
        this.getField('tercero_id').setError(false, "");
        this.getField('operador_logico').setError(false, "");
        this.getField('camposparatomarvalor').setError(false, "");
        this.getField('label').setVisible(false);
        this.getField('label8').setVisible(false);
        this.getField('plantillas').setVisible(false);
        this.getField('rejillapredefinidos').toggle(false);
    }



    validarModalFinal(){

        if (this.nuevoArray.length>1){
            this.getField('operador_logico').setDisabled(false);
        }else{
            this.getField('operador_logico').setDisabled(true);
        }

        let datos={ datos: {
                bancos_id:this.getField("id_bancos").getValue()+""
        }};
            
        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: 'traerbanco', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerBanco,
            error: this.error_,
            general: this.generalFormatPmv,
        });
    }


    successTraerBanco(data){
        let datosFicha2 = [];
        let data3 = {                  
            "banco": data.data[0].nombre,
            "banco_cuenta":this.banco_cuenta            
        };

        datosFicha2.push(data3);
        this.getField('lista_datos3').setItemsFichas(datosFicha2);
        this.getField('lista_datos4').setItemsFichas(datosFicha2);
        this.getField("modal_finalizar").handleClickOpen();
    }


    grabarRegla(){
        if(this.getField("nombre_consulta").valid()  && this.getField("detalle").valid() && this.getField("camposparatomarvalor").valid() && 
        this.plantillas_electronicas_gastos_id !== 0 && this.plantillas_electronicas_gastos_id !== '') {
            if (this.nuevoArray.length>1){
                if (this.getField("operador_logico").valid()){
                    this.getField('confirmModalCustom').setTitleAndContent('TERCERO', `¿Desea calcular el tercero?`);
                    this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
                    this.getField("confirmModalCustom").setClickDialog(()=>{

                        this.getField('confirmModalCustom').toggle(false);
                        this.configurarTercero();
                    
                    });
                    
                    this.getField('confirmModalCustom').setClickCancelDialog(() => {
        
                        this.getField('confirmModalCustom').toggle(false);    
                        this.finalizarReglas();                
                    
                    });
        
                    this.getField('confirmModalCustom').toggle(true);  
                }
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('TERCERO', `¿Desea calcular el tercero?`);
                this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setClickDialog(()=>{
                        
                   
                    this.getField('confirmModalCustom').toggle(false);
                    this.configurarTercero();
                
                });
                
                this.getField('confirmModalCustom').setClickCancelDialog(() => {
    
                    
                    this.getField('confirmModalCustom').toggle(false);                    
                    this.finalizarReglas();

                });
    
                this.getField('confirmModalCustom').toggle(true);  
            }
        }else if(this.plantillas_electronicas_gastos_id === '' || this.plantillas_electronicas_gastos_id === 0){
            this.alertGeneral.toggle(true, 'Por favor seleccione un registro de la tabla.', 'error');
        }else{
            this.alertGeneral.toggle(true, 'Valide que todos los campos, estén correctamente diligenciados.', 'error');
        }
    }

    successCrearRegla(data){
        if(data.estado_p === 200){
            this.regla = {};
            this.camposArrayMulticash = [];
            this.campo = 0;
            this.nuevoArray = [];
            this.arrayTemporal = [];
            this.getField("modal_finalizar").handleClose();
            //this.getField("modal_reglas").handleClose();
            this.getField('modal_multicash').handleClose();
            this.getField('btn_crear_regla').setDisabled(true);
            this.getField('btn_crear_regla').setVisible(false);
            this.getField('btn_cancelar_regla').setDisabled(true);
            this.getField('btn_cancelar_regla').setVisible(false);

            
            this.getField("modal_config_tercero").handleClose();
            this.getField('caracteres2').setValue(0);
            this.getField('caracteres').setValue(0);
            this.getField('resultado').setValue('');        
            this.getField('caracteres2').setError(false, "");
            this.getField('caracteres').setError(false, "");
            this.getField('resultado').setError(false, "");
            
            this.mostrarConfigReglas();
        }else{
            this.getField("modal_finalizar").handleClose();
            this.alertGeneral.toggle(true, 'Verifique sus datos.', 'error');
        }
    }

    mostrarConfigReglas(){
        let datos = {
            datos:{
                bancos_id:this.getField("id_bancos").getValue()+"",
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: 'traerconfigreglas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successConfigReglas,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false,
        });
    }

    successConfigReglas(data){
        if(data.estado_p === 200){
            this.gridOptionsTablaConfigReglas["rowData"] = data.data; //y se recarga la tabla
            let configCell = new Map();
            configCell.set('ver_regla', { cellRenderer: this.setButtonGraficarReglas, field: 'ver_regla' });
            configCell.set('aplicar_regla', { cellRenderer: this.setButtonAplicarReglas, field: 'aplicar_regla' });     
            configCell.set('ver_regla_tercero', { cellRenderer: this.setButtonGraficarReglasTercero, field: 'ver_regla_tercero' });
            configCell.set('aplicar_regla_tercero', { cellRenderer: this.setButtonAplicarReglasTercero, field: 'aplicar_regla_tercero' });                        
            configCell.set('ver_plantilla', { cellRenderer: this.setButtonGraficarPLantillas, field: 'ver_plantilla' });
            this.getField("rejilla_config_reglas").initData(this.gridOptionsTablaConfigReglas, configCell);
            this.getField('btn_contabilizar').setDisabled(false);
        }else{
            this.alertGeneral.toggle(true, 'No tiene reglas configuiradas.', 'error');
            this.getField("rejilla_config_reglas").toggle(false);
            this.getField('btn_contabilizar').setDisabled(true);
        }
    }

    setButtonAplicarReglasTercero(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.AplicarReglaArchivosTercero(id);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Aplicar");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);
    }

    AplicarReglaArchivosTercero(id){
        let datos={datos: {bancos_multicash_archivos_original_configuracion_id: id}};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: 'aplicarreglatercero', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successAplicarReglaTercero,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false,
        });
    }

    successAplicarReglaTercero(data){

        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se aplico la regla correctamente.', 'success');
            this.validarCampos();
        }else{
            this.alertGeneral.toggle(true, 'Error al aplicar la regla.', 'error');
        }

    }

    setButtonAplicarReglas(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.AplicarReglaArchivos(id);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Aplicar");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);
    }

    AplicarReglaArchivos(id){
        let datos={datos: {bancos_multicash_archivos_original_configuracion_id: id}};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: 'aplicarregla', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successAplicarRegla,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false,
            });
    }
    
    successAplicarRegla(data){
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se aplico la regla correctamente.', 'success');
            this.validarCampos();
        }else{
            this.alertGeneral.toggle(true, 'Error al aplicar la regla.', 'error');
        }
    }

    setButtonGraficarReglas(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.DatosVerMas(id);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Ver regla");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);
    }

    DatosVerMas(id){
        let datos={datos: {bancos_multicash_archivos_original_configuracion_id: id}};
        this.generalFormatPmv = { tipo_servicio:  'maes-integrarbancosmulticash', operacion: 'traerreglasxconfiguracion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosVerMas,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false,
        });
    }

    successDatosVerMas(data){
        if(data.estado_p === 200){
            this.getField('modal_detalle').handleClickOpen();
            this.gridOptionsTablaConfigReglasDetalle["rowData"] = data.data; //y se recarga la tabla
            this.getField("rejilla_config_reglas_detalle").initData(this.gridOptionsTablaConfigReglasDetalle);
        }else{
            this.alertGeneral.toggle(true, 'No tiene reglas configuradas.', 'error');
        }
    }


    setButtonGraficarReglasTercero(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.DatosVerMasTercero(id);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Ver regla");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);
    }

    DatosVerMasTercero(id){
        let datos={datos: {bancos_multicash_archivos_original_configuracion_id: id}};
        this.generalFormatPmv = { tipo_servicio:  'maes-integrarbancosmulticash', operacion: 'traerreglasxconfiguracionterceros', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosVerMasTerceros,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false,
        });
    }

    successDatosVerMasTerceros(data){
        if(data.estado_p === 200){
            this.getField('modal_detalle_terceros').handleClickOpen();
            this.gridOptionsTablaConfigReglasDetalleTerceros["rowData"] = data.data; //y se recarga la tabla
            this.getField("rejilla_config_reglas_detalle_terceros").initData(this.gridOptionsTablaConfigReglasDetalleTerceros);
        }else{
            this.alertGeneral.toggle(true, 'No tiene reglas para terceros configuradas.', 'error');
        }
    }

    setButtonGraficarPLantillas(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.plantillas_electronicas_gastos_id;

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.DatosVerMasPlantillasDetalle(id);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Ver detalle");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);
    }

    DatosVerMasPlantillasDetalle(id){
        let datos={datos: {plantillas_electronicas_gastos_id: id}};
        this.generalFormatPmv = { tipo_servicio:  'maes-integrarbancosmulticash', operacion: 'traerreglasxconfiguracionplantilas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosVerMasPlantillasDetalle,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false,
        });
    }

    successDatosVerMasPlantillasDetalle(data){
        if(data.estado_p === 200){
            this.getField('modal_detalle_plantillas').handleClickOpen();
            this.gridOptionsTablaPlantillasPredefrinidos["rowData"] = data.data; //y se recarga la tabla
            this.getField("rejilla_config_plantillas").initData(this.gridOptionsTablaPlantillasPredefrinidos);
        }else{
            this.alertGeneral.toggle(true, 'No tiene platillas configuradas.', 'error');
        }
    }

    llenarOperadorLogico()
    {
        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        let data2 = {
            "data": [
                {
                    "codigo": 'AND',
                    "descripcion": 'AND',
                },
                {
                    "codigo": 'OR',
                    "descripcion": 'OR',
                }
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo;
            dataOp['text'] = item.descripcion;
            dataOp['campos_cambian'] = {};
            opciones.push(dataOp);
        });
        this.getField('operador_logico').setOptions(opciones);
    }

    abrirtabla(){
        if (this.getField('tercero_id').getValue()!=='' && this.getField('nombre_consulta').getValue()!==''){
            this.getField('nombre_consulta').setError(false, "");


            let datos={datos: {tercero_id: parseInt(this.getField('tercero_id').getValue())}};
            this.generalFormatPmv = { tipo_servicio: 'cont-asignarplanilla', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successAbrirTabla,
                error: this.error_,
                general: this.generalFormatPmv 
            });

        }else{            
            this.getField('label').setVisible(false);
            this.getField('label8').setVisible(false);
            this.getField('plantillas').setVisible(false);
            this.getField('rejillapredefinidos').toggle(false);
        }
        this.plantillas_electronicas_gastos_id = 0;
        this.pegpId = 0 ;
        
    }

    successAbrirTabla(data){
        if (data.estado_p === 200 ) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.detalle;
                dataOp['campos_cambian'] = {nombre_plantilla: item.detalle};
                opciones.push(dataOp);                
            });
            this.getField('plantillas').setOptions(opciones);
            this.getField('label').setVisible(true);
            this.getField('plantillas').setVisible(true);

            if (data.data.length === 1) {
                this.getField('plantillas').setValue(data.data[0].id);
                this.DatosVerMasPlantillas();                
            }

            this.getField("btn_finalizar").setDisabled(false);
        }else{
            this.getField("btn_finalizar").setDisabled(true);
            this.alertGeneral.toggle(true, 'Error al consultar la categoria de contabilización.', 'error');
        }
    }



    DatosVerMasPlantillas(){
        if (this.getField('plantillas').getValue() !== ''){
            // this.plantillas_electronicas_gastos_id = this.getField('plantillas').getValue();
            this.gridOptionsComponentesPredefinidos['rowData'] = [];
            this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos);
            this.getField('rejillapredefinidos').toggle(false);
            let datos={datos: {plantillas_electronicas_gastos_id: this.getField('plantillas').getValue()}};
            this.generalFormatPmv = { tipo_servicio:  'cont-configdocumenpredefinidos', operacion: 'getpegpxnit', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosVerMasPlantillas,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.getField('plantillas').setError(true,'Seleccione una plantilla.');
        }

    }

    successDatosVerMasPlantillas(data){
        if (data.estado_p === 200 ) {
            this.getField('label8').setVisible(true);
            this.getField('rejillapredefinidos').toggle(true);
            this.gridOptionsComponentesPredefinidos['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('check', {headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: false,checkboxSelection: true, sortable: false, filter: false, field: "", width: 43});
            this.gridOptionsComponentesPredefinidos["suppressRowClickSelection"] = true;
            this.gridOptionsComponentesPredefinidos["rowSelection"] = "single";
            this.gridOptionsComponentesPredefinidos["enableRangeSelection"] = true;
            this.gridOptionsComponentesPredefinidos["enableCellChangeFlash"] = true;
            this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos,configCell);
            this.gridOptionsComponentesPredefinidos["onSelectionChanged"] = this.rowVerMasPlantillas;
        } else {
            this.alertGeneral.toggle(true, 'Error al cargar la plantilla electronica gastos predefinidos.', 'error');
        }
    }

    rowVerMasPlantillas(){
        let arrayDataCheckBox = this.gridOptionsComponentesPredefinidos.api.getSelectedRows();
        if (arrayDataCheckBox.length >= 1) {
            this.plantillas_electronicas_gastos_id = arrayDataCheckBox[0].id;
        }else{
            this.alertGeneral.toggle(true, 'Error al cargar ID de la plantilla electronica gastos predefinidos.', 'error');
        }
    }
    
    pantallaInicio(){
        this.getField('nit_defecto').setValue('');
        this.getField('sede_defecto').setValue('');
        this.getField('nombre_consulta').setValue('');
        this.getField('plantillas').setValue('');
        this.getField('tercero_id').setValue('');
        this.getField('operador_logico').setValue('');
        this.getField('camposparatomarvalor').setValue('');
        
        
        this.getField('nit_defecto').setError(false, "");
        this.getField('sede_defecto').setError(false, "");
        this.getField('nombre_consulta').setError(false, "");
        this.getField('plantillas').setError(false, "");
        this.getField('tercero_id').setError(false, "");
        this.getField('operador_logico').setError(false, "");
        this.getField('camposparatomarvalor').setError(false, "");        
        this.getField('label').setVisible(false);
        this.getField('label8').setVisible(false);
        this.getField('plantillas').setVisible(false);
        this.getField('rejillapredefinidos').toggle(false);


        this.regla                                      = {};
        this.camposArrayMulticash                       = [];
        this.campo                                      = 0;
        this.nuevoArray                                 = [];
        this.arrayTemporal                              = [];
        this.getField("modal_multicash").handleClose();
        this.getField('btn_crear_regla').setDisabled(true);
        this.getField('btn_crear_regla').setVisible(false);
        this.getField('btn_cancelar_regla').setDisabled(true);
        this.getField('btn_cancelar_regla').setVisible(false);
    }


    configurarTercero(){
        this.getField("modal_config_tercero").handleClickOpen();
    }

    traerValores(){        
        this.getField('resultado').setValue('');
        this.getField('campos_valor').setValue('');

        if (this.getField("campos").valid()){

            let datos={ datos: {
                campos:this.getField('campos').getValue(),
                bancos_id: this.getField('bancos_id').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio:  'maes-integrarbancosmulticash', operacion: 'traervalorcampos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosValores,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successDatosValores(data){

        this.getField('resultado').setValue('');

        if (data.estado_p === 200 ) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.campo;
                dataOp['text'] = item.campo;
                dataOp['campos_cambian'] = {resultado: item.campo};
                opciones.push(dataOp);                
            });
            this.getField('campos_valor').setOptions(opciones);
        } else {
            this.alertGeneral.toggle(true, 'Error al cargar los valores.', 'error');
        }        
    }



    quitarCaracteresFinal(){      
        this.getField('caracteres2').setValue(0);
        if (this.getField('resultado').getValue()!==''){
            if (this.getField('caracteres').getValue()>0){
                if (this.getField('caracteres').getValue() < this.getField('resultado').getValue().length){
                    // al final 
                    const str = this.getField('resultado').getValue(); 
                    const newStr = str.slice(0,-this.getField('caracteres').getValue()); 
                    this.getField('resultado').setValue(newStr);
                }else{
                    this.alertGeneral.toggle(true, 'Caracteres a retirar no pueden ser mayor a la longitud del resultado.', 'error'); 
                }
            } 

        }
    }

    quitarCaracteresInicio(){

        this.getField('caracteres').setValue(0);
        if (this.getField('resultado').getValue()!==''){
            if (this.getField('caracteres2').getValue() < this.getField('resultado').getValue().length){

                // al inicio 
                const str =  this.getField('resultado').getValue();
                const newStr = str.slice(this.getField('caracteres2').getValue())
                this.getField('resultado').setValue(newStr);

            }else{
                this.alertGeneral.toggle(true, 'Caracteres a retirar no pueden ser mayor a la longitud del resultado.', 'error'); 
            }
        }
    }



    mostrarReglasTerceros(){

        if (this.getField('campos').getValue()!==''&&this.getField('campos_valor').getValue()!==''&&this.getField('resultado').getValue()!==''){
            this.reglaTercero = {};
            this.reglaTercero[`campo`] = (this.getField('campos').getValue());
            this.reglaTercero[`valor`] = (this.getField('campos_valor').getValue());
            this.reglaTercero[`resultado`] = (this.getField('resultado').getValue());

    
            this.arrayTemporalTercero.push(this.reglaTercero); //el ítem agregado se carga primero en un array temporal
            let campoArray1 = this.reglaTercero.valor; //el campo se guarda en un array
            let campoArray2 = []; // se crea un array vacio
            this.nuevoArrayTercero.forEach((item) => {
                //se recorre el array principal, y se busca si el campo en el array1 ya se encuentra en el array principal
                if (Number(item.valor) === Number(campoArray1)) {
                    campoArray2.push(item); // si es asi, se carga ese campo en el array2
                }
            });
    
    
            if (campoArray2.length === 0) {
                this.nuevoArrayTercero.push(this.reglaTercero);
                this.loadTablaItemsTerceros(this.nuevoArrayTercero);
                this.reglaTercero = {};
            } else {
                this.reglaTercero = {};
                //si el array2 es diferente de 0, quiere decir que el campo ya fue agregado y se muestra el mensaje
                this.alertGeneral.toggle(true, "El campo ya fue agregado", "error");
            }
        }else{
            this.alertGeneral.toggle(true, "Debe seleccionar los campos de forma correcta.", "error");
        }
    }

    loadTablaItemsTerceros(data){


        this.gridOptionsTablaItemsTercero["rowData"] = data; //y se recarga la tabla
        let configCell = new Map();
        configCell.set('accion', { cellRenderer: this.crearBotonesTablaItemTerceros, sortable: false, filter: false, field: "Acción"});
        this.gridOptionsTablaItemsTercero["onGridReady"] = (event) => event.api.sizeColumnsToFit();        
        this.getField("rejilla_reglas_tercero").initData(this.gridOptionsTablaItemsTercero,configCell);
    }


    crearBotonesTablaItemTerceros(props){
        let fragment = document.createDocumentFragment();
        let btnEliminarTablaItemTercero = this.crearBotonTabla3(props.data, 'Eliminar Tabla Item');
        btnEliminarTablaItemTercero.setAttribute("class","buttonStyle2");
        fragment.appendChild(btnEliminarTablaItemTercero);
        return this.createElementJaivana(fragment);
    }

    crearBotonTabla3(data,boton){
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar Tabla Item':
                button.setAttribute("id", `boton_eliminar_tbItem_${data.valor}`);
                break;
            default:
                break;
        }

        button.onclick = () => { this.accionBotonTercero(data, boton) };
        if (boton === 'Eliminar Tabla Item') {
            button.setAttribute("value", 'Eliminar');
        }else {
            button.setAttribute("value", boton);
        }
        button.setAttribute("type", "button");
        return button;
    }

    accionBotonTercero(data,boton){
        if (boton === 'Eliminar Tabla Item') {
            this.eliminarItemTablaItemTercero(data, 'Eliminar');
        }
    }

    eliminarItemTablaItemTercero(data, boton){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => { this.eliminarItemTablaTercero(data) });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarItemTablaTercero(data){
        this.getField('confirmModalCustom').toggle(false);
        let indexItem = this.nuevoArrayTercero.indexOf(data);
        this.nuevoArrayTercero.splice(indexItem, 1);
        if (this.nuevoArrayTercero.length >= 1) {
            this.gridOptionsTablaItemsTercero["rowData"] = this.nuevoArrayTercero;
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.crearBotonesTablaItemTerceros, sortable: false, filter: false, field: "Acción"});
            this.gridOptionsTablaItemsTercero["onGridReady"] = (event) => event.api.sizeColumnsToFit();   
            this.getField("rejilla_reglas_tercero").initData(this.gridOptionsTablaItemsTercero,configCell);
        } else {
            this.getField('rejilla_reglas_tercero').toggle(false);
        }
    }

    finalizarReglas(){

        let datos = {};
        if (this.nuevoArrayTercero.length>0){
            datos = {
                datos:{
                    bancos_id:this.getField("id_bancos").getValue()+"",
                    nit_defecto:this.getField("nit_defecto").getValue()+"",
                    sede_defecto:this.getField("sede_defecto").getValue(),                  
                    detalle:this.getField("detalle").getValue()+"",                    
                    camposparatomarvalor:this.getField("camposparatomarvalor").getValue()+"",
                    plantillas:this.plantillas_electronicas_gastos_id,
                    reglasitems: this.nuevoArray,
                    reglasitemsterceros: this.nuevoArrayTercero,
                }
            }
            if (this.getField("id_bancos_cuentas").getValue() !== '' && this.getField("id_bancos_cuentas").getValue() !== ' ') {
                datos.datos["bancos_cuentas_id"] = this.getField("id_bancos_cuentas").getValue()+"";
            }else{
                datos.datos["bancos_cuentas"] = this.banco_cuenta+"";
            }
            if (this.getField("operador_logico").getValue() !== '' && this.getField("operador_logico").getValue() !== ' ') {
                datos.datos["operador_logico"] = this.getField("operador_logico").getValue()+"";
            }else{
                datos.datos["operador_logico"] = '.';
            }

            this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearRegla,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
                
        }
        
        else{
            datos = {
                datos:{
                    bancos_id:this.getField("id_bancos").getValue()+"",
                    nit_defecto:this.getField("nit_defecto").getValue()+"",
                    sede_defecto:this.getField("sede_defecto").getValue(),                  
                    detalle:this.getField("detalle").getValue()+"",                    
                    camposparatomarvalor:this.getField("camposparatomarvalor").getValue()+"",
                    plantillas:this.plantillas_electronicas_gastos_id,
                    reglasitems: this.nuevoArray,
                    reglasitemsterceros:[],
                }
            }
            if (this.getField("id_bancos_cuentas").getValue() !== '' && this.getField("id_bancos_cuentas").getValue() !== ' ') {
                datos.datos["bancos_cuentas_id"] = this.getField("id_bancos_cuentas").getValue()+"";
            }else{
                datos.datos["bancos_cuentas"] = this.banco_cuenta+"";
            }
            if (this.getField("operador_logico").getValue() !== '' && this.getField("operador_logico").getValue() !== ' ') {
                datos.datos["operador_logico"] = this.getField("operador_logico").getValue()+"";
            }else{
                datos.datos["operador_logico"] = '.';
            }

            this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearRegla,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }

    }

    selectionRegistroAplicaTercero(props){
        let aplica_regla_tercero = props.data.aplica_regla_tercero;
        if (props.data.aplica_regla_tercero === 'S') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyleBancos");
            div.textContent = aplica_regla_tercero;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = aplica_regla_tercero;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistroEstadoContabilidad(props){
        let estado_contabilizacion = props.data.estado_contabilizacion;
        if (props.data.estado_contabilizacion === 'CONTABILIZADO') {
            let div = document.createElement('div');
            div.setAttribute("class","divStyleBancos2");
            div.textContent = estado_contabilizacion;
            return this.createElementJaivana(div);
        } else if (props.data.estado_contabilizacion === 'POR CONTABILIZAR'){
            let div = document.createElement('div');
            div.setAttribute("class","divStyleBancos3");
            div.textContent = estado_contabilizacion;
            return this.createElementJaivana(div);
        }else {
            let div = document.createElement('div');
            div.setAttribute("class","divStyle6");
            div.textContent = estado_contabilizacion;
            return this.createElementJaivana(div);
        }
    }

    contabilizarArchivo(){

        let datos = {};
        datos = {
            datos:{
                bancos_id:this.getField("id_bancos").getValue()+"",
                codigo_sucursal:JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal+"",
            }
        }
        if (this.getField("id_bancos_cuentas").getValue() !== '' && this.getField("id_bancos_cuentas").getValue() !== ' ') {
            datos.datos["bancos_cuentas_id"] = this.getField("id_bancos_cuentas").getValue()+"";
        }

        this.generalFormatPmv = { tipo_servicio: 'maes-integrarbancosmulticash', operacion: 'contabilizararchivo', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successContabilizacionArchivo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false,
        });
            

    }

    successContabilizacionArchivo(data){
        if(data.estado_p === 200){ 

            this.getField('confirmModalCustom').setTitleAndContent('MULTICASH', 'Contabilización éxitosa: ' + data.data.numero_documento)
            this.getField("confirmModalCustom").setButtonConfirm('CONFIRMAR');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false);
                this.validarCampos() 
            });
            this.getField("confirmModalCustom").setVisibleCancel(true);// Para mostrar el Cancelar en los ConfirmDialog
            this.getField('confirmModalCustom').toggle(true);


        }else{
            this.getField('confirmModalCustom').setTitleAndContent('MULTICASH', 'No hay registros por contabilizar.')
            this.getField("confirmModalCustom").setButtonConfirm('CONFIRMAR');
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setVisibleCancel(true);// Para mostrar el Cancelar en los ConfirmDialog
            this.getField('confirmModalCustom').toggle(true);
        }
    }


}
FormJaivana.addController('maes-integrarbancosmulticash',CustomIntegrarBancosMulticash);
export default CustomIntegrarBancosMulticash;