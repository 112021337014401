import FormJaivana from "dashboard_jaivana_v1";
/**
 *
 * @author Crristian Ciro
 * @UpdateBy Anderson Acevedo
/** 
 * Custom  Asignar Planilla terceros
 **/
class CustomAsignarPlanillaTerceros extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                           = this.initForm.bind(this);
        this.traerDatos                         = this.traerDatos.bind(this);
        this.setButtonCambiar                   = this.setButtonCambiar.bind(this);
        this.successTraerDatos                  = this.successTraerDatos.bind(this);
        this.gridOptionsTbPrincipal             = Object.assign({},this.gridOptions);
        this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
        this.gridOptionsTbPrincipal['onSelectionChanged']         =   this.onSelectionChanged;

        this.enviarArchivo                      = this.enviarArchivo.bind(this);
        this.successCrear                       = this.successCrear.bind(this);
        this.successSubirArchivo                = this.successSubirArchivo.bind(this);
        this.eliminarPlanilla                   = this.eliminarPlanilla.bind(this);
        this.successEliminar                    = this.successEliminar.bind(this);
        this.limpiarCampos                      = this.limpiarCampos.bind(this);
        this.successEliminarArchivo             = this.successEliminarArchivo.bind(this);
        this.id                                 = 0;
        this.archivoUf                          = "";
        this.url_servidor=''
        this.traerUrlSubirArchivos= this.traerUrlSubirArchivos.bind(this);
        this.succesTraerUrlSubirArchivos= this.succesTraerUrlSubirArchivos.bind(this);
        this.limpiarCamposCrear =   this.limpiarCamposCrear.bind(this);
        this.eliminar   =   this.eliminar.bind(this);

    }

    initForm() {
        console.log("Formulario CustomAsignarPlanillaTerceros,  @version: jdesk_1.01.0001, @author: Cristian Ciro,@updateBy Anderson Acevedo");
        this.traerUrlSubirArchivos();

        this.getField("tercero_id").setOnChange(this.traerDatos);
        this.getField("btnAceptar").setClick(this.enviarArchivo);
        this.getField("btnCancelar").setClick(this.limpiarCampos);
        this.getField("maneja_documento_fuente").setValue("N")

        this.getField('archivo').setOnChange((props) => { 
            if(props.target.files[0] !== undefined && props.target.files[0] !== " " ){
                this.archivoUf = props.target.files[0];
                /*this.uploadFile(props);*/ 
                let array=props.target.files[0].name.split('.');
                this.extension=array[array.length-1];
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    this.getField('archivo').setError(false, '');
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                }
            }else{
                this.archivoUf = "";
                this.getField('archivo').setError(false, '');
            }
            
             
        });

    }

    onSelectionChanged(){
        //let rowDataTable = this.gridOptionsTbPrincipal.api.getSelectedRows();
    }

    limpiarCampos(){
        this.getField("detalle").setValue("");
        this.getField("archivo").handleClickDelete(this);
        this.getField("categoria").setValue("");
        this.getField("estadosactivos_id").setValue("");
        this.getField("nombre").setValue("");
        this.getField("sede").setValue("");
        this.getField("nit").setValue("");
        this.getField("codigo_documento").setValue("");
        this.getField("nombre_documento").setValue("");
        this.getField("documentos_id").setValue("");
        this.getField("codigo_dptointerno").setValue("");
        this.getField("nombre_dptointerno").setValue("");
        this.getField("departamento_interno_id").setValue("");
        this.getField("maneja_documento_fuente").setValue("");
        this.getField("detalle").setValue("");
        this.getField("archivo").setValue("");
        this.getField('resumen_archivos').toggle(false);
        this.getField("nit").setError(false,'');
        this.getField("sede").setError(false,'');
        this.getField("nombre").setError(false,'');
        this.getField("categoria").setError(false,'');
        this.getField("estadosactivos_id").setError(false,'');
        this.getField("codigo_documento").setError(false,'');
        this.getField("nombre_documento").setError(false,'');
        this.getField("codigo_dptointerno").setError(false,'');
        this.getField("nombre_dptointerno").setError(false,'');
        this.getField("maneja_documento_fuente").setError(false,'');
        this.getField("detalle").setError(false,'');
        this.getField("archivo").setError(false,'');
        this.getField('maneja_documento_fuente').setValue('N');
        this.archivoUf = "";
    }

    limpiarCamposCrear(){
        this.getField("detalle").setValue("");
        this.getField("archivo").handleClickDelete(this);
        this.getField("categoria").setValue("");
        this.getField("estadosactivos_id").setValue("");
        this.getField('resumen_archivos').toggle(false);
        this.getField("codigo_documento").setValue("");
        this.getField("nombre_documento").setValue("");
        this.getField("documentos_id").setValue("");
        this.getField("codigo_dptointerno").setValue("");
        this.getField("nombre_dptointerno").setValue("");
        this.getField("departamento_interno_id").setValue("");
        this.getField("maneja_documento_fuente").setValue("");
        this.getField("detalle").setValue("");
        this.getField("archivo").setValue("");
        this.getField("categoria").setError(false,'');
        this.getField("estadosactivos_id").setError(false,'');
        this.getField("codigo_documento").setError(false,'');
        this.getField("nombre_documento").setError(false,'');
        this.getField("codigo_dptointerno").setError(false,'');
        this.getField("nombre_dptointerno").setError(false,'');
        this.getField("maneja_documento_fuente").setError(false,'');
        this.getField("detalle").setError(false,'');
        this.getField("archivo").setError(false,'');
        this.getField('maneja_documento_fuente').setValue('N');
        this.archivoUf = "";
    }

    traerDatos(){
        this.limpiarCamposCrear();
        if(this.getField("tercero_id").getValue() !== ""){
            let datos = { datos: {tercero_id:this.getField("tercero_id").getValue()}};
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else{
            this.getField('resumen_archivos').toggle(false);
        }
    }

    /**
     insert into formularios_jdesk (codigo,modulo,nombre_formulario,version) values ('cont-asignarplanilla','contabilidad-plantillaselectronicas','Asignar Planilla Terceros',1);
insert into secciones_formularios_jdesk (secciones_id,formulario_id,orden) values (18,78,1);
     */

    successTraerDatos(data){
        if(data.estado_p === 200){
            this.getField('resumen_archivos').toggle(true);
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonCambiar, width: 110, sortable: false, filter: false, field: 'accion' });
            this.getField('resumen_archivos').initData(this.gridOptionsTbPrincipal,configCell);   
        }else{
            this.getField('resumen_archivos').toggle(false);
        }
        //this.limpiarCampos();
    }

    setButtonCambiar(props) {
        let id = props.data.id;
        let nombre_archivo = props.data.nombre_archivo;
        let button = document.createElement("input");
        button.onclick = () => this.eliminar(id, nombre_archivo);
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    eliminar(id,nombre_archivo){
        this.idEliminar = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>{this.eliminarPlanilla(id,nombre_archivo)});
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarPlanilla(id, nombre_archivo){
        this.getField('confirmModalCustom').toggle(false);
        this.id = id;
        if(nombre_archivo === '.'){
            let datos = { 
                datos: {
                    id: this.id,
                }
            };
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else{
            if(this.url_servidor!=='' && this.url_servidor!==''){
                let datos = { 
                    datos: {
                        nombre_archivo: nombre_archivo,
                    }
                };
                let url = this.url_servidor+'/xlsx/api/protected';
                this.generalFormatPmv = { tipo_servicio:  'eliminararchivo', operacion: 'eliminar_xlsx', operacion_tipo: 'eliminar' };
                this.service.send(
                    {
                        endpoint:url,
                        method: 'DELETE',
                        body: datos,
                        success: this.successEliminarArchivo,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
            }else{
                this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
            }
        }
    }

    successEliminarArchivo(data){
        if(data.estado_p === 200){
            let datos = { 
                datos: {
                    id: this.id,
                }
            };
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.traerDatos();
        }
    }

    enviarArchivo(){
        // console.log('Radio Buttom\n',this.getField("maneja_documento_fuente").getValue());
        if(this.getField("nit").valid()  && this.getField("sede").valid() && this.getField("nombre").valid() && this.getField("categoria").valid() && 
        this.getField("estadosactivos_id").valid() && this.getField("codigo_documento").valid() && this.getField("nombre_documento").valid() && 
        this.getField("codigo_dptointerno").valid() && this.getField("nombre_dptointerno").valid() && this.getField("maneja_documento_fuente").valid() && 
        this.getField("detalle").valid() && this.getField("nit").valid()){
            
            if(this.archivoUf !== ""){
                if(this.url_servidor!=='' && this.url_servidor!==''){
                    if(this.extension==='XLSX' || this.extension==='xlsx'){
                        const data = {
                            _generales: {"tipo_servicio":"subirarchivos","operacion":"xlsx_plantilla_terceros","operacion_tipo": "crear"},
                            archivo: this.archivoUf
                        }
                        let url = this.url_servidor+'/xlsx/api/protected';
                        this.generalFormat = data._generales;
                        this.service.send(
                            {
                                endpoint:url,
                                method: 'POST',
                                body: data,
                                success: this.successSubirArchivo,
                                error: this.error_,
                                general: this.generalFormat,
                                formData: true,
                                showMessage: false
                            });
                    }else{
                        this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                    }
                }else{
                    this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
                }  
            }else{
                let datos = { 
                    datos: {
                        tercero_id:this.getField("tercero_id").getValue(),
                        nit:this.getField("nit").getValue(),
                        ruta_archivo:'.',
                        nombre_archivo:'.',
                        detalle:this.getField("detalle").getValue(),
                        contabilidad_plantillas_categorias_id:this.getField("contabilidad_plantillas_categorias_id").getValue(),
                        documentos_id:this.getField("documentos_id").getValue(),
                        departamento_interno_id:this.getField("departamento_interno_id").getValue(),
                        maneja_documento_fuente:this.getField("maneja_documento_fuente").getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successCrear,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
            }
        }
    }

    successSubirArchivo(data){
        if(data.estado_p === 200){
            let datos = { 
                datos: {
                    tercero_id:this.getField("tercero_id").getValue(),
                    nit:this.getField("nit").getValue(),
                    ruta_archivo:data.data[0].ruta_archivo,
                    nombre_archivo:data.data[0].nombre_archivo,
                    contabilidad_plantillas_categorias_id:this.getField("contabilidad_plantillas_categorias_id").getValue(),
                    documentos_id:this.getField("documentos_id").getValue(),
                    departamento_interno_id:this.getField("departamento_interno_id").getValue(),
                    maneja_documento_fuente:this.getField("maneja_documento_fuente").getValue(),
                    detalle:this.getField("detalle").getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrear,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else{
            let respuesta=Object.values(data.data.errores);
			let keys=Object.keys(data.data.errores);
			this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    successCrear(data){
        if(data.estado_p === 200){
            this.traerDatos();
            this.limpiarCamposCrear();
        }
    }

    traerUrlSubirArchivos(){

        let datos={ 
            datos: {}
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-integrararchivos', operacion: 'obtenerurl', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesTraerUrlSubirArchivos,
                error: this.error_,
                general: this.generalFormatPmv
        });
      }
    
      succesTraerUrlSubirArchivos(data){
          if (data.estado_p === 200 )
          {
              this.url_servidor=data.data[0].url_subir_archivo+':'+data.data[0].puerto_servidor_subir_archivo
          }
          else 
          {
            this.url_servidor='';
          }
      }
}
FormJaivana.addController("cont-asignarplanilla", CustomAsignarPlanillaTerceros);
export default CustomAsignarPlanillaTerceros;