import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: Custom con funciones adicionales al formulario con código: comp-estadodecuenta, ID Formulario: 208, Sección: padre: Compras(id: 36)- hijo: Informes Proveedores(id: 45)
 * @author: Sebastian Rios Echeverri
 * @version: jdesk_1.01.0002
 **/
class CustomEstadoDeCuenta extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                               = this.initForm.bind(this);
        // Variables de tablas:
        this.gridOptionsCartProve                   = Object.assign({}, this.gridOptions);
        this.gridOptionsCheques                     = Object.assign({}, this.gridOptions);
        this.gridOptionsContabilidad                = Object.assign({}, this.gridOptions);
        this.gridOptionsClientes                    = Object.assign({}, this.gridOptions);
        this.gridOptionsModalPantalla               = Object.assign({}, this.gridOptions);
        this.gridOptionsResumenSucursales           = Object.assign({}, this.gridOptions);

        // Variables Globales:
        this.sucursal_ingreso                       = '';
        this.operacion_actual                       = '';
        this.preguntarFechaCorte                    = '';
        this.fechaCorte                             = '';
        this.archivo_servicio                       = 'comp-estadodecuenta';
        this.verTodo                                = false;

        // Funciones:
        this.currencyFormatterGeneral               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                      = this.formatNumberSaldo.bind(this);
        this.mostrarMensajeGenerando                = this.mostrarMensajeGenerando.bind(this);
        this.habilitarBtnConcultar                  = this.habilitarBtnConcultar.bind(this);
        this.traerSaldo                             = this.traerSaldo.bind(this);
        this.successTraerSaldo                      = this.successTraerSaldo.bind(this);
        this.traerCartProve                         = this.traerCartProve.bind(this);
        this.successTraerCartProve                  = this.successTraerCartProve.bind(this);
        this.traerCheques                           = this.traerCheques.bind(this);
        this.successTraerCheques                    = this.successTraerCheques.bind(this);
        this.habilitarCamposFrm                     = this.habilitarCamposFrm.bind(this);
        this.setButtonTbCartProve                   = this.setButtonTbCartProve.bind(this);
        this.crearBotonTabla                        = this.crearBotonTabla.bind(this);
        this.accionProcesar                         = this.accionProcesar.bind(this);
        this.traerTotalCarteraClientes              = this.traerTotalCarteraClientes.bind(this);
        this.successTraerTotalCarteraClientes       = this.successTraerTotalCarteraClientes.bind(this);
        this.traerResumenSucursales                 = this.traerResumenSucursales.bind(this);
        this.successTraerResumenSucursales          = this.successTraerResumenSucursales.bind(this);
        this.traerRecibospendientes                 = this.traerRecibospendientes.bind(this);
        this.successTraerRecibospendientes          = this.successTraerRecibospendientes.bind(this);
        this.traerTodoCartProve                     = this.traerTodoCartProve.bind(this);
        this.successTraerTodoCartProve              = this.successTraerTodoCartProve.bind(this);
        this.contabilidad                           = this.contabilidad.bind(this);
        this.successContabilidad                    = this.successContabilidad.bind(this);
        this.abrirImprimir                          = this.abrirImprimir.bind(this);
        this.habilitarCorreo                        = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                     = this.deshabilitarCorreo.bind(this);
        this.carteraClientes                        = this.carteraClientes.bind(this);
        this.successCarteraClientes                 = this.successCarteraClientes.bind(this);
        this.procesar                               = this.procesar.bind(this);
        this.enviarCorreoTerceros                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                    = this.successEnviarCorreo.bind(this);
        this.generarPdfTabla3                       = this.generarPdfTabla3.bind(this);
        this.genearModalGlobales                    = this.genearModalGlobales.bind(this);
        this.successGenearModalGlobales             = this.successGenearModalGlobales.bind(this);
        this.generarExcelTabla3                     = this.generarExcelTabla3.bind(this);
        this.isJson                                 = this.isJson.bind(this);
        this.mostrarMensajeNoHayDatos               = this.mostrarMensajeNoHayDatos.bind(this);
        this.traerPropiedades                       = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades                 = this.sucesstraerPropiedades.bind(this);
        this.mostrarMensajeEmailEnviado             = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo               = this.mostrarMensajeEmailFallo.bind(this);
    }


    initForm() {
        //Versión de CUSTOM:
        console.log('Formulario CustomEstadoDeCuenta,  @version: jdesk_1.01.0002, @author: Sebastian Rios Echeverri');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        this.getField('consultar').setDisabled(true);
        this.getField('nombre_nit').setOnChange(()=>{this.habilitarBtnConcultar(); this.traerSaldo(); this.habilitarCamposFrm(false);});
        this.getField('saldo').setOnChange(()=>{
            this.traerCartProve(); 
            if (this.getField('saldo').getValue() !== '') {
                this.habilitarCamposFrm(true);
            }
        });
        this.getField('consultar').setClick(()=>{this.traerSaldo();});
        this.getField('ver_todo').setClick(this.traerTodoCartProve);
        this.getField('ver_presentacion').setClick(()=>{this.traerPropiedades();});
        this.getField('cancelar_fecha').setClick(()=>{
            this.getField('modal_fecha_corte').handleClose();
        });
        this.getField('aceptar_fecha').setClick(()=>{
            if (this.getField('fecha_corte').valid()) {
                this.fechaCorte = this.getField('fecha_corte').getValue();
                this.getField('modal_fecha_corte').handleClose();
                this.abrirImprimir();
            }
        });
        //para balances globales
        this.getField('ch_excel').setOnChange(()=>{
            if(this.getField('ch_excel').getValue()==='false'){
            this.getField('ch_pdf').setValueSwitch (false);
            this.getField('ch_pantalla').setValueSwitch (false);
            this.operacion_actual='excel';
            this.habilitarCorreo();
            }
        });
        this.getField('ch_pdf').setOnChange(()=>{
            if(this.getField('ch_pdf').getValue()==='false'){
            this.getField('ch_excel').setValueSwitch (false);
            this.getField('ch_pantalla').setValueSwitch (false);
            this.operacion_actual='pdf';
            this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange(()=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
            this.getField('ch_excel').setValueSwitch (false);
            this.getField('ch_pdf').setValueSwitch (false);
            this.operacion_actual='pantalla';
            this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });
        this.getField('clientes').setClick(this.carteraClientes);
        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setClick(()=>{this.procesar();});
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    traerPropiedades(){
        let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.preguntarFechaCorte = data.data.fecha_corte;
            this.fechaCorte = '';
            if (this.preguntarFechaCorte === 'S') {
                this.getField('modal_fecha_corte').handleClickOpen();
            }else{
                this.abrirImprimir();
            }
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    generarExcelTabla3(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2= {
            nit: this.getField('nit').getValue(),
            sede_nit: this.getField('sede_nit').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            ver_todo: this.verTodo,
            fecha_corte: this.fechaCorte,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocuentaproveedor';
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+archivo_operacion+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
            return reader.read().then(({ done, value }) => {
            if (done) {
                controller.close();
                return;}
            controller.enqueue(value);
            return pump();});
        }}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }
    
    generarPdfTabla3(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2= {
            nit: this.getField('nit').getValue(),
            sede_nit: this.getField('sede_nit').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            ver_todo: this.verTodo,
            fecha_corte: this.fechaCorte,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        const myJSON = JSON.stringify(datos2);
        let archivo_operacion = 'estadocuentaproveedor';
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+archivo_operacion+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
    genearModalGlobales(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{}}
        datos.datos= {
            nit: this.getField('nit').getValue(),
            sede_nit: this.getField('sede_nit').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            ver_todo: this.verTodo,
            fecha_corte: this.fechaCorte,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocuentaproveedor';
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successGenearModalGlobales,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }
    
    successGenearModalGlobales(data){
        // console.log('data successGenearModalGlobales:\n',data);
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosLista = data.data.datos_lista;
            this.gridOptionsModalPantalla['rowData'] = [];
            this.getField('tb_modal_pantalla').initData(this.gridOptionsModalPantalla);
            this.getField('tb_modal_pantalla').toggle(false);
            
            let datosFicha = [];
            let data2 = {
                "nombre_empresa": datosLista.nombre_empresa,
                "nit_proveedor": datosLista.nit_proveedor,
                "sede_proveedor": datosLista.sede_proveedor,
                "nombre_proveedor": datosLista.nombre_proveedor,
                "direccion": datosLista.direccion,
                "telefono": datosLista.telefono,
                "ciudad": datosLista.ciudad,
            };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            
            this.getField("modal_pantalla").setLabel(datosLista.titulo_informe);
            this.getField('modal_pantalla').handleClickOpen();
            let configCell = new Map();
            this.gridOptionsModalPantalla['rowData'] = data.data.datos_items;
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"}});
            
            this.getField('tb_modal_pantalla').toggle(true);
            this.getField('tb_modal_pantalla').initData(this.gridOptionsModalPantalla,configCell);
            this.getField('valor_total_pantalla').setValue(this.currencyFormatterGeneral(datosLista.total_general));
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }
    
    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = {datos: {}};
        datos.datos= {
            nit: this.getField('nit').getValue(),
            sede_nit: this.getField('sede_nit').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            ver_todo: this.verTodo,
            fecha_corte: this.fechaCorte,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocuentaproveedor';
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else{
            this.mostrarMensajeEmailFallo();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='devcompra';
                    this.nombre_excel='devcompra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }
    
    carteraClientes(){
        if (this.getField('nit').getValue() !== '' && this.getField('sede_nit').getValue() !== '' && this.getField('nombre_nit').getValue() !== '' &&
        this.getField('nit').getValue() !== ' ' && this.getField('sede_nit').getValue() !== ' ' && this.getField('nombre_nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'carteraclientes', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCarteraClientes,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successCarteraClientes(data){
        // console.log('data successCarteraClientes:\n',data);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.gridOptionsClientes['rowData'] = [];
            this.getField('tb_clientes').initData(this.gridOptionsClientes,configCell);
            this.getField('tb_clientes').toggle(false);

            this.gridOptionsClientes['rowData'] = data.data.data;
            configCell.set('numero', {headerName: 'Número'});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"}});
            this.getField('tb_clientes').toggle(true);
            this.getField('tb_clientes').initData(this.gridOptionsClientes,configCell);
            
            this.getField('modal_clientes').handleClickOpen();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('consultar').setDisabled(false);
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    //Inicio modal ver presentación
    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }
    
    abrirImprimir(){
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    //Fin modal ver presentación
    
    traerTodoCartProve(){
        this.verTodo = true;
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traertodocartprove', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTodoCartProve,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerTodoCartProve(data){
        // console.log("data successTraerTodoCartProve:\n",data);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.gridOptionsCartProve['rowData'] = [];
            this.getField('tb_cartprove').initData(this.gridOptionsCartProve,configCell);
            this.getField('tb_cartprove').toggle(false);

            this.gridOptionsCartProve['rowData'] = data.data.data;
            configCell.set('codigo_sucursal', {headerName: 'Código Sucursal'});
            configCell.set('numero', {headerName: 'Número'});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"}});
            configCell.set('texto', {cellStyle: {headerName:"Detalle"}});
            configCell.set('accion', { cellRenderer: this.setButtonTbCartProve, headerName: 'Acción', sortable: false, filter: false });
            this.getField('tb_cartprove').toggle(true);
            this.getField('tb_cartprove').initData(this.gridOptionsCartProve,configCell);
            
            this.getField('confirmModalCustom').toggle(false);
            this.getField('consultar').setDisabled(false);
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    traerRecibospendientes(){
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ' &&
        this.getField('sede_nit').getValue() !== '' && this.getField('sede_nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue(),
                sede_nit: this.getField('sede_nit').getValue(),
                sucursal_ingreso: this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'recibospendientes', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerRecibospendientes,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerRecibospendientes(data){
        // console.log('data successTraerRecibospendientes:\n',data);
        if (data.estado_p === 200) {
            this.getField('pendiente').setValue(data.data.total);
            
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    traerResumenSucursales(){
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'resumensucursales', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerResumenSucursales,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerResumenSucursales(data){
        // console.log('data successTraerResumenSucursales:\n',data);
        if (data.estado_p === 200) {
            let textoFinal = '';
            data.data.data.forEach((item)=>{
                textoFinal += ` ${item.codigo_sucursal} -> ${this.currencyFormatterGeneral(item.xsaldo)};`;
            });
            this.getField('resumen_sucursales').setValue(textoFinal.substring(0, textoFinal.length - 1));

            let configCell = new Map();
            this.gridOptionsResumenSucursales['rowData'] = [];
            this.getField('tb_resumen_sucursales').initData(this.gridOptionsResumenSucursales,configCell);
            this.getField('tb_resumen_sucursales').toggle(false);

            this.gridOptionsResumenSucursales['rowData'] = data.data.data;
            configCell.set('codigo_sucursal  ', {headerName: 'Código Sucursal'});
            configCell.set('xsaldo', {headerName: 'Saldo', cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xsaldo) }, cellStyle: {textAlign:"right"}});
            this.getField('tb_resumen_sucursales').toggle(true);
            this.getField('tb_resumen_sucursales').initData(this.gridOptionsResumenSucursales,configCell);
            
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
            this.traerRecibospendientes();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    traerTotalCarteraClientes(){
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traertotalcarteraclientes', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTotalCarteraClientes,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerTotalCarteraClientes(data){
        // console.log('data successTraerTotalCarteraClientes:\n',data);
        if (data.estado_p === 200) {
            this.getField('total').setValue(this.currencyFormatterGeneral(data.data.total));
            
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
            this.traerResumenSucursales();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    habilitarCamposFrm(estado){
        this.getField('pendiente').setVisible(estado);
        // this.getField('resumen_sucursales').setVisible(estado);
        this.getField('total').setVisible(estado);
        this.getField('ver_todo').setVisible(estado);
        this.getField('clientes').setVisible(estado);
        this.getField('ver_presentacion').setVisible(estado);
        
        if (!(this.getField('nit').getValue() !== '' && this.getField('sede_nit').getValue() !== '' && this.getField('nombre_nit').getValue() !== '' &&
        this.getField('nit').getValue() !== ' ' && this.getField('sede_nit').getValue() !== ' ' && this.getField('nombre_nit').getValue() !== ' ')) {
            this.getField('saldo').setValue('');

            let configCell = new Map();
            this.gridOptionsCheques['rowData'] = [];
            this.getField('tb_cheques').initData(this.gridOptionsCheques,configCell);
            this.getField('tb_cheques').toggle(false);
            
            
            this.gridOptionsCartProve['rowData'] = [];
            this.getField('tb_cartprove').initData(this.gridOptionsCartProve,configCell);
            this.getField('tb_cartprove').toggle(false);

            this.gridOptionsResumenSucursales['rowData'] = [];
            this.getField('tb_resumen_sucursales').initData(this.gridOptionsResumenSucursales,configCell);
            this.getField('tb_resumen_sucursales').toggle(false);
            
            this.getField('pendiente').setValue('');
            this.getField('resumen_sucursales').setValue('');
            this.getField('total').setValue('');
            this.preguntarFechaCorte = '';
        }
    }
    
    traerCheques(){
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traercheques', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCheques,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerCheques(data){
        // console.log("data successTraerCheques:\n",data);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.gridOptionsCheques['rowData'] = [];
            this.getField('tb_cheques').initData(this.gridOptionsCheques,configCell);
            this.getField('tb_cheques').toggle(false);

            this.gridOptionsCheques['rowData'] = data.data.data;
            configCell.set('fecha_cheque', {headerName: 'Fecha Cheque'});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            configCell.set('texto', {cellStyle: {headerName:"Detalle"}});
            configCell.set('codigo_sucursal', {headerName: 'Código Sucursal'});
            this.getField('tb_cheques').toggle(true);
            this.getField('tb_cheques').initData(this.gridOptionsCheques,configCell);
            
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
            this.traerTotalCarteraClientes();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    traerCartProve(){
        if (this.getField('nit').getValue() !== '' && this.getField('nit').getValue() !== ' ' &&
        this.getField('saldo').getValue() !== '' && this.getField('saldo').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traercartprove', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCartProve,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerCartProve(data){
        // console.log("data successTraerCartProve:\n",data);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.gridOptionsCartProve['rowData'] = [];
            this.getField('tb_cartprove').initData(this.gridOptionsCartProve,configCell);
            this.getField('tb_cartprove').toggle(false);

            this.gridOptionsCartProve['rowData'] = data.data.data;
            configCell.set('codigo_sucursal', {headerName: 'Código Sucursal'});
            configCell.set('numero', {headerName: 'Número'});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"}});
            configCell.set('texto', {cellStyle: {headerName:"Detalle"}});
            configCell.set('accion', { cellRenderer: this.setButtonTbCartProve, headerName: 'Acción', sortable: false, filter: false });
            this.getField('tb_cartprove').toggle(true);
            this.getField('tb_cartprove').initData(this.gridOptionsCartProve,configCell);
            
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
            this.traerCheques();
            this.habilitarCamposFrm(true);
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    setButtonTbCartProve(props) {
        let fragment = document.createDocumentFragment();
        let buttonContabilidad = this.crearBotonTabla(props.data, 'Contabilidad');
        buttonContabilidad.setAttribute("class", "buttonStyle2");
        fragment.appendChild(buttonContabilidad);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Contabilidad"} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Contabilidad':
                button.setAttribute("id", `buttonContabilidad_${data.codigo_sucursal}`);
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Contabilidad")} boton 
      */
    accionProcesar(data, boton) {
        if (boton === 'Contabilidad') {
            this.contabilidad(data);
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    contabilidad(props){
        // console.log("Estas son las props a anlizar:\n",props);
        if (this.getField('nit').getValue() !== '' && this.getField('sede_nit').getValue() !== '' && 
        props !== undefined  && props !== null && props !== '') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue(),
                sede_nit: this.getField('sede_nit').getValue(),
                documento_fuente: props.numero,
                codigo_sucursal: props.codigo_sucursal,
                sucursal_ingreso: this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'contabilidad', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successContabilidad,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }
    
    successContabilidad(data){
        // console.log("data successContabilidad:\n",data);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.gridOptionsContabilidad['rowData'] = [];
            this.getField('tb_contabilidad').initData(this.gridOptionsContabilidad,configCell);
            this.getField('tb_contabilidad').toggle(false);

            this.gridOptionsContabilidad['rowData'] = data.data.data;
            configCell.set('fecha_documento', {headerName: 'Fecha Documento'});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
            this.getField('tb_contabilidad').toggle(true);
            this.getField('tb_contabilidad').initData(this.gridOptionsContabilidad,configCell);
            
            this.getField('confirmModalCustom').toggle(false);
            this.getField('modal_contabilidad').handleClickOpen();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    traerSaldo(){
        this.verTodo = false;
        if (this.getField('nit').getValue() !== '' && this.getField('sede_nit').getValue() !== '' && this.getField('nombre_nit').getValue() !== '' &&
        this.getField('nit').getValue() !== ' ' && this.getField('sede_nit').getValue() !== ' ' && this.getField('nombre_nit').getValue() !== ' ') {
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit: this.getField('nit').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-estadodecuenta', operacion: 'traersaldo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerSaldo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerSaldo(data){
        // console.log("data successTraerSaldo:\n",data);
        if (data.estado_p === 200) {
            this.getField('saldo').setValue(this.currencyFormatterGeneral(data.data.xsaldo));
            this.getField('confirmModalCustom').toggle(false);
            this.habilitarBtnConcultar();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
    
    habilitarBtnConcultar(){
        if (this.getField('nit').getValue() !== '' && this.getField('sede_nit').getValue() !== '' && this.getField('nombre_nit').getValue() !== '' &&
        this.getField('nit').getValue() !== ' ' && this.getField('sede_nit').getValue() !== ' ' && this.getField('nombre_nit').getValue() !== ' ') {
            this.getField('consultar').setDisabled(false);
        }else{
            this.getField('consultar').setDisabled(true);
        }
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
}

FormJaivana.addController('comp-estadodecuenta', CustomEstadoDeCuenta);
export default CustomEstadoDeCuenta;