import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomActivosFijosBodegas
 * @author: Juan Camilo Villa Osorio
 * @version: jdesk_1.01.0002
 **/

class CustomActivosFijosBodegas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.chargeSelectCiudad = this.chargeSelectCiudad.bind(this);
        this.successResponsechargeSelectCiudad = this.successResponsechargeSelectCiudad.bind(this);

    }

    initForm() {
        console.log("Custom de prueba ");
        this.getField('departamento_geografico_id').setOnChange(this.chargeSelectCiudad);
    }

    chargeSelectCiudad() {
        let idCiudad = this.getField("departamento_geografico_id").getValue();
        console.log('va a entrar ', idCiudad);
        if (idCiudad !== '') {
            console.log('entro ', idCiudad);
            console.log('Prueba select', idCiudad);

            let datos = {
                datos: { departamento_geografico_id: parseInt(idCiudad) }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successResponsechargeSelectCiudad,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }

    }
    successResponsechargeSelectCiudad(data) {
        console.log('data Ciudad => ', data.data);
        if (data.estado_p === 200) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.ciudades_id;
                dataOp['text'] = item.nombre;
                /* dataOp['campos_cambian'] = { rack: item.id }; */
                opciones.push(dataOp);
            });
            this.getField('ciudad_id').setOptions(opciones);
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            } else {
                let respuesta = Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }

    }


}
FormJaivana.addController("serlog-activosfijosbodegas", CustomActivosFijosBodegas);
export default CustomActivosFijosBodegas;