import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomActivosFijosPolizas
 * @author: Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomActivosFijosPolizas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                        = this.initForm.bind(this);
        this.gridOptionsPolizas              = Object.assign({},this.gridOptions);
        this.cargarDatosPolizas              = this.cargarDatosPolizas.bind(this);
        this.successCargarDatosPolizas       = this.successCargarDatosPolizas.bind(this);
        this.habilitarCamposAgregar          = this.habilitarCamposAgregar.bind(this);
        this.successCrearDatosPoliza         = this.successCrearDatosPoliza.bind(this);
        this.cancelarDatosAgregar            = this.cancelarDatosAgregar.bind(this);
        this.setCrearButtonRejilla           = this.setCrearButtonRejilla.bind(this);
        this.validarDatosTercero             = this.validarDatosTercero.bind(this);
        this.successValidarTercero           = this.successValidarTercero.bind(this);
        this.confirmEliminar                 = this.confirmEliminar.bind(this);
        this.verArchivos                     = this.verArchivos.bind(this);
        this.DatosEliminarPoliza             = this.DatosEliminarPoliza.bind(this);
        this.successEliminarDataPoliza       = this.successEliminarDataPoliza.bind(this);
        this.openModalDatoDetalle            = this.openModalDatoDetalle.bind(this);
        this.validarFechaDesde               = this.validarFechaDesde.bind(this);
        this.validarFechaHasta               = this.validarFechaHasta.bind(this);
        this.validarUniques                 = this.validarUniques.bind(this);
        this.successValidarUniques          = this.successValidarUniques.bind(this);
        this.confirmarCancelarAgregar       = this.confirmarCancelarAgregar.bind(this);

        this.uploadFile                     = this.uploadFile.bind(this);
        this.ruta_imagen                    = "";
        this.estadoUF                       = "";
        this.EstadoEnviar                   = false;
        this.allowedExtensions              = /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
        this.archivoImagen1                 ="";


    }
    initForm(){
        console.log("Formulario CustomActivosFijosPolizas,  @version: jdesk_1.01.0001, @author:Jose Albeiro Marin Bernal");
        this.cargarDatosPolizas();
        this.getField("btn_agregar").setClick(this.habilitarCamposAgregar)
        this.getField("aceptar").setClick(this.validarDatosTercero);
        this.getField("cancelar").setClick(this.confirmarCancelarAgregar);
        this.getField("fecha_inicio_poliza").setOnChange(this.validarFechaDesde); 
        this.getField("fecha_final_poliza").setOnChange(this.validarFechaHasta);
        this.getField('imagen1_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen1 = props.target.files[0] : this.archivoImagen1 = '';});
        
    } 
    uploadFile(e){
        // console.log('Variable e de la función uploadFile:\n',e);
        // console.log('Variable e.target.id de la función uploadFile:\n',e.target.id.slice(0, -5));
        // let campo = e.target.id.slice(0, -5);
        if (e.target.files.length >= 1) {
            if (!this.allowedExtensions.exec(e.target.files[0].name)) {
                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png', 'error');
                // this.getField(`${campo}`).setValue('');
                this.EstadoEnviar = false;
            }else if (e.target.files[0].name.indexOf(' ') > 0) {
                this.alertGeneral.toggle(true, "La imagen (" + e.target.files[0].name + ") no puede contener espacios.", 'error');
                // this.getField(`${campo}`).setValue('');
                this.EstadoEnviar = false;
            }else{
                this.EstadoEnviar = true;
            }
        }     
    }

    cargarDatosPolizas(){
        let datos={ 
            datos: {}
        };

        this.generalFormatPmv = { tipo_servicio:  'maes-activosfijospolizas', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCargarDatosPolizas,
            error: this.error_,
            general: this.generalFormatPmv
        })
        
    }

    successCargarDatosPolizas(data){
        // console.log("polizas",data);
        if(data.estado_p===200) {
            this.getField("btn_agregar").setVisible(true)
            this.getField('rejillapoliza').toggle(true);
            let configCell = new Map();
            this.gridOptionsPolizas['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.setCrearButtonRejilla, sortable: false, filter: false, headerName: 'Accion' });
            this.getField('rejillapoliza').initData(this.gridOptionsPolizas, configCell);
        
            // ↓↓ Las siguientes dos líneas es para que los tres botones dentro de la columna "Acción" de la tabla, aparezcan todos ↓↓
            const allColumnIds = ["accion"];
            this.gridOptionsPolizas.columnApi.autoSizeColumns(allColumnIds, true);
        }
        if(data.estado_p===404) {
            this.getField("btn_agregar").setVisible(true)
        }
    }

    setCrearButtonRejilla(props){
        // console.log("dataIdProps",props)
        let fragment = document.createDocumentFragment();
        let activosfijospolizas_id = props.data.activosfijospolizas_id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.confirmEliminar(activosfijospolizas_id);
        button1.setAttribute("id", 'button_delete_' + activosfijospolizas_id);
        button1.setAttribute("type", "button");
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        let imagen1 = props.data.activosfijospolizas_id;
        button2.onclick = () => this.verArchivos(imagen1);
        button2.setAttribute("id", 'button_add_' + imagen1);
        button2.setAttribute("type", "button");
        button2.setAttribute("value", "Ver imagen");
        button2.setAttribute("class","buttonStyle2");

        //tercer boton de la rejilla
        let button3 = document.createElement("input");
        let detalle = props.data.detalle;
        button3.onclick = () => this.openModalDatoDetalle(detalle);
        button3.setAttribute("id", 'button_add_' + detalle);
        button3.setAttribute("type", "button");
        button3.setAttribute("value", "Ver detalle");
        button3.setAttribute("class","buttonStyle2");

                
        fragment.appendChild(button1);
        fragment.appendChild(button2);
        fragment.appendChild(button3);
        return this.createElementJaivana(fragment);

    }
    confirmEliminar(activosfijospolizas_id){

        let idEliminar = activosfijospolizas_id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').setClickDialog(()=>{
            this.DatosEliminarPoliza(idEliminar);
        });
        this.getField('confirmModalCustom').toggle(true);
    }
    DatosEliminarPoliza(idEliminar){
        this.getField('confirmModalCustom').toggle(false);

        let datos={ datos: {
            activosfijospolizas_id:idEliminar
        } };

        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijospolizas', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarDataPoliza,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    successEliminarDataPoliza(data){
        if (data.estado_p === 200) {
            this.cargarDatosPolizas();
            this.cancelarDatosAgregar();   
        }
    }
    openModalDatoDetalle(detalle){
        this.getField("modal_detalle").setLabel("Detalle poliza");
        this.getField('modal_detalle').handleClickOpen();
        // console.log("dataModal",detalle)
        this.getField("detalle_modal").setValue(detalle);    
         
    }

    verArchivos(imagen1){
        // console.log("props iamgenes",imagen1);
        // this.getField("modal_ver_archivos").setLabel("Archivos poliza");
        // this.getField('imagen_modal').setDisabled(true);
        // this.getField('modal_ver_archivos').handleClickOpen();
        // this.getField("imagen_modal").setValue(imagen1);
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos=`?body={"datos":{"activosfijospolizas_id":${imagen1}},"generales":{"tipo_servicio":"maes-activosfijospolizas","operacion":"traerimagenurl1","operacion_tipo":"consulta"}}`;
        fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
        })})
        .then(response => {
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                    });
                }
                }
            })
            }).then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false);
            })
            .catch(err => console.error(err));
            this.operacion="";
    }
    validarFechaDesde(){
        if (this.getField('fecha_inicio_poliza').getValue() !== '') {
            this.getField('fecha_inicio_poliza').setError(false, "");
            let fechaActual = new Date();
            let fechaCampo = new Date(this.getField('fecha_inicio_poliza').getValue());
            let fechaActualFormateada = fechaActual.toISOString().split('T')[0];
            let fechaCampoFormateada = fechaCampo.toISOString().split('T')[0];
            
            // Hacemos la validación dónde la fecha ingresada por el usuario sólo puede ser un día inferior al día de la fecha actual o el mismo día del mismo:
            // if ((fechaCampoFormateada <= fechaActualFormateada) && ((fechaActualFormateada.split('-')[0] - fechaCampoFormateada.split('-')[0]) <= 5 || (fechaCampoFormateada === fechaActualFormateada))) {
            if ((fechaCampoFormateada <= fechaActualFormateada) && ((fechaActualFormateada.replaceAll('-','') - fechaCampoFormateada.replaceAll('-','')) <= 50000 || (fechaCampoFormateada === fechaActualFormateada))) {
            this.validarFechaHasta();
                return true;
            }else{
                this.getField('fecha_inicio_poliza').setError(true, "¡La fecha debe ser igual o inferior a 5 años de la fecha actual!");
                return false;
            }
        }
    }

    validarFechaHasta(){
        if (this.getField('fecha_inicio_poliza').getValue() !== '' && this.getField('fecha_final_poliza').getValue() !== '') {
            let fechaInicial = new Date(this.getField('fecha_inicio_poliza').getValue()).toISOString().split('T')[0];
            let fechaFinal = new Date(this.getField('fecha_final_poliza').getValue()).toISOString().split('T')[0];
            if (fechaInicial >= fechaFinal) {
                this.getField('fecha_final_poliza').setError(true, "¡Fecha hasta, debe ser mayor a fecha desde!");
                return false;
            } else if (fechaFinal > fechaInicial) {
                this.getField('fecha_final_poliza').setError(false, "");
                return true;
            }
        }        
    }
    habilitarCamposAgregar(){
        this.getField("tercero").setVisible(true);
        this.getField("sede").setVisible(true);
        this.getField("nombre").setVisible(true);
        this.getField("numero_poliza").setVisible(true);
        this.getField("fecha_inicio_poliza").setVisible(true);
        this.getField("fecha_final_poliza").setVisible(true);
        this.getField("valor_poliza_con_impuestos").setVisible(true);
        this.getField("valor_impuestos").setVisible(true);
        this.getField("detalle").setVisible(true);
        this.getField("aceptar").setVisible(true);
        this.getField("cancelar").setVisible(true);
        this.getField("codigo_activo_fijo").setVisible(true);
        this.getField("descripcion_activo_fijo").setVisible(true);
        this.getField("imagen1_url").setVisible(true);
        this.getField("btn_agregar").setVisible(false);

        //setiar campos en vacio
        this.getField("tercero").setValue('');
        this.getField("sede").setValue('');
        this.getField("nombre").setValue('');
        this.getField("numero_poliza").setValue('');
        this.getField("valor_poliza_con_impuestos").setValue('');
        this.getField("valor_impuestos").setValue('');
        this.getField("detalle").setValue('');
        this.getField("codigo_activo_fijo").setValue('');
        this.getField("descripcion_activo_fijo").setValue('');
        this.getField("imagen1_url").setValue('');
        this.getField("fecha_inicio_poliza").setValue('');
        this.getField("fecha_final_poliza").setValue('');
        this.getField("imagen1_url").setValue('');
        //limpiarCamposErrores
        this.getField("tercero").setError(false,'');
        this.getField("sede").setError(false,'');
        this.getField("nombre").setError(false,'');
        this.getField("numero_poliza").setError(false,'');
        this.getField("fecha_inicio_poliza").setError(false,'');
        this.getField("fecha_final_poliza").setError(false,'');
        this.getField("valor_poliza_con_impuestos").setError(false,'');
        this.getField("valor_impuestos").setError(false,'');
        this.getField("detalle").setError(false,'');
        this.getField("codigo_activo_fijo").setError(false,'');
        this.getField("descripcion_activo_fijo").setError(false,'');
        this.getField("imagen1_url").setError(false,'');

    }

    confirmarCancelarAgregar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia','Desea realizar esta operación?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').setClickDialog(()=>{
            this.cancelarDatosAgregar();
        });
        this.getField('confirmModalCustom').toggle(true);  
    }

    cancelarDatosAgregar(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField("tercero").setVisible(false);
        this.getField("sede").setVisible(false);
        this.getField("nombre").setVisible(false);
        this.getField("numero_poliza").setVisible(false);
        this.getField("fecha_inicio_poliza").setVisible(false);
        this.getField("fecha_final_poliza").setVisible(false);
        this.getField("valor_poliza_con_impuestos").setVisible(false);
        this.getField("valor_impuestos").setVisible(false);
        this.getField("detalle").setVisible(false);
        this.getField("aceptar").setVisible(false);
        this.getField("cancelar").setVisible(false);   
        this.getField("codigo_activo_fijo").setVisible(false);
        this.getField("descripcion_activo_fijo").setVisible(false);
        this.getField("btn_agregar").setVisible(true);
        this.getField("imagen1_url").setVisible(false);
        //setiar campos en vacio
        this.getField("tercero").setValue('');
        this.getField("sede").setValue('');
        this.getField("nombre").setValue('');
        this.getField("numero_poliza").setValue('');
        this.getField("valor_poliza_con_impuestos").setValue('');
        this.getField("valor_impuestos").setValue('');
        this.getField("detalle").setValue('');
        this.getField("codigo_activo_fijo").setValue('');
        this.getField("descripcion_activo_fijo").setValue('');
        this.getField("imagen1_url").setValue('');
        this.getField("fecha_inicio_poliza").setValue('');
        this.getField("fecha_final_poliza").setValue('');
        this.getField("imagen1_url").setValue('');
        //limpiarCamposErrores
        this.getField("tercero").setError(false,'');
        this.getField("sede").setError(false,'');
        this.getField("nombre").setError(false,'');
        this.getField("numero_poliza").setError(false,'');
        this.getField("fecha_inicio_poliza").setError(false,'');
        this.getField("fecha_final_poliza").setError(false,'');
        this.getField("valor_poliza_con_impuestos").setError(false,'');
        this.getField("valor_impuestos").setError(false,'');
        this.getField("detalle").setError(false,'');
        this.getField("codigo_activo_fijo").setError(false,'');
        this.getField("descripcion_activo_fijo").setError(false,'');
        this.getField("imagen1_url").setError(false,'');
    }
    validarDatosTercero(){
        if(this.getField('tercero').valid() && this.getField('sede').valid() &&
        this.getField('nombre').valid()&& this.getField('numero_poliza').valid() && this.validarFechaDesde() && this.validarFechaHasta() &&
        this.getField('valor_poliza_con_impuestos').valid() && this.getField('valor_impuestos').valid() &&
        this.getField('detalle').valid() && this.getField('codigo_activo_fijo').valid() && this.getField('descripcion_activo_fijo').valid() &&
        this.getField('descripcion_activo_fijo').valid() &&
        this.getField('imagen1_url').valid()){
        let datos={ 
            datos: {
                terceros_id: this.getField("terceros_id").getValue()
            }
        };

        this.generalFormatPmv = { tipo_servicio:  'maes-activosfijospolizas', operacion: '21', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarTercero,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        })
       }
    }
    successValidarTercero(data){
        if (data.estado_p === 200 && data.data[0].cuantos === 0) {
            // console.log("dataTercero",data)    
            this.validarUniques();
        }else if(data.data[0].cuantos >= 1){
            this.alertGeneral.toggle(true, 'El tercero ingresado ya existe, no se permite duplicar', "error");
        }
    }

    validarUniques(){
        if(this.getField('tercero').valid() && this.getField('sede').valid() &&
        this.getField('nombre').valid()&& this.getField('numero_poliza').valid() &&
        this.getField('valor_impuestos').valid() && this.getField('valor_poliza_con_impuestos').valid()&&
        this.getField('codigo_activo_fijo').valid() && this.getField('detalle').valid()&&
        this.getField('descripcion_activo_fijo').valid() &&
        this.getField('imagen1_url').valid()){
        let datos={ 
            datos: {
                activosfijos_id: this.getField("activosfijos_id").getValue(),
                numero_poliza: this.getField("numero_poliza").getValue(),

            }
        };

        this.generalFormatPmv = { tipo_servicio:  'maes-activosfijospolizas', operacion: '20', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarUniques,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        })
       }   
    }

    successValidarUniques(data){        
        if (data.estado_p === 200) {
            // console.log("dataNumero",data)    
            this.alertGeneral.toggle(true, 'El código activo fijo y número poliza ya existe, no se permite duplicar', "error");
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true)
            // console.log("404 validar campos")
            const data = {
                _generales: { "tipo_servicio": 'maes-activosfijospolizas', "operacion": '5', "operacion_tipo": 'crear' },
                archivo: this.archivoImagen1,
                terceros_id:this.getField('terceros_id').getValue(),
                numero_poliza:this.getField('numero_poliza').getValue(),
                fecha_inicio_poliza:this.getField('fecha_inicio_poliza').getValue(),
                fecha_final_poliza:this.getField('fecha_final_poliza').getValue(),
                valor_poliza_con_impuestos:this.getField('valor_poliza_con_impuestos').getValue(),
                valor_impuestos:this.getField('valor_impuestos').getValue(),
                detalle:this.getField('detalle').getValue(),
                activosfijos_id:this.getField('activosfijos_id').getValue(),
            }
            this.generalFormatPmv = data._generales;
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: data,
                    success: this.successCrearDatosPoliza,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    formData: true
                }
            );
            // console.log('imagen1_url...:\n', this.getField('imagen1_url').getValue());
          }        
    }

    successCrearDatosPoliza(data){
        // console.log("crearDatos",data);
        if (data.estado_p ===200) {
            this.getField("btn_agregar").setVisible(true)    
            this.cargarDatosPolizas();
            this.cancelarDatosAgregar();        
        }
        this.getField('confirmModalCustom').toggle(false);
    }

}
FormJaivana.addController("maes-activosfijospolizas",CustomActivosFijosPolizas);
export default CustomActivosFijosPolizas