import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomGrabarDocumentoSoporte
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001.1
 **/
class CustomGrabarDocumentoSoporte extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.llenarcentrocosto              = this.llenarcentrocosto.bind(this);
        this.successcentroCosto             = this.successcentroCosto.bind(this);
        this.llenardocumento                = this.llenardocumento.bind(this);
        this.successDocumento               = this.successDocumento.bind(this);
        this.llenarformapago                = this.llenarformapago.bind(this);
        this.llenarcuenta                   = this.llenarcuenta.bind(this);
        this.successcuentas                 = this.successcuentas.bind(this);
        this.gridOptionsComponentes         = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes2        = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes3        = Object.assign({},this.gridOptions);
        this.crear_tabla                    = this.crear_tabla.bind(this);
        this.successcrear_tabla             = this.successcrear_tabla.bind(this);
        this.validarCentroCostos            = this.validarCentroCostos.bind(this);
        this.successvalidarcentrocosto      = this.successvalidarcentrocosto.bind(this);
        this.iniciarFecha                   = this.iniciarFecha.bind(this);
        this.successcreariniciarFecha       = this.successcreariniciarFecha.bind(this);
        this.validar                        = this.validar.bind(this);
        this.traer_deptointerno             = this.traer_deptointerno.bind(this);
        this.successDptoInterno             = this.successDptoInterno.bind(this);
        this.calcularvalores                = this.calcularvalores.bind(this);
        this.calcularvalores2                = this.calcularvalores2.bind(this);
        this.limpiar                        = this.limpiar.bind(this);
        this.limpiar_todo                   = this.limpiar_todo.bind(this);
        this.guardar                        = this.guardar.bind(this);
        this.successGuardar                 = this.successGuardar.bind(this);
        this.ver_contabilidad               = this.ver_contabilidad.bind(this);
        this.successver_contabilidad        = this.successver_contabilidad.bind(this);
        this.setButtonDeleteOpcion          = this.setButtonDeleteOpcion.bind(this);
        this.eliminarOpcion                 = this.eliminarOpcion.bind(this);
        this.confirmDeleteCustom            = this.confirmDeleteCustom.bind(this);
        this.successDelete                  = this.successDelete.bind(this);
        this.setButtonModificarOpcion       = this.setButtonModificarOpcion.bind(this);
        this.Modificar                      = this.Modificar.bind(this);
        this.guardar_factura                = this.guardar_factura.bind(this);
        this.successguardar_factura         = this.successguardar_factura.bind(this);
        this.successguardar_facelectronica  = this.successguardar_facelectronica.bind(this);
        this.lista_datos=[];
        this.registro_eliminar={};
        this.registro_actualizar={};
        this.dibujarTabla                   = this.dibujarTabla.bind(this);
        this.id_actualizar=0;
    }

    initForm() {
        console.log("Formulario CustomGrabarDocumentoSoporte,  @version: jdesk_1.01.0001.1, @author: Patricia Lopez Sanchez. ");

        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.llenarformapago();
        this.crear_tabla();
        //this.getField("fecha_desde").setDisabled(true);
        this.getField("agregar_bolsa").setDisabled(true);
        
        this.getField("valor_descuento").setDisabled(true);
        this.getField("valor_iva").setDisabled(true);
        this.getField("total").setDisabled(true);
        this.getField("valor_reteica").setDisabled(true);
        this.getField("valor_reteiva").setDisabled(true);
        this.getField("valor_retefuente").setDisabled(true);
        this.getField("retefuente").setDisabled(true);
        this.getField("reteica").setDisabled(true);
        this.getField("reteiva").setDisabled(true);
        this.getField("neto").setDisabled(true);
        this.getField("subtotal").setDisabled(true);
        this.getField("descuento").setDisabled(true);
        this.getField("impuestos").setDisabled(true);
        this.getField("total1").setDisabled(true);

        //deshabilitar porcentajes
        this.getField("porcentaje_decuento").setDisabled(true);
        this.getField("porcentaje_reteiva").setDisabled(true);
        this.getField("porcentaje_iva").setDisabled(true);
        this.getField("porcentaje_inc").setDisabled(true);
        this.getField("impuesto_nominal").setDisabled(true);
        this.getField("porcentaje_retefuente").setDisabled(true);
        this.getField("rete_ica").setDisabled(true);
        
        

        this.getField('centro_costo_codigo').setValue(this.codigo_sucursal);
        this.getField('centro_costo_nombre').setOnChange(this.iniciarFecha);
        this.getField('nombre_articulo').setOnChange(()=>{this.calcularvalores()});
        
        this.getField("fecha_desde").setOnChange(this.validar);
        this.getField("fecha_desde").setDisabled(true)
        this.getField("valor_uni").setOnBlur(this.calcularvalores2);
        this.getField("cantidad").setOnBlur(this.calcularvalores2);
        
        this.getField("porcentaje_decuento").setOnBlur(()=>this.calcularvalores2('otro'));
        this.getField("porcentaje_reteiva").setOnBlur(()=>this.calcularvalores2('txReteIVA'));
        this.getField("porcentaje_iva").setOnBlur(()=>this.calcularvalores2('txIVA'));
        this.getField("porcentaje_inc").setOnBlur(()=>this.calcularvalores2('txINC'));
        this.getField("impuesto_nominal").setOnBlur(()=>this.calcularvalores2('otro'));
        this.getField("porcentaje_retefuente").setOnBlur(()=>this.calcularvalores2('otro'));
        this.getField("rete_ica").setOnBlur(()=>this.calcularvalores2('otro'));

        this.getField('forma_pago').setOnBlur(this.llenarcuenta);
        this.getField('codigo_cuenta').setOnChange(()=>{this.getField('modal').handleClose();this.getField('codigo_cuenta').setError(false,'')});
        this.getField("cancelar").setClick(()=>{this.id_actualizar=0;this.limpiar()});
        this.getField("cancelar1").setClick(this.limpiar_todo);
        this.getField("Guardar").setClick(this.guardar);
        this.getField("ver_contabilidad").setClick(this.ver_contabilidad);
        this.getField("crear_producto").setClick(()=>{this.getField('crear_producto').handlerOpenForm(155,'Crear Productos','cont-articuloservicios','S',1);});
        this.getField("Guardar_factura").setClick(this.guardar_factura);

        this.getField('impuesto_nacional').setDisabled(true);
    }

    llenarformapago(){
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        let dataOp = {}
        dataOp['value'] = 'CR';
        dataOp['text'] = 'CREDITO';
        dataOp['campos_cambian'] = { };
        opciones.push(dataOp); 
        this.getField('forma_pago').setOptions(opciones);
    }

    iniciarFecha(){
        if(this.getField('centro_costo_nombre').getValue() !== ''){
            let datos = { datos: {centro_costo_codigo: this.getField('centro_costo_codigo').getValue()}};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcreariniciarFecha,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successcreariniciarFecha(data){
        if (data.estado_p === 200 )
        {
            this.fechaMinima = data.data[0].fecha_desde;
            this.fechaMaxima = data.data[0].fecha_hasta;

            this.validarCentroCostos();
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validar(){
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        if(fecha_desde < this.fechaMinima){
            this.getField("fecha_desde").setError(true,"No puede ser menor a la fecha "+this.fechaMinima+".");
            return false;
        }else if(this.fechaMaxima < fecha_desde){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha "+this.fechaMaxima+".");
            return false;
        }else{
            this.getField("fecha_desde").setError(false,"");
            return true;
        }
    }

    llenarcuenta(){
        if(this.getField('forma_pago').getValue() !== '' && this.getField('documento').getValue() !== ''){
            let datos = { datos: {
                documento: this.getField('id_documento').getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'traer_cuentas', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcuentas,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'No se encuentran cuentas porque el select documentos no tiene datos.', 'error');
            this.getField('codigo_cuenta').setError(true,'este campo es requerido.')
        }
    }

    successcuentas(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal').handleClickOpen();

            this.gridOptionsComponentes2['rowData'] = [];
            let configCell = new Map();
            configCell.set('id_cuenta', {hide: true});
            configCell.set('tipo_fuente', {hide: true});

            this.getField('rejilla2').toggle(true);
            this.gridOptionsComponentes2['rowData'] = data.data;
            this.getField('rejilla2').initData(this.gridOptionsComponentes2,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    crear_tabla(){
        let datos = { datos: {
            usuario: this.codigo_usuario
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'crear_tabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcrear_tabla,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successcrear_tabla(data){
        if (data.estado_p === 200)
        {
            this.alertGeneral.toggle(true, 'Acción exitosa.', 'success');
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Acción exitosa.', 'success');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validarCentroCostos(){
        let datos = { datos: {
            centro_costo_codigo: this.getField('centro_costo_codigo').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successvalidarcentrocosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successvalidarcentrocosto(data){
        if (data.estado_p === 200)
        {
            if(data.data[0].estado !== 'A') {
                this.alertGeneral.toggle(true, 'Centro de costos inactivo.', 'error');
            }else{
                this.llenarcentrocosto();
            }
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No se encuentra el centro de costos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }


    llenarcentrocosto(){
        if(this.getField('centro_costo_codigo').getValue() !== '' && this.getField('centro_costo_nombre').getValue() === ''){
            let datos = { datos: {
                value: this.getField('centro_costo_codigo').getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'maes-centroscosto', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcentroCosto,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if(this.getField('centro_costo_codigo').getValue() !== '' && this.getField('centro_costo_nombre').getValue() !== ''){
            this.llenardocumento();
        }
    }

    successcentroCosto(data){
        if (data.estado_p === 200 )
        {
            this.getField('centro_costo_nombre').setValue(data.data[0].nombre);
            this.llenardocumento();
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
    
    llenardocumento(){
        let datos = { datos: {
            centro_costo_codigo: this.getField('centro_costo_codigo').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDocumento,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }
    
    successDocumento(data){
        if (data.estado_p === 200 )
        {
            
            if(data.model && data.model.length>0){
                let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
                data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo_documento;
                dataOp['text'] = item.codigo_documento+" "+item.nombre;
                dataOp['campos_cambian'] = {id_documento:item.id, contabilizar:item.contabilizar, numero_resolucion:item.numero_resolucion, finicio:item.finicio,
                    ffinal:item.ffinal, prefijo_documento:item.prefijo_documento, ninicio:item.ninicio, nfinal:item.nfinal, llave_tecnica:item.llave_tecnica};
                opciones.push(dataOp); })
                this.getField('documento').setOptions(opciones);
            }
            this.traer_deptointerno();
        }
        else 
        {
          if(data.estado_p===404) {
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            this.getField('documento').setOptions(opciones);
            this.alertGeneral.toggle(true, 'No se encontro documentos relacionados con la sucursal '+this.getField('centro_costo_codigo').getValue(), 'error');
          }
          else {
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            this.getField('documento').setOptions(opciones);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traer_deptointerno(){
        let datos = { datos: {
            centro_costo_codigo: this.getField('centro_costo_codigo').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: '1_4', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDptoInterno,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successDptoInterno(data){
        if (data.estado_p === 200 )
        {
            this.getField('departamento_interno').setValue(data.data[0].valor);
            this.departamentoprincipal=data.data[0].valor;
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    calcularvalores(){
        if(this.getField('nombre_articulo').getValue() !== ''){
            this.registro_actualizar={};

            this.getField('porcentaje_decuento').setValue(0);
            this.getField('valor_descuento').setValue(0);
            this.getField('cantidad').setValue(1);
            

            if (this.getField("aplica_descuento").getValue() === "N") {
                this.getField('porcentaje_decuento').setDisabled(true);
                //this.getField('valor_descuento').setDisabled(false);
            } else {
                this.getField('porcentaje_decuento').setDisabled(false);
                //this.getField('valor_descuento').setDisabled(false);
                this.getField('valor_descuento').setValue((this.getField('valor_uni').getValue() * this.getField('cantidad').getValue() * this.getField('porcentaje_decuento').getValue()) / 100);
            }
            this.getField('total').setValue((this.getField('valor_uni').getValue() * this.getField('cantidad').getValue()) - this.getField('valor_descuento').getValue());

            if (this.getField('porcentaje_iva').getValue() === 0) {
                this.getField('porcentaje_iva').setDisabled(true);
                this.getField('porcentaje_reteiva').setDisabled(true);
                this.getField('porcentaje_reteiva').setValue(0);
                this.getField('valor_iva').setValue((this.getField('total').getValue() * this.getField('porcentaje_iva').getValue()) / 100);
                this.getField('valor_reteiva').setValue((this.getField('valor_iva').getValue() * this.getField('porcentaje_reteiva').getValue()) / 100);
            } else {
                this.getField('porcentaje_iva').setDisabled(false);
                this.getField('porcentaje_reteiva').setDisabled(false);
                this.getField('valor_iva').setValue((this.getField('total').getValue() * this.getField('porcentaje_iva').getValue()) / 100);
                this.getField('valor_reteiva').setValue((this.getField('valor_iva').getValue() * this.getField('porcentaje_reteiva').getValue()) / 100);
            }

            if (this.getField('porcentaje_inc').getValue() === 0) {
                this.getField('porcentaje_inc').setDisabled(true);        
                this.getField('impuesto_nacional').setValue((this.getField('total').getValue() * this.getField('porcentaje_inc').getValue()) / 100);
            } else {
                this.getField('porcentaje_inc').setDisabled(false);        
                this.getField('impuesto_nacional').setValue((this.getField('total').getValue() * this.getField('porcentaje_inc').getValue()) / 100);
            }

            //this.getField('impuesto_nominal').setValue(0);
            this.getField('impuesto_nominal').setDisabled(false);

            if (this.getField('porcentaje_retefuente').getValue() === 0) {
                this.getField('porcentaje_retefuente').setDisabled(true);         
                this.getField('valor_retefuente').setValue((this.getField('total').getValue() * this.getField('porcentaje_retefuente').getValue()) / 100);
            } else {
                this.getField('porcentaje_retefuente').setDisabled(false);         
                this.getField('valor_retefuente').setValue((this.getField('total').getValue() * this.getField('porcentaje_retefuente').getValue()) / 100);
            }

            if (this.getField('rete_ica').getValue() === 0) {
                this.getField('rete_ica').setDisabled(true);    
                this.getField('valor_reteica').setValue((this.getField('total').getValue() * this.getField('rete_ica').getValue()) / 100);
            } else {
                this.getField('rete_ica').setDisabled(false);    
                this.getField('valor_reteica').setValue((this.getField('total').getValue() * this.getField('rete_ica').getValue()) / 100);
            }
        }else{
            //deshabilitar porcentajes
            this.getField("porcentaje_decuento").setDisabled(true);
            this.getField("porcentaje_reteiva").setDisabled(true);
            this.getField("porcentaje_iva").setDisabled(true);
            this.getField("porcentaje_inc").setDisabled(true);
            this.getField("impuesto_nominal").setDisabled(true);
            this.getField("porcentaje_retefuente").setDisabled(true);
            this.getField("rete_ica").setDisabled(true);
            this.getField('cantidad').setValue('')
            this.getField('valor_uni').setValue('')
            this.getField('porcentaje_decuento').setValue('')
            this.getField('valor_descuento').setValue('')
            this.getField('porcentaje_iva').setValue('')
            this.getField('valor_iva').setValue('')
            this.getField('porcentaje_inc').setValue('')
            this.getField('impuesto_nacional').setValue('')
            this.getField('impuesto_nominal').setValue('')
            this.getField('total').setValue('')
            this.getField('rete_ica').setValue('')
            this.getField('valor_reteica').setValue('')
            this.getField('porcentaje_reteiva').setValue('')
            this.getField('valor_reteiva').setValue('')
            this.getField('porcentaje_retefuente').setValue('')
            this.getField('valor_retefuente').setValue('')
            this.getField('cantidad').setError(false,'')
            this.getField('valor_uni').setError(false,'')
            this.getField('porcentaje_decuento').setError(false,'')
            this.getField('valor_descuento').setError(false,'')
            this.getField('porcentaje_iva').setError(false,'')
            this.getField('valor_iva').setError(false,'')
            this.getField('porcentaje_inc').setError(false,'')
            this.getField('impuesto_nacional').setError(false,'')
            this.getField('impuesto_nominal').setError(false,'')
            this.getField('total').setError(false,'')
            this.getField('rete_ica').setError(false,'')
            this.getField('valor_reteica').setError(false,'')
            this.getField('porcentaje_reteiva').setError(false,'')
            this.getField('valor_reteiva').setError(false,'')
            this.getField('porcentaje_retefuente').setError(false,'')
            this.getField('valor_retefuente').setError(false,'')
        }
        
    }

    calcularvalores2(tx){
        if(this.getField('nombre_articulo').getValue() !== ''){
            let cantidad=Number(this.getField('cantidad').getValue());
            let x_iva=Number(this.getField('porcentaje_iva').getValue());
            let x_inc=Number(this.getField('porcentaje_inc').getValue());

            if (tx === 'txIVA') {
                if (this.getField('cantidad').getValue() > 0.0 && x_iva!==0.0) {

                    this.getField('porcentaje_inc').setValue(0);
                    this.getField('impuesto_nacional').setValue(0);
                } else {
                    this.getField('porcentaje_reteiva').setValue(0);
                    this.getField('valor_reteiva').setValue(0);
                }
            }
            if ((tx === 'txINC') && cantidad > 0.0 &&  x_inc!==0.0) {
                this.getField('porcentaje_reteiva').setValue(0);
                this.getField('valor_reteiva').setValue(0);
                this.getField('porcentaje_iva').setValue(0);
                this.getField('valor_iva').setValue(0);
            }
            if ((tx === 'txReteIVA') && cantidad > 0.0) {
                var iva = this.getField('valor_iva').getValue();
                if (iva === 0.0) {
                    this.getField('porcentaje_reteiva').setValue(0);
                    this.getField('valor_reteiva').setValue(0);
                }
            }


            this.getField('valor_descuento').setValue(0);
            if (this.getField("aplica_descuento").getValue() === "N") {
                this.getField('porcentaje_decuento').setDisabled(true);
                //this.getField('valor_descuento').setDisabled(false);
            } else {
                this.getField('porcentaje_decuento').setDisabled(false);
                //this.getField('valor_descuento').setDisabled(false);
                this.getField('valor_descuento').setValue((this.getField('valor_uni').getValue() * this.getField('cantidad').getValue() * this.getField('porcentaje_decuento').getValue()) / 100);
            }
            this.getField('total').setValue((this.getField('valor_uni').getValue() * this.getField('cantidad').getValue()) - this.getField('valor_descuento').getValue());

            
            
            this.getField('valor_iva').setValue((this.getField('total').getValue() * this.getField('porcentaje_iva').getValue()) / 100);
            this.getField('valor_reteiva').setValue((this.getField('valor_iva').getValue() * this.getField('porcentaje_reteiva').getValue()) / 100);
            this.getField('impuesto_nacional').setValue((this.getField('total').getValue() * this.getField('porcentaje_inc').getValue()) / 100);
            this.getField('impuesto_nominal').setDisabled(false);
            this.getField('valor_retefuente').setValue((this.getField('total').getValue() * this.getField('porcentaje_retefuente').getValue()) / 100);
            //.getField('porcentaje_retefuente').setDisabled(false);         
            this.getField('valor_reteica').setValue((this.getField('total').getValue() * this.getField('rete_ica').getValue()) / 100);
        
        
        
        }
        
    }

    guardar(){
        if(this.getField('codigo').valid() && this.getField('nombre_articulo').valid() && this.getField('cantidad').valid() 
        && this.getField('valor_uni').valid() && this.getField('impuesto_nominal').valid() && this.getField('porcentaje_retefuente').valid()
        && this.getField('centro_costo').valid() && this.getField('departamento_interno').valid()){
            let errores=0;
            this.getField('centro_costo').setError(false,'');
            this.getField('departamento_interno').setError(false,'');

            if(this.getField('id_centro_costo_aux').getValue()==='' || this.getField('id_centro_costo_aux').getValue()===' '){
                this.getField('centro_costo').setError(true,'Es necesario realizar la busquedad.');
                errores++;
            }
            if(this.getField('dpto_interno_aux').getValue()==='' || this.getField('dpto_interno_aux').getValue()===' '){
                this.getField('departamento_interno').setError(true,'Es necesario realizar la busquedad.');
                errores++;
            }
            if(errores===0){
                let datos = { datos: {
                    codigo: this.getField('codigo').getValue(),
                    nombre_articulo: this.getField('nombre_articulo').getValue(),
                    cantidad:this.getField('cantidad').getValue(),
                    valor_uni:this.getField('valor_uni').getValue(),
                    porcentaje_decuento:this.getField('porcentaje_decuento').getValue(),
                    valor_descuento:this.getField('valor_descuento').getValue(),
                    porcentaje_iva:this.getField('porcentaje_iva').getValue(),
                    valor_iva:this.getField('valor_iva').getValue(),
                    porcentaje_inc:this.getField('porcentaje_inc').getValue(),
                    impuesto_nacional:this.getField('impuesto_nacional').getValue(),
                    impuesto_nominal:this.getField('impuesto_nominal').getValue(),
                    porcentaje_retefuente:this.getField('porcentaje_retefuente').getValue(),
                    valor_retefuente:this.getField('valor_retefuente').getValue(),
                    porcentaje_reteiva:this.getField('porcentaje_reteiva').getValue(),
                    valor_reteiva:this.getField('valor_reteiva').getValue(),
                    rete_ica:this.getField('rete_ica').getValue(),
                    valor_reteica:this.getField('valor_reteica').getValue(),
                    total:this.getField('total').getValue(),
                    centro_costo:this.getField('centro_costo').getValue(),
                    departamento_interno:this.getField('departamento_interno').getValue(),
                    id:this.id_actualizar===0?"":this.id_actualizar,
                    usuario: this.codigo_usuario,
                }};
        
                this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'insertar', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGuardar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    dibujarTabla(){
        if(this.lista_datos.length > 0){
            this.limpiar();
            for (let i = 0; i < this.lista_datos.length; i++) {
                this.lista_datos[i]['modificar']='';
                this.lista_datos[i]['eliminar']='';
            }
            let subtotal = 0;
            let retefuente = 0;
            let total = 0;
            let reteica = 0;
            let reteiva = 0;
            let descuento = 0;
            let impuestos = 0;

            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            configCell.set('modificar', { cellRenderer: this.setButtonModificarOpcion, sortable: false, filter: false, field: 'modificar' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDeleteOpcion, sortable: false, filter: false, field: 'eliminar' });

            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = this.lista_datos;
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);


            for (let i = 0; i < this.lista_datos.length; i++) {
                subtotal += this.lista_datos[i].total;
                impuestos += this.lista_datos[i].ic_val + this.lista_datos[i].iva_val + this.lista_datos[i].inc_val;
                retefuente += this.lista_datos[i].rete_val;
                total += this.lista_datos[i].total;
                reteica += this.lista_datos[i].reteica_val;
                reteiva += this.lista_datos[i].reteiva_val;
                descuento += this.lista_datos[i].descuento_val;
            }
            
            this.getField('retefuente').setValue(retefuente);
            this.getField('reteica').setValue(reteica);
            this.getField('reteiva').setValue(reteiva);
            this.getField('neto').setValue((total + impuestos) - (retefuente + reteica + reteiva));
            this.getField('subtotal').setValue(subtotal + descuento);
            this.getField('descuento').setValue(descuento);
            this.getField('impuestos').setValue(impuestos);
            this.getField('total1').setValue(total + impuestos);
        }else{
            this.getField('rejilla').toggle(false);
            
            this.getField('retefuente').setValue(0);
            this.getField('reteica').setValue(0);
            this.getField('reteiva').setValue(0);
            this.getField('neto').setValue(0);
            this.getField('subtotal').setValue(0);
            this.getField('descuento').setValue(0);
            this.getField('impuestos').setValue(0);
            this.getField('total1').setValue(0);
        }
    }

    successGuardar(data){
        if (data.estado_p === 200 )
        {
            if(this.id_actualizar!==0){
                this.registro_actualizar={};
                this.id_actualizar=0;
            }
            this.lista_datos=data.data;
            this.dibujarTabla();
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            this.getField('rejilla').toggle(false);
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    ver_contabilidad(){
        if(this.getField('centro_costo_codigo').valid() && this.getField('nit').valid() && this.getField('sede').valid() 
        && this.getField('documento').valid() && this.getField('codigo_cuenta').valid()){
            let datos = { datos: {

                fecha_desde: this.getField('fecha_desde').getValue(),
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
                departamento_interno:this.departamentoprincipal,
                documento:this.getField('documento').getValue(),
                codigo_cuenta:this.getField('codigo_cuenta').getValue(),
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                sucursal:this.getField('centro_costo_codigo').getValue(),
                usuario: this.codigo_usuario
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'vercontabilidad', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successver_contabilidad,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successver_contabilidad(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal1').handleClickOpen();

            this.gridOptionsComponentes3['rowData'] = [];
            let configCell = new Map();
            this.getField('rejilla3').toggle(true);
            this.gridOptionsComponentes3['rowData'] = data.data;
            this.getField('rejilla3').initData(this.gridOptionsComponentes3,configCell);

        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
    setButtonDeleteOpcion(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarOpcion(props);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarOpcion(props) {
        this.registro_eliminar=props.data;
        this.id_delete = props.data.id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteCustom() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            usuario:'wras',
            id: this.id_delete
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'DELETE',
              body: datos,
              success: this.successDelete,
              error: this.error_,
              general: this.generalFormatPmv
        });
    }

    successDelete(data) {
        if (data.estado_p === 200 || data.estado_p === 404) {
            this.opciones=[];
            this.lista_datos=data.data;
            this.dibujarTabla();
            //this.successGuardar(data);
        }
    }

    setButtonModificarOpcion(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.Modificar(props);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Modificar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    Modificar(props) {
        this.id_actualizar=props.data.id;
        this.getField('codigo').setValue(props.data.codigo)
        this.getField('nombre_articulo').setValue(props.data.nombre)
        this.getField('cantidad').setValue(props.data.cantidad)
        this.getField('valor_uni').setValue(props.data.precio)
        this.getField('porcentaje_decuento').setValue(props.data.descuento_por)
        this.getField('valor_descuento').setValue(props.data.descuento_val)
        this.getField('porcentaje_iva').setValue(props.data.iva_por)
        this.getField('valor_iva').setValue(props.data.iva_val)
        this.getField('porcentaje_inc').setValue(props.data.inc_por)
        this.getField('impuesto_nacional').setValue(props.data.inc_val)
        this.getField('impuesto_nominal').setValue(props.data.ic_val)
        this.getField('total').setValue(props.data.total)
        this.getField('rete_ica').setValue(props.data.reteica_por)
        this.getField('valor_reteica').setValue(props.data.reteica_val)
        this.getField('porcentaje_reteiva').setValue(props.data.reteiva_por)
        this.getField('valor_reteiva').setValue(props.data.reteiva_val)
        this.getField('porcentaje_retefuente').setValue(props.data.rete_por)
        this.getField('valor_retefuente').setValue(props.data.rete_val)
    }

    guardar_factura(){
        if(this.getField('centro_costo_codigo').valid() && this.getField('nit').valid() && this.getField('sede').valid() 
        && this.getField('documento').valid() && this.getField('codigo_cuenta').valid()){

            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `); 
            this.getField('confirmModalCustom').setClickDialog(()=>{});    
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            let datos = { datos: {

                fecha_desde: this.getField('fecha_desde').getValue(),
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
                departamento_interno:this.departamentoprincipal,
                documento:this.getField('documento').getValue(),
                codigo_cuenta:this.getField('codigo_cuenta').getValue(),
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                sucursal:this.getField('centro_costo_codigo').getValue(),
                contabilizar:this.getField('contabilizar').getValue(),
                dias_vence:this.getField('dias_vence').getValue(),
                neto:this.getField('neto').getValue(),
                tipo_fuente:this.getField('tipo_fuente').getValue(),
                forma_pago:this.getField('forma_pago').getValue(),
                observaciones:this.getField('observaciones').getValue(),                
                numero_resolucion:this.getField('numero_resolucion').getValue(),                
                finicio:this.getField('finicio').getValue(),                
                ffinal:this.getField('ffinal').getValue(),                
                prefijo_documento:this.getField('prefijo_documento').getValue(),                
                ninicio:parseInt(this.getField('ninicio').getValue()),                
                nfinal:parseInt(this.getField('nfinal').getValue()),                
                llave_tecnica:this.getField('llave_tecnica').getValue(),                
                id_documento:this.getField('id_documento').getValue(),                
                id_cuenta:this.getField('id_cuenta').getValue(),                
                usuario: this.codigo_usuario
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'terminarFactura', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successguardar_factura,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successguardar_factura(data){
        if (data.estado_p === 200 )
        {
            let datos = { datos: {
                fecha_desde: this.getField('fecha_desde').getValue(),
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
                sucursal:this.getField('centro_costo_codigo').getValue(), 
                numero:data.data.numero,             
                usuario: this.codigo_usuario
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-grabardocumentosoporte', operacion: 'enviarDocumentoElectronico', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successguardar_facelectronica,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
        else 
        {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successguardar_facelectronica(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.limpiar_todo()
            let mensaje='1. El documento se guardó con éxito.';//data.data.mensaje_dian
            this.getField('confirmModalCustom').setTitleAndContent('Documento soporte', mensaje);
            this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)/*,this.limpiar_todo()*/});
            this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField('confirmModalCustom').toggle(true);
        }
        else 
        {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                this.getField('confirmModalCustom').setTitleAndContent('Error', respuesta);
                this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)/*,this.limpiar_todo()*/});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField('confirmModalCustom').toggle(true);
            }
            else if(data.estado_p===500) {
                let respuesta=Object.values(data.data.errores);
                this.getField('confirmModalCustom').setTitleAndContent('Error', respuesta);
                this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)/*,this.limpiar_todo()*/});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Error', "Error al enviar el documento a la DIAN.");
                this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)/*,this.limpiar_todo()*/});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField('confirmModalCustom').toggle(true);
            }
        }
    }

    limpiar(){
        this.getField('codigo').setValue('')
        this.getField('nombre_articulo').setValue('')
        this.getField('cantidad').setValue('')
        this.getField('valor_uni').setValue('')
        this.getField('porcentaje_decuento').setValue('')
        this.getField('valor_descuento').setValue('')
        this.getField('porcentaje_iva').setValue('')
        this.getField('valor_iva').setValue('')
        this.getField('porcentaje_inc').setValue('')
        this.getField('impuesto_nacional').setValue('')
        this.getField('impuesto_nominal').setValue('')
        this.getField('total').setValue('')
        this.getField('rete_ica').setValue('')
        this.getField('valor_reteica').setValue('')
        this.getField('porcentaje_reteiva').setValue('')
        this.getField('valor_reteiva').setValue('')
        this.getField('porcentaje_retefuente').setValue('')
        this.getField('valor_retefuente').setValue('')
        
        this.getField('codigo').setError(false,'')
        this.getField('nombre_articulo').setError(false,'')
        this.getField('cantidad').setError(false,'')
        this.getField('valor_uni').setError(false,'')
        this.getField('porcentaje_decuento').setError(false,'')
        this.getField('valor_descuento').setError(false,'')
        this.getField('porcentaje_iva').setError(false,'')
        this.getField('valor_iva').setError(false,'')
        this.getField('porcentaje_inc').setError(false,'')
        this.getField('impuesto_nacional').setError(false,'')
        this.getField('impuesto_nominal').setError(false,'')
        this.getField('total').setError(false,'')
        this.getField('rete_ica').setError(false,'')
        this.getField('valor_reteica').setError(false,'')
        this.getField('porcentaje_reteiva').setError(false,'')
        this.getField('valor_reteiva').setError(false,'')
        this.getField('porcentaje_retefuente').setError(false,'')
        this.getField('valor_retefuente').setError(false,'')
        this.getField('centro_costo').setError(false,'')
        this.getField('departamento_interno').setError(false,'')

        //deshabilitar porcentajes
        this.getField("porcentaje_decuento").setDisabled(true);
        this.getField("porcentaje_reteiva").setDisabled(true);
        this.getField("porcentaje_iva").setDisabled(true);
        this.getField("porcentaje_inc").setDisabled(true);
        this.getField("impuesto_nominal").setDisabled(true);
        this.getField("porcentaje_retefuente").setDisabled(true);
        this.getField("rete_ica").setDisabled(true);
    }

    limpiar_todo(){
        this.lista_datos=[];
        this.getField('rejilla').toggle(false);
        this.getField('centro_costo_codigo').setValue('')
        this.getField('centro_costo_nombre').setValue('')
        this.getField('nit').setValue('')
        this.getField('sede').setValue('')
        this.getField('nombre_nit').setValue('')
        this.getField('documento').setValue('')
        this.getField('forma_pago').setValue('')
        this.getField('codigo_cuenta').setValue('')

        this.getField('codigo').setValue('')
        this.getField('nombre_articulo').setValue('')
        this.getField('cantidad').setValue('')
        this.getField('valor_uni').setValue('')
        this.getField('porcentaje_decuento').setValue('')
        this.getField('valor_descuento').setValue('')
        this.getField('porcentaje_iva').setValue('')
        this.getField('valor_iva').setValue('')
        this.getField('porcentaje_inc').setValue('')
        this.getField('impuesto_nacional').setValue('')
        this.getField('impuesto_nominal').setValue('')
        this.getField('total').setValue('')
        this.getField('rete_ica').setValue('')
        this.getField('valor_reteica').setValue('')
        this.getField('porcentaje_reteiva').setValue('')
        this.getField('valor_reteiva').setValue('')
        this.getField('porcentaje_retefuente').setValue('')
        this.getField('valor_retefuente').setValue('')
        this.getField('centro_costo').setValue('')
        this.getField('departamento_interno').setValue('')
        this.getField('observaciones').setValue('')

        this.getField('retefuente').setValue('');
        this.getField('reteica').setValue('');
        this.getField('reteiva').setValue('');
        this.getField('neto').setValue('');
        this.getField('subtotal').setValue('');
        this.getField('descuento').setValue('');
        this.getField('impuestos').setValue('');
        this.getField('total1').setValue('');

        this.getField('centro_costo_codigo').setError(false,'')
        this.getField('centro_costo_nombre').setError(false,'')
        this.getField('nit').setError(false,'')
        this.getField('sede').setError(false,'')
        this.getField('nombre_nit').setError(false,'')
        this.getField('documento').setError(false,'')
        this.getField('forma_pago').setError(false,'')
        this.getField('codigo_cuenta').setError(false,'')

        this.getField('codigo').setError(false,'')
        this.getField('nombre_articulo').setError(false,'')
        this.getField('cantidad').setError(false,'')
        this.getField('valor_uni').setError(false,'')
        this.getField('porcentaje_decuento').setError(false,'')
        this.getField('valor_descuento').setError(false,'')
        this.getField('porcentaje_iva').setError(false,'')
        this.getField('valor_iva').setError(false,'')
        this.getField('porcentaje_inc').setError(false,'')
        this.getField('impuesto_nacional').setError(false,'')
        this.getField('impuesto_nominal').setError(false,'')
        this.getField('total').setError(false,'')
        this.getField('rete_ica').setError(false,'')
        this.getField('valor_reteica').setError(false,'')
        this.getField('porcentaje_reteiva').setError(false,'')
        this.getField('valor_reteiva').setError(false,'')
        this.getField('porcentaje_retefuente').setError(false,'')
        this.getField('valor_retefuente').setError(false,'')
        this.getField('centro_costo').setError(false,'')
        this.getField('departamento_interno').setError(false,'')

        this.getField('retefuente').setError(false,'');
        this.getField('reteica').setError(false,'');
        this.getField('reteiva').setError(false,'');
        this.getField('neto').setError(false,'');
        this.getField('subtotal').setError(false,'');
        this.getField('descuento').setError(false,'');
        this.getField('impuestos').setError(false,'');
        this.getField('total1').setError(false,'');

        this.initForm();
    }

}
FormJaivana.addController("cont-grabardocumentosoporte", CustomGrabarDocumentoSoporte);
export default CustomGrabarDocumentoSoporte;