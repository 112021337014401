import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomCodigosAlternos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        
        this.gridOptionsDireccionesClientes     = Object.assign({},this.gridOptions);
        this.arrayObjetos                       = new Map();
        this.arrayCampos                        = new Map();
        this.bHayErrores                        = false;
        this.bHayError                          = false;
        this.datosAgregar                       = { datos:{}};
        this.estadoMostrarCampos                = ""; 
        this.successDatosTabla                  = this.successDatosTabla.bind(this);
        this.CrearDatos                         = this.CrearDatos.bind(this);
        this.successCrearDatos                  = this.successCrearDatos.bind(this);
        this.setButtonBotonesTabla              = this.setButtonBotonesTabla.bind(this);
        this.nombreboton                        = this.nombreboton.bind(this);
        this.crearBotonTabla                    = this.crearBotonTabla.bind(this);
        this.accionProcesar                     = this.accionProcesar.bind(this);
        this.eliminarItemConfirmar              = this.eliminarItemConfirmar.bind(this);
        this.eliminarItem                       = this.eliminarItem.bind(this);
        this.successEliminarItem                = this.successEliminarItem.bind(this);
        this.Inactivar                          = this.Inactivar.bind(this);
        this.successActivarInactivar            = this.successActivarInactivar.bind(this);
        this.Activar                            = this.Activar.bind(this);
        this.successValidarDatos                = this.successValidarDatos.bind(this);
    }

    initForm(){
        console.log('Formulario CustomCodigosAlternos,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez')

        this.getField('seleccionar').setClick(()=>{this.estadoMostrarCampos = "Seleccionar"; this.validarBtnFrm('seleccionar');});
        this.getField('codigo_articulo').setOnChange(()=>{this.validarBtnFrm('ocultarTb');this.getField('seleccionar').setDisabled(false)});
        this.getField('agregar').setClick(()=>{this.validarBtnFrm('mostrarCampos');this.getField('agregar').setVisible(false)});
        this.operador();
        this.getField('cancelar').setClick(()=>{this.validarBtnFrm('cancelar');});
        this.getField('aceptar').setClick(()=>{this.validarBtnFrm('aceptar');});

    }

    operador(){
        let operar = [
            {
                operar:'*'
            },
            {
                operar:'/'
            }
        ]
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             operar.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.operar;
             dataOp['text'] = item.operar;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('factor_componente').setOptions(opciones);
    }

    validarBtnFrm(boton) {
        switch (boton) {
            case "seleccionar":
                this.getField('seleccionar').setDisabled(true);
                if (this.getField('codigo_articulo').valid() && this.getField('nombre3').valid()  && this.getField('articuloprincipal_id').valid()) {
                    let datos={ datos:{codigo_articulo: this.getField('codigo_articulo').getValue()}};
                    this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '20', operacion_tipo: 'consulta'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.successDatosTabla,
                            error: this.error_,
                            general: this.generalFormatPmv
                        }
                    );
                }
                break;

            case "ocultarTb":
                this.gridOptionsDireccionesClientes['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsDireccionesClientes);
                this.getField('rejilla').toggle(false);
                this.getField('label').setVisible(false);
                this.getField('label1').setVisible(false);
                this.getField('label2').setVisible(false);
                this.getField('label3').setVisible(false);
                this.getField('label4').setVisible(false);
                this.getField('codigo_componente').setVisible(false);
                this.getField('nombre1').setVisible(false);
                this.getField('cantidad_componente').setVisible(false);
                this.getField('factor_componente').setVisible(false);
                this.getField('costo_componente').setVisible(false);
                this.getField('aceptar').setVisible(false);
                this.getField('cancelar').setVisible(false);
                this.getField('agregar').setVisible(false);

                this.getField('codigo_componente').setValue('');
                this.getField('nombre1').setValue('');
                this.getField('cantidad_componente').setValue('');
                this.getField('factor_componente').setValue('');
                this.getField('costo_componente').setValue('');
                break;

            case "mostrarCampos":
                this.getField('label').setVisible(true);
                this.getField('label1').setVisible(true);
                this.getField('label2').setVisible(true);
                this.getField('label3').setVisible(true);
                this.getField('label4').setVisible(true);
                this.getField('codigo_componente').setVisible(true);
                this.getField('nombre1').setVisible(true);
                this.getField('cantidad_componente').setVisible(true);
                this.getField('factor_componente').setVisible(true);
                this.getField('costo_componente').setVisible(true);
                this.getField('aceptar').setVisible(true);
                this.getField('cancelar').setVisible(true);
                break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.validarBtnFrm('ocultarTb');
                    this.getField('codigo_articulo').setValue('');
                    this.getField('nombre3').setValue('');
                    this.getField('articuloprincipal_id').setValue('');
                    this.estadoMostrarCampos = "";
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "aceptar":
                if (this.getField('codigo_componente').valid() && this.getField('nombre1').valid()  && this.getField('cantidad_componente').valid()
                && this.getField('factor_componente').valid() && this.getField('costo_componente').valid() && this.getField('componentearticulo_id').valid()) {
                    if(this.getField('codigo_componente').getValue() !== this.getField('codigo_articulo').getValue()){
                        let datos={ datos:{
                            codigo_articulo: this.getField('codigo_articulo').getValue(),
                            codigo_componente: this.getField('codigo_componente').getValue()
                        }};
                        this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '20_1', operacion_tipo: 'consulta'};
                        this.service.send(
                            {
                                endpoint: this.constant.formConfiguration(),
                                method:'GET',
                                body: datos,
                                success: this.successValidarDatos,
                                error: this.error_,
                                general: this.generalFormatPmv
                            }
                        );
                    }else{
                        this.alertGeneral.toggle(true, 'El código componente no puede ser igual al código articulo.', 'error');
                    }
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    successValidarDatos(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            this.alertGeneral.toggle(true, 'La relación código artículo y código componente ya existen.', 'error');
        }else{
           if(data.estado_p===404) {
                this.CrearDatos();
           }
           else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    CrearDatos(){
        let datos={ datos:{
            codigo_articulo: this.getField('codigo_articulo').getValue(),
            codigo_componente: this.getField('codigo_componente').getValue(),
            cantidad_componente: this.getField('cantidad_componente').getValue(),
            factor_componente: this.getField('factor_componente').getValue(),
            costo_componente: this.getField('costo_componente').getValue(),
            estadoactivo_id: 1,
            articuloprincipal_id: this.getField('articuloprincipal_id').getValue(),
            componentearticulo_id: this.getField('componentearticulo_id').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '5', operacion_tipo: 'crear'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearDatos,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successCrearDatos(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            this.validarBtnFrm('ocultarTb');
            this.validarBtnFrm('seleccionar');
        }else{
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    successDatosTabla(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            this.getField('rejilla').toggle(true);
    
            let datos=[]
            data.data.forEach(element => {
            let estado = "";
            if(element.estadoactivo_id===1) {  
                estado="Activo";
            }
            else{
                estado="Inactivo"; 
            } 
            let arreglo=
              {
                componente: element.codigo_componente,
                nombre_componente: element.nombre,
                cantidad: element.cantidad_componente,
                operador: element.factor_componente,
                estado: estado,
                codigoalterno_id: element.id
            };
            
              datos.push(arreglo);
            });
            let configCell = new Map();
            this.gridOptionsDireccionesClientes['rowData'] = datos;
            configCell.set('accion', {cellRenderer:this.setButtonBotonesTabla, headerName: 'Acción', sortable: false, filter: false/* , width:400 */});
            this.getField('rejilla').initData(this.gridOptionsDireccionesClientes, configCell);
            // ↓↓ Las siguientes dos líneas es para que los tres botones dentro de la columna "Acción" de la tabla, aparezcan todos ↓↓
            const allColumnIds = ["accion"];
            this.gridOptionsDireccionesClientes.columnApi.autoSizeColumns(allColumnIds, true);
            this.getField('agregar').setVisible(true);
        }else{
            if (this.estadoMostrarCampos === 'Eliminar'){
                this.estadoMostrarCampos = "";
                this.validarBtnFrm('ocultarTb');
            }else if (this.estadoMostrarCampos === 'Seleccionar'){
                this.validarBtnFrm('mostrarCampos');
            }
        }
    }

    setButtonBotonesTabla(props){
        let fragment = document.createDocumentFragment();
        let buttonActivarInactivar = this.crearBotonTabla(props.data, `${this.nombreboton(props)}`);
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        buttonActivarInactivar.setAttribute("class","buttonStyle2");
        buttonEliminar.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonActivarInactivar);
        fragment.appendChild(buttonEliminar);
        return this.createElementJaivana(fragment);
    }

    nombreboton(props){
        if(props.data.estado === 'Activo'){
            return "Inactivar"
        }else if(props.data.estado === 'Inactivo'){
            return "Activar"
        }
    }

    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Inactivar':
                button.setAttribute("id", `buttonActivarInactivar_${data.codigoalterno_id}`);
                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.codigoalterno_id}`);
                break;
            default:
                break;
        }
        button.onclick = () => {this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    accionProcesar(data, boton) {
        if (boton === 'Eliminar') {
            this.eliminarItemConfirmar(data, 'Eliminar');
        }else if (boton === 'Inactivar'){
            this.Inactivar(data, 'Inactivar');
        }else if (boton === 'Activar'){
            this.Activar(data, 'Activar');
        }
    }

    eliminarItemConfirmar(data, boton) {
        // console.log('Este es el data de eliminar:\n',data);
        this.getField('confirmModalCustom').setTitleAndContent(`${boton}`, '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => {this.eliminarItem(data.codigoalterno_id)});
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`);
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarItem(codigoalterno_id) {
        this.getField('confirmModalCustom').toggle(false);
        this.estadoMostrarCampos = "Eliminar";
        if (codigoalterno_id !== "") {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['codigoalterno_id']= codigoalterno_id;
            this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '7', operacion_tipo: 'eliminar'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: this.datosAgregar,
                    success: this.successEliminarItem,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successEliminarItem(data){
        if (data.estado_p === 200) {
            this.getField('label').setVisible(false);
            this.getField('label1').setVisible(false);
            this.getField('label2').setVisible(false);
            this.getField('label3').setVisible(false);
            this.getField('label4').setVisible(false);
            this.getField('codigo_componente').setVisible(false);
            this.getField('nombre1').setVisible(false);
            this.getField('cantidad_componente').setVisible(false);
            this.getField('factor_componente').setVisible(false);
            this.getField('costo_componente').setVisible(false);
            this.getField('aceptar').setVisible(false);
            this.getField('cancelar').setVisible(false);
            this.getField('codigo_componente').setValue('');
            this.getField('nombre1').setValue('');
            this.getField('cantidad_componente').setValue('');
            this.getField('factor_componente').setValue('');
            this.getField('costo_componente').setValue('');
            this.validarBtnFrm('seleccionar');
        }else 
        {
           if(data.estado_p===404) {
                //this.getField('TabJaivana_90').setNextTab();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    Inactivar(codigoalterno_id){
        if (codigoalterno_id !== "") {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['codigoalterno_id']= codigoalterno_id.codigoalterno_id;
            this.datosAgregar.datos['estadoactivo_id']= 2;
            this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '6', operacion_tipo: 'modificar'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: this.datosAgregar,
                    success: this.successActivarInactivar,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    Activar(codigoalterno_id){
        if (codigoalterno_id !== "") {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['codigoalterno_id']= codigoalterno_id.codigoalterno_id;
            this.datosAgregar.datos['estadoactivo_id']= 1;
            this.generalFormatPmv = { tipo_servicio: 'maes-codigosalternos', operacion: '6', operacion_tipo: 'modificar'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: this.datosAgregar,
                    success: this.successActivarInactivar,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successActivarInactivar(data){
        if (data.estado_p === 200) {
            this.getField('label').setVisible(false);
            this.getField('label1').setVisible(false);
            this.getField('label2').setVisible(false);
            this.getField('label3').setVisible(false);
            this.getField('label4').setVisible(false);
            this.getField('codigo_componente').setVisible(false);
            this.getField('nombre1').setVisible(false);
            this.getField('cantidad_componente').setVisible(false);
            this.getField('factor_componente').setVisible(false);
            this.getField('costo_componente').setVisible(false);
            this.getField('aceptar').setVisible(false);
            this.getField('cancelar').setVisible(false);
            this.getField('codigo_componente').setValue('');
            this.getField('nombre1').setValue('');
            this.getField('cantidad_componente').setValue('');
            this.getField('factor_componente').setValue('');
            this.getField('costo_componente').setValue('');
            this.validarBtnFrm('seleccionar');
        }else 
        {
           if(data.estado_p===404) {
                //this.getField('TabJaivana_90').setNextTab();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }


}
FormJaivana.addController("maes-codigosalternos",CustomCodigosAlternos);
export default CustomCodigosAlternos