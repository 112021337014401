/**
 * Archivo para almacenar constantes del proyecto jaivana_pmv_cliente_web,
 * Estas constant no afectan las constantes de dashboard_jaivana
 */



class Constant {
    static params =
    {
      loginBase: '13-login_jdesk',//Se envia solo si se va a conectar el proyecto jaivana_pmv
      baseUrl:  'https://multicash.almacenparis.jaivanaweb.co',//'http://development.jaivana.co/',//'https://pintunal.jaivana.co',//'http://54.210.32.77',//'pruebas.jaivanaweb.co',//'https://almacen.ferricentro.com',
      keyEmpresa: 'a1b1c1d1'
    }
    constructor(){
        this.getParams  =   this.getParams.bind(this);
        this.setParams  =   this.setParams.bind(this);
    }

    getParams = () => {
        return Constant.params;
    }

    setParams = (params,value) => {
        if(params && value)
            Constant.params[params] =   value;
    }
}
// eslint-disable-next-line
export default new Constant()