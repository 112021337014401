import FormJaivana from "dashboard_jaivana_v1";

/**
 * @description: CustomAsignarOrdenBodegas
 * @author: Mario Alejandro Sepulveda Rojas
 * @version: jdesk_1.01.0001
 **/

class CustomAsignarBodegas extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayObjetos = new Map();
        this.arrayCampos = new Map();
        this.bHayErrores = false;

        this.gridOptionsOrdenBodegas = Object.assign({}, this.gridOptions);
        this.setButtonAccion = this.setButtonAccion.bind(this);
        this.consultarTablaBodegas = this.consultarTablaBodegas.bind(this);
        this.successConsultarTablaBodegas = this.successConsultarTablaBodegas.bind(this);
        this.crearBotonTabla = this.crearBotonTabla.bind(this);

        this.successActualizarOrden = this.successActualizarOrden.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAsignarOrdenBodegas,  @version: jdesk_1.01.0001, @author:Mario Alejandro Sepúlveda");

        this.arrayCampos.set(1, 'bodegas_id');
        this.arrayCampos.set(2, 'rejilla');
        this.arrayCampos.set(3, 'orden');

        this.arrayObjetos.set(1, 'bodegas_id');
        this.arrayObjetos.set(2, 'rejilla');
        this.arrayObjetos.set(3, 'orden');

        this.consultarTablaBodegas();

    }

    consultarTablaBodegas() {
        let datos = {
            datos: {}
        };

        this.generalFormatPmv = { tipo_servicio: 'maes-bodegasasignarorden', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successConsultarTablaBodegas,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successConsultarTablaBodegas(data) {
        if (data.estado_p === 200) {
            //console.log("succes datos bodegas:", data);

            let obj = {
                bodegas_id:0,
                codigo:"",
                nombre:"",
                orden:"",
            }

            for(let i=0;i<data.data.length;i++){
                if(i===0){
                    obj={
                        bodegas_id:data.data[i].bodegas_id,
                        codigo:data.data[i].codigo,
                        nombre:data.data[i].nombre,
                        orden:data.data[i].orden,
                        subir:false,
                        bajar:true
                    }
                    data.data[i] = obj;
                }else if(i === data.data.length-1){
                    obj={
                        bodegas_id:data.data[i].bodegas_id,
                        codigo:data.data[i].codigo,
                        nombre:data.data[i].nombre,
                        orden:data.data[i].orden,
                        subir:true,
                        bajar:false
                    }
                    data.data[i] = obj;
                }else{
                    obj={
                        bodegas_id:data.data[i].bodegas_id,
                        codigo:data.data[i].codigo,
                        nombre:data.data[i].nombre,
                        orden:data.data[i].orden,
                        subir:true,
                        bajar:true
                    }
                    data.data[i] = obj; 
                }
            };
            //console.log("nuevoArray",data.data);

            let configCell = new Map();
            configCell.set('codigo', { width: 140, field:'codigo' });
            configCell.set('orden', {  width: 140, field:'orden' });
            configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 200, sortable: false, filter: false, field: 'accion' });

            
            this.gridOptionsOrdenBodegas['rowData'] = data.data;
            this.getField(this.arrayCampos.get(2)).initData(this.gridOptionsOrdenBodegas, configCell);
/* 
            this.gridOptionsOrdenBodegas.api.sizeColumnsToFit();

             const allColumnIds = ["nombre","accion"];
            this.gridOptionsOrdenBodegas.columnApi.autoSizeColumns(allColumnIds,true); */
        }
    }

    setButtonAccion(props) {
        let fragment = document.createDocumentFragment();
        let buttonSubir = this.crearBotonTabla(props.data, 'Subir ↑');
        let buttonBajar = this.crearBotonTabla(props.data, 'Bajar ↓');
        
        //console.log("props con data:",props);

        if(!props.data.subir){
            buttonSubir.setAttribute("class","buttonDisabled");

            //buttonSubir.setAttribute("class","buttonDisabled");
            buttonSubir.setAttribute("disabled",true);
            buttonBajar.setAttribute("class","buttonStyle2");

        } else if(!props.data.bajar){
            buttonBajar.setAttribute("class","buttonDisabled");
            buttonBajar.setAttribute("disabled",true);
            buttonSubir.setAttribute("class","buttonStyle2");

        } else {
            buttonSubir.setAttribute("class","buttonStyle2");
            buttonBajar.setAttribute("class","buttonStyle2");
        }
       

        fragment.appendChild(buttonSubir);
        fragment.appendChild(buttonBajar);
        return this.createElementJaivana(fragment);//↓↑
    }

    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Subir ↑':
                button.setAttribute("id", `buttonSubir_${data.bodegas_id}`);
                break;
            case 'Bajar ↓':
                button.setAttribute("id", `buttonBajar${data.bodegas_id}`);
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    accionProcesar(data, boton){
        this.getField(this.arrayCampos.get(1)).setValue(data.bodegas_id);
        this.getField(this.arrayCampos.get(3)).setValue(data.orden);

        if(boton === 'Subir ↑'){


            let datos = {
                datos: {
                    bodegas_id:data.bodegas_id,
                    orden:data.orden+1
                }
            };
            //console.log("entro subir",data.bodegas_id,data.orden);

            this.generalFormatPmv = { tipo_servicio: 'maes-bodegasasignarorden', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successActualizarOrden,
                    error: this.error_,
                    general: this.generalFormatPmv
                }); 

        }else if(boton === 'Bajar ↓'){
            let datos = {
                datos: {
                    bodegas_id:data.bodegas_id,
                    orden:data.orden-1
                }
            };
            //console.log("entro a bajar",data.bodegas_id,data.orden);

            this.generalFormatPmv = { tipo_servicio: 'maes-bodegasasignarorden', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successActualizarOrden,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successActualizarOrden(data){
        if(data.estado_p === 200){
            //console.log("datos del success actualizar",data);
            this.consultarTablaBodegas();
        }
    }

    //*** fin  los botones y los success


    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }

    cargarCampos(data) {
        this.getField('bodegas_id').setValue(data.data[0].bodegas_id);
        this.getField('rejilla').setValue(data.data[0].rejilla);
        this.getField('orden').setValue(data.data[0].orden);
    }

}

FormJaivana.addController("maes-bodegasasignarorden", CustomAsignarBodegas);
export default CustomAsignarBodegas;