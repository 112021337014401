import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomTarjetaKardexCostoPromedio
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomTarjetaKardexCostoPromedio extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.creartemporal                                          = this.creartemporal.bind(this);
        this.success_creartemporal                                  = this.success_creartemporal.bind(this);
        this.calcularsaldo                                          = this.calcularsaldo.bind(this);
        this.success_calcularsaldo                                  = this.success_calcularsaldo.bind(this);
        this.realizarKardex                                         = this.realizarKardex.bind(this);
        this.success_realizarKardex                                 = this.success_realizarKardex.bind(this);
        this.busqueda_usuario                                       = false;
        this.mostrar                                                = this.mostrar.bind(this);
        this.success_mostrar                                        = this.success_mostrar.bind(this);
        this.gridOptionsComponentes1                                = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1['onSelectionChanged']          = this.onSelectionChanged;
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.validarfechasConsulta                                  = this.validarfechasConsulta.bind(this);
        this.limpiar                                                = this.limpiar.bind(this);
        this.traerPropiedades                                       = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades                                 = this.sucesstraerPropiedades.bind(this);
    }

    initForm() {
        console.log("Formulario CustomTarjetaKardexCostoPromedio,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.traerPropiedades();
        this.getField("desde").setValue(this.fecha_inicio);
        this.getField("desde").setOnChange(this.validarfechasConsulta);
        this.getField("hasta").setOnChange(this.validarfechasConsulta);
        this.creartemporal();
        this.getField("nombre_articulo").setOnChange(this.limpiar);
        this.getField("nombre_bodega").setOnChange(this.calcularsaldo);
        this.getField("procesar").setClick(()=>{this.busqueda_usuario = false; this.realizarKardex()});
        this.getField("procesar_usuario").setClick(()=>{this.busqueda_usuario = true; this.realizarKardex()});
    }

    validarfechasConsulta(){
        this.limpiar();
        let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];

        if(consultar_desde > consultar_hasta){
            this.getField('procesar').setDisabled(true);
            this.getField('procesar_usuario').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }else{
            this.getField('procesar').setDisabled(false);
            this.getField('procesar_usuario').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }

        if(consultar_hasta > fecha_actual){
            this.getField('procesar').setDisabled(true);
            this.getField('procesar_usuario').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }else{
            this.getField('procesar').setDisabled(false);
            this.getField('procesar_usuario').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }
        
        let diferencia=new Date(consultar_hasta).getTime()-new Date(consultar_desde).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            this.getField('procesar_usuario').setDisabled(true); 
            return false;
        }else{
            this.getField('procesar').setDisabled(false);
            this.getField("desde").setError(false,"");
        }
        return true;

    }

    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-consultarordenproduccion', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
        }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    limpiar(){
        this.getField('procesar').setDisabled(true);
        this.getField('procesar_usuario').setDisabled(true);
        this.gridOptionsComponentes1['rowData'] = [];            
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsComponentes1,configCell);
        this.getField('rejilla').toggle(false)
    }
 
    onSelectionChanged(){
        //nothing
    }

    creartemporal(){
        let datos={ datos: {
            usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-kardexcostopromedio', operacion: 'crear_tmp', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_creartemporal,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    success_creartemporal(data){
        if(data.estado_p===502) {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }

    realizarKardex(){
        let errores=0;
        if(this.validarfechasConsulta()===false)
        errores++;
        if (errores===0 && this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid() && this.getField('bodega').valid() && 
        this.getField('nombre_bodega').valid() && this.getField('saldo').valid()) {
            let datos={ datos: {
                usuario:this.codigo_usuario,
                busqueda_usuario: this.busqueda_usuario,
                bodega: this.getField("bodega").getValue(),
                desde: this.getField("desde").getValue(),
                hasta: this.getField("hasta").getValue(),
                codigo_articulo: this.getField("codigo_articulo").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-kardexcostopromedio', operacion: 'realizarKardex', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.success_realizarKardex,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    success_realizarKardex(data){
        if (data.estado_p === 200 )
        {
            this.mostrar();
            //esperar 
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    calcularsaldo(){
        this.limpiar();
        if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid() && this.getField('bodega').valid() && 
        this.getField('nombre_bodega').valid()){
            let datos={ datos: {
                bodega: this.getField("bodega").getValue(),
                codigo_articulo: this.getField("codigo_articulo").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-kardexcostopromedio', operacion: 'calcularSaldo', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.success_calcularsaldo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    success_calcularsaldo(data){
        if (data.estado_p === 200 )
        {
            this.getField("saldo").setValue(data.data.saldo)
            //esperar 
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    mostrar(){
        let datos={ datos: {
            usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-kardexcostopromedio', operacion: 'mostrar', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_mostrar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    success_mostrar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data;
            this.items = data.data;
            configCell.set('costo_unitario', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_unitario) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costopro_antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_antes) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo_antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_antes) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costopro_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_despues) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_despues) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', { cellRenderer: function (props) { return props.data.cantidad}, cellStyle: { textAlign: "right"}, field: 'cantidad' });
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

}
FormJaivana.addController("inve-kardexcostopromedio", CustomTarjetaKardexCostoPromedio);
export default CustomTarjetaKardexCostoPromedio;