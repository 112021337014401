/**
 * @description Custom con funciones adicionales al formulario con código: maes-facturaciones, ID Formulario: 79, Sección: Sección: padre: Maestros(id: 1)- hijo: Empresa(id: 8)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomFacturaciones extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsConsecutivoFacturas        =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                          =   new Map();
        this.arrayCampos                           =   new Map();
        this.bHayErrores                           =   false;
        this.bHayError                             =   false;
        this.datosAgregar                          =   { datos:{}};
       
        // Funciones:
        this.initForm                              =   this.initForm.bind(this);
        this.validarBtnFrm                         =   this.validarBtnFrm.bind(this);
        this.dibujarTbConsecutivoFacturas          =   this.dibujarTbConsecutivoFacturas.bind(this);
        this.successTbConsecutivoFacturas          =   this.successTbConsecutivoFacturas.bind(this);
        this.opcionResolucionFacturaciones         =   this.opcionResolucionFacturaciones.bind(this);
        this.opcionConsultarNumeracion             =   this.opcionConsultarNumeracion.bind(this);
        this.setButtonTbConsecutivoFacturas        =   this.setButtonTbConsecutivoFacturas.bind(this);
        this.crearBotonTabla                       =   this.crearBotonTabla.bind(this);
        this.accionProcesar                        =   this.accionProcesar.bind(this);
        this.successActualizarConsecutivoFacturas  =   this.successActualizarConsecutivoFacturas.bind(this);

        this.manejaFacturacionElectronica        =   this.manejaFacturacionElectronica.bind(this);
        this.successManejaFacturaElectronica     =   this.successManejaFacturaElectronica.bind(this);
        this.consultarUrl                        =   this.consultarUrl.bind(this);
        this.successTraerUrl                     =   this.successTraerUrl.bind(this);
        this.successTraerValor                   =   this.successTraerValor.bind(this);
        this.sucursal = "";
        this.xvalor = "";
    }

    initForm() {

        console.log('Formulario CustomFacturaciones,  @version: jdesk_1.01.0001, @author: Sebastian Rios Echeverri');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'tb_consecutivofacturas');
        this.arrayCampos.set(3, 'codigo_facturacion');
        this.arrayCampos.set(4, 'prefijo_factura');
        this.arrayCampos.set(5, 'numero_factura');
        this.arrayCampos.set(6, 'finicio');
        this.arrayCampos.set(7, 'ffinal');
        this.arrayCampos.set(8, 'ninicio');
        this.arrayCampos.set(9, 'nfinal');
        this.arrayCampos.set(10, 'resolucion');
        this.arrayCampos.set(11, 'llave_tecnica');
        this.arrayCampos.set(12, 'rete_ica');
        this.arrayCampos.set(13, 'tipo_formato');
        this.arrayCampos.set(14, 'archivo_formato');
        this.arrayCampos.set(15, 'minimo');
        this.arrayCampos.set(16, 'email');
        this.arrayCampos.set(17, '.');
        this.arrayCampos.set(18, 'btn_aceptar');
        this.arrayCampos.set(19, 'btn_cancelar');
        this.arrayCampos.set(20, 'estadoactivo_id');
        this.arrayCampos.set(24, 'estado');
        this.arrayCampos.set(50, '.');
        this.arrayCampos.set(51, '.');

        this.arrayCampos.set(21,'btn_consultar');
        this.arrayCampos.set(22,'campo_url');
        this.arrayCampos.set(23,'sucursal');


        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'tb_consecutivofacturas');
        this.arrayObjetos.set(3, 'codigo_facturacion');
        this.arrayObjetos.set(4, 'prefijo_factura');
        this.arrayObjetos.set(5, 'numero_factura');
        this.arrayObjetos.set(6, 'finicio');
        this.arrayObjetos.set(7, 'ffinal');
        this.arrayObjetos.set(8, 'ninicio');
        this.arrayObjetos.set(9, 'nfinal');
        this.arrayObjetos.set(10, 'resolucion');
        this.arrayObjetos.set(11, 'llave_tecnica');
        this.arrayObjetos.set(12, 'rete_ica');
        this.arrayObjetos.set(13, 'tipo_formato');
        this.arrayObjetos.set(14, 'archivo_formato');
        this.arrayObjetos.set(15, 'minimo');
        this.arrayObjetos.set(16, 'email');
        this.arrayObjetos.set(17, '.');
        this.arrayObjetos.set(18, 'btn_aceptar');
        this.arrayObjetos.set(19, 'btn_cancelar');
        this.arrayObjetos.set(20, 'estadoactivo_id');
        this.arrayObjetos.set(24, 'estado');
        this.arrayObjetos.set(50, '.');
        this.arrayObjetos.set(51, '.');

        this.arrayObjetos.set(21,'btn_consultar');
        this.arrayObjetos.set(22,'campo_url');
        this.arrayObjetos.set(23,'sucursal');

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionResolucionFacturaciones();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionConsultarNumeracion();
        }
    }


    //*** definir los botones y los success
    successActualizarConsecutivoFacturas(data){
        this.getField('btn_aceptar').setDisabled(false);
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20','visible','false');
            this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,20','value,error','false');
            this.dibujarTbConsecutivoFacturas();
        }
    }
    
    opcionResolucionFacturaciones(){
        this.dibujarTbConsecutivoFacturas();
        let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
        let data2 = {
            "data": [
                {
                    "valor": "T",
                    "text": "Texto"
                },
                {
                    "valor": "P",
                    "text": "Presentación"
                }
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.valor;
            dataOp['text'] = item.text;
            dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
            opciones.push(dataOp);
        });
        this.getField('tipo_formato').setOptions(opciones);
        this.getField('btn_cancelar').setClick(()=>{this.validarBtnFrm('cancelar');});
        this.getField('minimo').setKeyUp(()=>{this.validarBtnFrm('validarMinimo');});
        this.getField('ninicio').setKeyUp(()=>{this.validarBtnFrm('validarMinimo');});
        this.getField('nfinal').setKeyUp(()=>{this.validarBtnFrm('validarMinimo');});
        this.getField('btn_aceptar').setClick(()=>{this.validarBtnFrm('aceptar');});
        this.getField('finicio').setOnChange(()=>{this.validarBtnFrm('finicio');});
        this.getField('ffinal').setOnChange(()=>{this.validarBtnFrm('validarfechas');});
    }
    
    dibujarTbConsecutivoFacturas(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = { tipo_servicio: 'maes-facturaciones', operacion: '20', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successTbConsecutivoFacturas,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successTbConsecutivoFacturas(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            // console.log('Data successTbConsecutivoFacturas:\n',data);
            this.getField('tb_consecutivofacturas').toggle(true);
            
            let configCell = new Map();
            this.gridOptionsConsecutivoFacturas['rowData'] = data.data;
            configCell.set('accion', {cellRenderer:this.setButtonTbConsecutivoFacturas, headerName: 'Acción', sortable: false, filter: false/* , width:400 */});
            this.getField('tb_consecutivofacturas').initData(this.gridOptionsConsecutivoFacturas, configCell);
            // ↓↓ Las siguientes dos líneas es para que los tres botones dentro de la columna "Acción" de la tabla, aparezcan todos ↓↓
            const allColumnIds = ["accion"];
            this.gridOptionsConsecutivoFacturas.columnApi.autoSizeColumns(allColumnIds, true);
        }else{
            this.getField('tb_consecutivofacturas').toggle(false);
        }
    }

    setButtonTbConsecutivoFacturas(props){
        let fragment = document.createDocumentFragment();
        let buttonVerDetalle = this.crearBotonTabla(props.data, 'Seleccionar');
        buttonVerDetalle.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonVerDetalle);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Seleccionar':
                button.setAttribute("id", `buttonSeleccionar_${data.id}`);
                break;

            default:
                break;
        }
        button.onclick = () => {this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    
    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Seleccionar")} boton 
      */
    accionProcesar(data, boton) {
        if (boton === 'Seleccionar') {
            this.validarBtnFrm('seleccionar');
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    validarBtnFrm(boton) {
        switch (boton) {
            case "seleccionar":
                this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,20','error','false');
                this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20','visible','true');
                break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20','visible','false');
                    this.establecerPropiedades('3,4,5,6,7,8,9,10,11,12,13,14,15,16,20,24','value,error','false');
                    this.dibujarTbConsecutivoFacturas();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "validarMinimo":
                if (this.getField('ninicio').getValue() !== '' && this.getField('nfinal').getValue() !== '') {
                    const ninicio = this.getField('ninicio').getValue();
                    const nfinal = this.getField('nfinal').getValue();
                    let resta = nfinal - ninicio;
                    if (this.getField('minimo').getValue() > resta) {
                        this.getField('minimo').setError(true, 'El valor no debe ser mayor a la resta de Número Final - Número Inicial');
                        return false;
                    }else{
                        this.getField('minimo').setError(false, '');
                        return true;
                    }
                }
                break;

            case "aceptar":
                if (this.validarBtnFrm('validarMinimo') && this.validarBtnFrm('validarfechas') && this.validarBtnFrm('finicio') &&
                this.getField('codigo_facturacion').valid() && this.getField('prefijo_factura').valid() && this.getField('numero_factura').valid() && this.getField('finicio').valid() && 
                this.getField('ffinal').valid() && this.getField('ninicio').valid() && this.getField('nfinal').valid() && this.getField('resolucion').valid() && 
                this.getField('llave_tecnica').valid() && this.getField('rete_ica').valid() && this.getField('tipo_formato').valid() && this.getField('archivo_formato').valid() && 
                this.getField('minimo').valid() && this.getField('email').valid() && this.getField('estadoactivo_id').valid() && this.getField('estado').valid()) {
                    this.getField('btn_aceptar').setDisabled(true);
                    this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                    this.datosAgregar.datos['codigo_facturacion']= this.getField('codigo_facturacion').getValue();
                    this.datosAgregar.datos['prefijo_factura']= this.getField('prefijo_factura').getValue();
                    this.datosAgregar.datos['numero_factura']= this.getField('numero_factura').getValue();
                    this.datosAgregar.datos['finicio']= this.getField('finicio').getValue();
                    this.datosAgregar.datos['ffinal']= this.getField('ffinal').getValue();
                    this.datosAgregar.datos['ninicio']= this.getField('ninicio').getValue();
                    this.datosAgregar.datos['nfinal']= this.getField('nfinal').getValue();
                    this.datosAgregar.datos['resolucion']= this.getField('resolucion').getValue();
                    this.datosAgregar.datos['llave_tecnica']= this.getField('llave_tecnica').getValue();
                    this.datosAgregar.datos['rete_ica']= this.getField('rete_ica').getValue();
                    this.datosAgregar.datos['tipo_formato']= this.getField('tipo_formato').getValue();
                    this.datosAgregar.datos['archivo_formato']= this.getField('archivo_formato').getValue();
                    this.datosAgregar.datos['minimo']= this.getField('minimo').getValue();
                    this.datosAgregar.datos['email']= this.getField('email').getValue().toLowerCase();
                    this.datosAgregar.datos['estadoactivo_id']= this.getField('estadoactivo_id').getValue();
                    this.datosAgregar.datos['estado']= this.getField('estado').getValue();
                    this.generalFormatPmv = {tipo_servicio: 'maes-facturaciones', operacion: '6', operacion_tipo: 'modificar'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'PUT',
                            body: this.datosAgregar,
                            success: this.successActualizarConsecutivoFacturas,
                            error: this.error_,
                            general: this.generalFormatPmv
                        }
                    );
                }
                break;

            case "validarfechas":
                if (this.getField('finicio').getValue() !== '' && this.getField('ffinal').getValue() !== '') {
                    let fechaInicial = new Date(this.getField('finicio').getValue()).toISOString().split('T')[0];
                    let fechaFinal = new Date(this.getField('ffinal').getValue()).toISOString().split('T')[0];
                    if (fechaInicial > fechaFinal) {
                        this.getField('ffinal').setError(true, "¡Fecha final, debe ser mayor a fecha inicial!");
                        return false;
                    } else if (fechaFinal >= fechaInicial) {
                        this.getField('ffinal').setError(false, "");
    
                        let fechaInicialFormateada = this.getField('finicio').getValue();
                        let fechaFinalFormateada = this.getField('ffinal').getValue();
                        let fechaRango = new Date(fechaFinalFormateada) - new Date(fechaInicialFormateada);
                        let diferencia = fechaRango / (1000 * 60 * 60 * 24);
                                        // (1000*60*60*24) --> milisegundos -> segundos -> minutos -> horas -> días
    
                        if (Math.sign(diferencia) === -1) {
                            diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                        }
    
                        if (!(Math.round(diferencia) >= 30)) {
                            this.getField('ffinal').setError(true, "¡La fecha debe ser mayor o igual a 30 días a la fecha inicial!");
                            return false;
                        }else{
                            return true;
                        }
                    }
                }
                break;

            case "finicio":
                if (this.getField('finicio').getValue() !== '') {
                    this.getField('finicio').setError(false, "");
                    let fechaActual = new Date();
                    let anioFechaActual = fechaActual.getFullYear();
                    let mesFechaActual = fechaActual.getMonth()+1;
                    let diaFechaActual = fechaActual.getDate();
    
                    let finicio = this.getField('finicio').getValue();

                    let RegExPattern = /^\d{1,4}\u002D\d{1,2}\u002D\d{2,2}$/;

                    const formatDate = ((finicio.match(RegExPattern)) && (finicio!=='')) ? true : false;
                    
                    finicio = (formatDate) ? (this.getField('finicio').getValue()).replaceAll('-','/') 
                                            : this.getField('finicio').getValue();
                    
                    let fechaCampo = new Date(finicio);
                    let anioFechaCampo = fechaCampo.getFullYear();
                    let mesFechaCampo = fechaCampo.getMonth()+1;
                    let diaFechaCampo = fechaCampo.getDate();
    
                    // Sacamos el valor mínimo y máximo del día de las fechas (fechaActual y fechaCampo):
                    let min = Math.min(diaFechaActual, diaFechaCampo);
                    let max = Math.max(diaFechaActual, diaFechaCampo);
                    // Hacemos la validación dónde la fecha ingresada por el usuario sólo puede ser un día inferior al día de la fecha actual o el mismo día del mismo:
                    if ((anioFechaActual === anioFechaCampo && mesFechaActual === mesFechaCampo) && 
                    (diaFechaActual === diaFechaCampo || (diaFechaActual > diaFechaCampo && (max - min) === 1))) {
                        this.validarBtnFrm('validarfechas');
                        return true;
                    }else{
                        this.getField('finicio').setError(true, "¡La fecha debe ser igual o inferior a un día de la fecha actual!");
                        return false;
                    }
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    opcionConsultarNumeracion(){
        //this.establecerPropiedades('21,22','visible','true');
        this.manejaFacturacionElectronica();
        this.sucursal = JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal;

        this.getField(this.arrayCampos.get(23)).setValue(this.sucursal);
        //this.getField(this.arrayCampos.get(22)).setVisible(false);
        //console.log("entro a opcion numeracion:",this.sucursal);
        this.getField(this.arrayCampos.get(21)).setClick(this.consultarUrl);

        //ordenCamposTablaModal = "cuenta,nombre";
        
    }

    manejaFacturacionElectronica(){
        let datos={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = { tipo_servicio: 'maes-facturaciones', operacion: '20_1', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successManejaFacturaElectronica,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successManejaFacturaElectronica(data){
        this.getField(this.arrayCampos.get(21)).setVisible(true);

        if(data.estado_p === 200){
            //console.log("datos de la respuesta:",data);
            this.xvalor = data.data[0].xvalor;
        } else {
            this.establecerPropiedades('21','disable','true');
            this.alertGeneral.toggle(true, 'Esta Empresa no maneja Facturación electrónica', 'error');
        }
    }

    consultarUrl(){
        if((this.xvalor === "S") && (this.getField(this.arrayCampos.get(23)).valid())){
            this.establecerPropiedades('21','disable','true');
            let datos={ datos:{
                sucursal:this.sucursal,
            }}; 
            this.generalFormatPmv = { tipo_servicio: 'maes-facturaciones', operacion: '20_2', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerUrl,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }

    }

    successTraerUrl(data){
        if(data.estado_p === 200){
            let datos={ datos:{
                sucursal:this.sucursal,
            }}; 
            this.generalFormatPmv = { tipo_servicio: 'maes-facturaciones', operacion: 'traernumeracion', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerValor,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }else {
            this.establecerPropiedades('21','disable','true');
            this.alertGeneral.toggle(true, 'Esta Sucursal no maneja Facturación electrónica propia', 'error');
        } 
    }

    successTraerValor(data){
        console.log("valor a concatenar:",data);

        if(data.estado_p === 200){
            let arrUrl = [];
            let dataOp = {consulta_numeracion: `${data.data.respuesta_xml}`};        

            arrUrl.push(dataOp);
            this.getField(this.arrayCampos.get(22)).setItemsFichas(arrUrl);
        }

    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('maes-facturaciones', CustomFacturaciones);
export default CustomFacturaciones;